import React from "react";
import { colors } from "tabler-react";
import C3Chart from "react-c3js";
import "c3/c3.css";

const ReportChart = ({
  years,
  chart1,
  chart2,
  chart3,
  className,
  chartRef,
}) => {
  const makeColumns = () => {
    let cols = [
      ["years"].concat(years.map((y) => y)),
      ["zero"].concat(years.map(() => 0)),
    ];
    if (chart1) cols.push([chart1.groupName].concat(chart1.data));
    if (chart2) cols.push([chart2.groupName].concat(chart2.data));
    if (chart3) cols.push([chart3.groupName].concat(chart3.data));
    return cols;
  };
  const makeColors = () => {
    let colorConfig = { zero: colors["gray-darkest"] };
    if (chart1) colorConfig[chart1.groupName] = colors[chart1.color];
    if (chart2) colorConfig[chart2.groupName] = colors[chart2.color];
    if (chart3) colorConfig[chart3.groupName] = colors[chart3.color];
    return colorConfig;
  };
  const makeNames = () => {
    let nameConfig = {};
    if (chart1) nameConfig[chart1.groupName] = chart1.displayName;
    if (chart2) nameConfig[chart2.groupName] = chart2.displayName;
    if (chart3) nameConfig[chart3.groupName] = chart3.displayName;
    return nameConfig;
  };

  return (
    <C3Chart
      ref={chartRef}
      className={className}
      style={{ height: "15rem" }}
      data={{
        x: "years",
        columns: makeColumns(),
        colors: makeColors(),
        names: makeNames(),
      }}
      axis={{
        y: { tick: { format: (y) => "$" + y } },
      }}
      legend={{
        hide: "zero",
      }}
      point={{ show: (d) => d.id !== "zero" }}
    />
  );
};

export default ReportChart;
