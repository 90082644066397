import React from "react";
import { ApolloProvider } from "@apollo/client";
import DocumentUpload from "./DocumentUpload";

const ReserveItemDocumentUpload = ({ cell, client, canEdit }) => {
  return (
    <ApolloProvider client={client}>
      <DocumentUpload
        reserveItem={cell.getData()}
        cell={cell}
        canEdit={canEdit}
      />
    </ApolloProvider>
  );
};

export default ReserveItemDocumentUpload;
