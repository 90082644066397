import { useState } from "react";
import useDebounce from "./useDebounce";

const useAutoSave = (submitFn, debounceMs = 1000) => {
  const [lastSubmittedAt, setLastSubmittedAt] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const debouncedSubmitFn = useDebounce(
    async () => {
      setSubmitting(true);
      await submitFn();
      setLastSubmittedAt(new Date().toLocaleString());
      setSubmitting(false);
    },
    process.env.NODE_ENV === "test" ? 0 : debounceMs
  );

  return [debouncedSubmitFn, lastSubmittedAt, submitting];
};

export default useAutoSave;
