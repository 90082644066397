import { Button, Card, Form, Grid, Loader } from "tabler-react";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import OrganizationFormUtils from "../../../utils/OrganizationFormUtils";
import { useMutation } from "@apollo/client";
import { UPDATE_ORGANIZATION } from "../../../graphql/mutations";
import UserContext from "../common/UserContext";
import { yupResolver } from "@hookform/resolvers/yup";
import LogoUpload from "./LogoUpload";
import { NumericFormat } from "react-number-format";
import { sentenceCase } from "change-case";

const OrganizationDetailsCard = () => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { register, handleSubmit, formState, errors, reset, control } = useForm(
    {
      resolver: yupResolver(OrganizationFormUtils.incomingSchema),
    }
  );
  const { isSubmitting } = formState;

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: ["GetUser"],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    reset({
      id: organization.id,
      name: organization.name,
      numberOfUnits: organization.numberOfUnits,
      measurementUnits: organization.measurementUnits,
    });
  }, [organization, reset]);

  const onSubmit = async (values) => {
    await updateOrganization({
      variables: {
        attributes: OrganizationFormUtils.outgoingSchema.cast(values),
      },
    });
  };

  if (!organization) return <i className="text-muted">None</i>;

  return (
    <Card>
      <Card.Header>
        <Card.Title>Organization Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            name="id"
            type="hidden"
            ref={register}
            defaultValue={organization.id}
          />
          <Grid.Row>
            <Grid.Col>
              <LogoUpload organization={organization} />
            </Grid.Col>
            <Grid.Col md={8}>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <input
                      name="name"
                      placeholder="Name (required)"
                      type="text"
                      ref={register}
                      defaultValue={organization.name}
                      className={`form-control ${errors.name && "is-invalid"}`}
                    />
                    <div className="invalid-feedback">
                      {errors.name?.message}
                    </div>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="numberOfUnits"
                      defaultValue={organization.numberOfUnits}
                      render={({ onChange, value }) => (
                        <NumericFormat
                          id="number-of-units-input"
                          placeholder={`Number of ${sentenceCase(
                            organization.measurementUnits
                          )}`}
                          className={`form-control ${
                            errors.numberOfUnits && "is-invalid"
                          }`}
                          allowNegative={false}
                          thousandSeparator={true}
                          valueIsNumericString={true}
                          name="numberOfUnits"
                          onValueChange={(v) => onChange(v.value)}
                          value={value}
                        />
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors.numberOfUnits?.message}
                    </div>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col>
                  <Form.Group>
                    <select
                      ref={register}
                      defaultValue={organization.measurementUnits}
                      name="measurementUnits"
                      className="custom-select form-control"
                    >
                      <option value="doors">Doors</option>
                      <option value="square_feet">Square feet</option>
                    </select>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className="justify-content-center">
            <Grid.Col width={6}>
              <Button
                type="submit"
                color="primary"
                block={true}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Loader className="sm white center" /> : "Save"}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </form>
      </Card.Body>
    </Card>
  );
};

export default OrganizationDetailsCard;
