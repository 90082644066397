import React from "react";
import ReserveItemSwitch from "./ReserveItemSwitch";

const ReserveItemEnabledCell = ({ cell, canEdit }) => {
  if (canEdit) return <ReserveItemSwitch cell={cell} />;
  const enabled = cell.getValue();
  if (enabled) return <div>Yes</div>;
  return <div>No</div>;
};

export default ReserveItemEnabledCell;
