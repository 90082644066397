import React, { Fragment } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Error401Page, Error500Page } from "tabler-react";

const ErrorRedirect = ({ children, error, user }) => {
  const location = useLocation();

  const types = error && error.graphQLErrors.map((err) => err.extensions.type);
  if (types && types.includes("UNAUTHENTICATED"))
    return <Redirect to="/logout" />;

  if (
    user?.subscription?.status === "pending" &&
    location.pathname !== "/activate"
  )
    return <Redirect to="/activate" />;

  const canceled_or_delinquent = [
    "canceled",
    "permanently_canceled",
    "delinquent",
  ].includes(user?.subscription?.status);

  if (canceled_or_delinquent && location.pathname !== "/settings")
    return <Redirect to="/settings" />;

  if (types && types.includes("UNAUTHORIZED")) return <Error401Page />;

  if (!error) return <Fragment>{children}</Fragment>;

  return <Error500Page />;
};

export default ErrorRedirect;
