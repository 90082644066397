import React, { useEffect, useRef } from "react";

const ReserveItemSwitch = ({ cell }) => {
  const spanRef = useRef();
  useEffect(() => {
    const el = spanRef.current;

    const transitionEndCallback = (e) => {
      //hack to make transition event fire only once
      if (e.propertyName === "background-color") {
        cell.setValue(!cell.getValue());
        cell.getRow().delete();
      }
    };

    if (el) {
      el.addEventListener("webkitTransitionEnd", transitionEndCallback, false);
      el.addEventListener("transitionend", transitionEndCallback, false);
    }

    return () => {
      el.removeEventListener("webkitTransitionEnd", transitionEndCallback);
      el.removeEventListener("transitionend", transitionEndCallback);
    };
  }, [spanRef, cell]);

  return (
    <label className="custom-switch m-0">
      <input
        type="checkbox"
        className="custom-switch-input"
        defaultChecked={cell.getValue()}
      />
      <span ref={spanRef} className="custom-switch-indicator" />
      <span className="custom-switch-description" />
    </label>
  );
};

export default ReserveItemSwitch;
