import React, { useEffect, useRef, useState } from "react";
import { Button, Loader } from "tabler-react";

const SaveButton = ({
  block,
  onSubmit,
  className,
  disabled,
  color,
  loaderColor,
  children,
  outline,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const mountedRef = useRef(true);

  const submit = async () => {
    setSubmitting(true);
    await onSubmit();
    if (mountedRef.current) setSubmitting(false);
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Button
      type="button"
      outline={outline}
      onClick={submit}
      color={color || "primary"}
      disabled={disabled || submitting}
      className={className || "px-7 mr-3"}
      block={block}
    >
      {submitting ? (
        <Loader className={`sm ${loaderColor || "white"} center`} />
      ) : (
        children || "Save"
      )}
    </Button>
  );
};

export default SaveButton;
