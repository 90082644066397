import React, { useState } from "react";
import StandaloneFormLayout from "../components/StandaloneFormLayout";
import logo from "../logo.png";
import { Button, Card, Form, Loader } from "tabler-react";
import { object, string } from "yup";
import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const LoginPage = () => {
  const AUTH_ERRORS = ["auth/user-not-found", "auth/wrong-password"];
  const [loginError, setLoginError] = useState(null);
  const { register, handleSubmit, errors, formState, reset } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(
      object({
        email: string().email().required(),
        password: string().required(),
      })
    ),
  });
  const { isSubmitting } = formState;
  const onSubmit = async ({ email, password }) => {
    try {
      const auth = getAuth(getApp());
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      reset({ email, password: "" });
      if (AUTH_ERRORS.includes(e.code)) {
        setLoginError("The username or password you entered is incorrect.");
      } else {
        setLoginError(
          "An unknown error occured. Please try again. If the problem persists, contact info@pemaquidreserves.com."
        );
        throw e;
      }
    }
  };
  return (
    <StandaloneFormLayout imageURL={logo}>
      <Form
        className="card"
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card.Body className="p-6">
          <Card.Title RootComponent="div">Login to your account</Card.Title>
          <Form.Group label="Email">
            <input
              name="email"
              ref={register}
              className={`form-control ${errors.email && "is-invalid"}`}
              placeholder="Enter Email"
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </Form.Group>
          <Form.Group label="Password">
            <input
              name="password"
              ref={register}
              placeholder="Password"
              className={`form-control ${errors.password && "is-invalid"}`}
              type="password"
              data-openreplay-obscured
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </Form.Group>
          {loginError && (
            <span className="d-inline-block invalid-feedback">
              {loginError}
            </span>
          )}
          <Form.Footer>
            <Button
              type="submit"
              color="primary"
              block={true}
              disabled={isSubmitting}
            >
              {isSubmitting ? <Loader className="sm white center" /> : "Login"}
            </Button>
          </Form.Footer>
        </Card.Body>
        <Card.Footer className="text-center">
          <div>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </div>
          <div>
            <Link to="/reset_password">Forgot Password?</Link>
          </div>
        </Card.Footer>
      </Form>
    </StandaloneFormLayout>
  );
};

export default LoginPage;
