import React from "react";
import { Card } from "tabler-react";
import GlossaryContent from "./GlossaryContent";
import { glossaryPdfOptions } from "../../../utils/pdfOptions";

const GlossaryPdf = () => {
  return (
    <div
      className="glossary-pdf"
      data-pdf-options={JSON.stringify(glossaryPdfOptions)}
    >
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>Glossary</Card.Title>
        </Card.Header>
        <Card.Body>
          <GlossaryContent />
        </Card.Body>
      </Card>
    </div>
  );
};

export default GlossaryPdf;
