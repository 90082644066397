import React, { useContext } from "react";
import UserContext from "../common/UserContext";
import ReportsHelperMenu from "./ReportsHelperMenu";
import { useQuery } from "@apollo/client";
import { GET_SPECIAL_REQUIREMENT_REPORT } from "../../../graphql/queries";
import SpecialRequirementReportPdf from "./SpecialRequirementReportPdf";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import { Alert, Card } from "tabler-react";
import MissingPrerequisitesList from "./MissingPrerequisitesList";

const SpecialRequirementReport = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const {
    data: rData,
    loading: rLoading,
    error: rError,
  } = useQuery(GET_SPECIAL_REQUIREMENT_REPORT, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  if (rLoading) return <LoadingPage />;
  if (rError) return <ErrorRedirect error={rError} />;

  if (rData.specialRequirementReport.missingPrerequisites.length > 0) {
    return (
      <Card statusColor={color}>
        <Card.Header>
          <Card.Title>Special Requirements Report</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Special Requirements Report, please complete
              the following steps:
            </p>
            <MissingPrerequisitesList
              missingPrerequisites={
                rData.specialRequirementReport.missingPrerequisites
              }
            />
          </Alert>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="special-requirements-report">
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix={`special-requirements-report`} />
      </div>
      <SpecialRequirementReportPdf
        color={color}
        organization={organization}
        specialRequirementReport={rData.specialRequirementReport}
        reportLoading={rLoading}
        webOnly={true}
      />
      <div className="d-none">
        <SpecialRequirementReportPdf
          organization={organization}
          specialRequirementReport={rData.specialRequirementReport}
        />
      </div>
    </div>
  );
};
export default SpecialRequirementReport;
