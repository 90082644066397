import React from "react";
import { Site, Page, Container, Grid } from "tabler-react";
import CustomPageMain from "./CustomPageMain";
import { AccountDropdown } from "tabler-react";
import { Link } from "react-router-dom";

/*
  Custom class to give use more control over classNames for the main layout
 */
class CustomSiteWrapper extends Site.Wrapper {
  static displayName = "CustomSiteWrapper";

  render() {
    const {
      headerProps,
      navProps,
      children,
      routerContextComponentType,
      mainClassName,
    } = this.props;

    const { href, alt, imageURL, accountDropdown } = headerProps;
    const navPropsWithCollapse = {
      ...navProps,
      collapse: this.state.collapseMobileMenu,
      routerContextComponentType: routerContextComponentType,
    };
    const nav = React.createElement(Site.Nav, navPropsWithCollapse);

    return (
      <Page>
        <CustomPageMain className={mainClassName ? mainClassName : ""}>
          <div className="header py-2">
            <Container>
              <div className="d-flex align-items-center">
                <React.Fragment>
                  <Site.Logo href={href} alt={alt} src={imageURL} />
                  <Link className="header-brand" to="/">
                    Pemaquid Reserves Planner
                  </Link>
                  <div className="d-flex order-lg-2 ml-auto">
                    <AccountDropdown {...accountDropdown} />
                  </div>
                  <button
                    className="header-toggler d-lg-none ml-3 ml-lg-0"
                    onClick={this.handleCollapseMobileMenu}
                  >
                    <span className="header-toggler-icon" />
                  </button>
                </React.Fragment>
              </div>
            </Container>
          </div>
          {nav}
          {children}
        </CustomPageMain>
        <footer className="footer">
          <Container>
            <Grid.Row className="align-items-center justify-content-between">
              <Grid.Col className="mt-3 mt-lg-0 text-center">
                <Grid.Row>
                  <Grid.Col>
                    <i>
                      Proud Member of&nbsp;
                      <a
                        href="https://www.caine.org"
                        className="text-decoration-none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CAI New England
                      </a>
                    </i>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    Copyright © {new Date().getFullYear()}{" "}
                    <a href="/">Pemaquid Reserves Planner</a>. All rights
                    reserved.
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col width={2} className="text-center">
                <img
                  src={imageURL}
                  alt="Pemaquid Reserves Planner Logo"
                  className="logo"
                />
              </Grid.Col>
              <Grid.Col>
                <Grid.Row className="align-items-center">
                  <Grid.Col width={5}>
                    <div>
                      <a
                        href="https://www.usinflationcalculator.com/inflation/current-inflation-rates/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        US Govt Inflation Rates
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://multifamily.fanniemae.com/sites/g/files/koqyhd161/files/2019-08/4099f.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Fannie Mae EUL Tables
                      </a>
                    </div>
                  </Grid.Col>
                  <Grid.Col width={5}>
                    <div>
                      <a
                        href="https://www.nachi.org/life-expectancy.htm"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        InterNACHI EUL Tables
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.becn.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Beacon Home Supply
                      </a>
                    </div>
                  </Grid.Col>
                  <Grid.Col width={2}>
                    <div>
                      <a href="/faq">FAQ</a>
                    </div>
                    <div>
                      <a href="/contact_us">Contact</a>
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </footer>
      </Page>
    );
  }
}

export default CustomSiteWrapper;
