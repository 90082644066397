import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Icon, Loader } from "tabler-react";
import { useMutation } from "@apollo/client";
import { DESTROY_LOGO, UPLOAD_LOGO } from "../../../graphql/mutations";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const LogoUpload = ({ organization }) => {
  const [submitting, setSubmitting] = useState(false);
  const mountedRef = useRef(true);
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (organization.logoUrl && mountedRef.current)
      setLogoUrl(organization.logoUrl);
  }, [organization]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const [uploadLogo] = useMutation(UPLOAD_LOGO);
  const [destroyLogo] = useMutation(DESTROY_LOGO);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setSubmitting(true);
      let { data } = await uploadLogo({
        variables: {
          attributes: {
            logo: acceptedFiles[0],
          },
        },
      });
      if (!mountedRef.current) return;
      setSubmitting(false);
      data.uploadLogo.errors.forEach((error) => toast.error(error.message));
      if (data.uploadLogo.organization)
        setLogoUrl(data.uploadLogo.organization.logoUrl);
    },
    [setSubmitting, uploadLogo, setLogoUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const performDestroy = async () => {
    setSubmitting(true);
    await destroyLogo();
    if (mountedRef.current) {
      setLogoUrl(null);
      setSubmitting(false);
    }
  };

  if (submitting)
    return (
      <div className="logo-upload d-flex mb-5 justify-content-center align-items-center">
        <Loader />
      </div>
    );

  if (logoUrl)
    return (
      <div className="logo-upload d-flex mb-5 justify-content-center align-items-center">
        <div>
          <div className="logo text-center">
            <img src={process.env.REACT_APP_API_URL + logoUrl} alt="logo" />
          </div>
          <div className="d-flex justify-content-center">
            <Button
              size="sm"
              link
              type="button"
              onClick={() => performDestroy()}
            >
              remove
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="logo-upload d-flex mb-5">
      <div
        {...getRootProps({
          className:
            "dropzone d-flex flex-column align-items-center justify-content-center",
        })}
      >
        <input {...getInputProps()} />
        <div>
          <Icon prefix="fe" name="image" className="text-muted" />
        </div>
        <div>
          {isDragActive ? <p>Drop the files here ...</p> : <p>Upload a Logo</p>}
        </div>
      </div>
    </div>
  );
};
export default LogoUpload;
