import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import LoadingPage from "../pages/LoadingPage";
import { ACCEPT_INVITATION_AUTHENTICATED } from "../graphql/mutations";
import useQueryParams from "../hooks/useQueryParams";
import ErrorRedirect from "./ErrorRedirect";
import { Loader } from "tabler-react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const AcceptInvitationAuthenticatedRedirect = () => {
  const query = useQueryParams();
  let invitationToken = query.get("invitation_token");
  const [acceptInvitation, { data, loading, error }] = useMutation(
    ACCEPT_INVITATION_AUTHENTICATED,
    {
      variables: {
        invitationToken: invitationToken,
      },
    }
  );

  useEffect(() => {
    acceptInvitation().then();
  }, [acceptInvitation]);

  if (loading) return <LoadingPage />;

  if (error) return <ErrorRedirect error={error} />;

  if (data?.acceptInvitationAuthenticated?.success) {
    toast.info("Invitation accepted.");
    return <Redirect to="/" />;
  }
  return <Loader />;
};

export default AcceptInvitationAuthenticatedRedirect;
