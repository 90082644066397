import React, { Fragment, useContext } from "react";
import { Card, Alert } from "tabler-react";
import { useQuery } from "@apollo/client";
import {
  GET_BASELINE_PLAN,
  GET_SIMULATION_PLAN,
} from "../../../graphql/queries";
import UserContext from "../common/UserContext";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import ReportsHelperMenu from "./ReportsHelperMenu";
import SimulationPlanPdf from "./SimulationPlanPdf";
import SimulationPlanTable from "./SimulationPlanTable";

const SimulationPlan = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { loading, error, data } = useQuery(GET_SIMULATION_PLAN, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });
  const {
    loading: baselineLoading,
    error: baselineError,
    data: baselineData,
  } = useQuery(GET_BASELINE_PLAN, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  if (loading || baselineLoading) return <LoadingPage />;
  if (error || baselineError)
    return <ErrorRedirect error={error || baselineError} />;

  const missingPrereqs = data.simulationPlan.missingPrerequisites;
  if (missingPrereqs.length)
    return (
      <Card statusColor={color}>
        <Card.Header>
          <Card.Title>Simulation Plan</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Simulation Plan Report, please complete the
              following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <Fragment>
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix="simulation" />
      </div>
      <SimulationPlanTable
        color={color}
        organization={organization}
        plan={data.simulationPlan.rows}
        baselinePlan={baselineData.baselinePlan.rows}
      />
      <div className="d-none">
        <SimulationPlanPdf
          organization={organization}
          baselinePlan={baselineData.baselinePlan.rows}
          simulationPlan={data.simulationPlan.rows}
        />
      </div>
    </Fragment>
  );
};

export default SimulationPlan;
