import React, { Fragment, useContext } from "react";
import UserContext from "../common/UserContext";
import { useQuery } from "@apollo/client";
import { GET_CHANGE_LOG_REPORT } from "../../../graphql/queries";
import ErrorRedirect from "../../ErrorRedirect";
import ReportsHelperMenu from "./ReportsHelperMenu";
import { Dimmer, Grid } from "tabler-react";
import SaveButton from "../common/SaveButton";
import { useForm } from "react-hook-form";
import ChangeLogReportPdf from "./ChangeLogReportPdf";

const ChangeLogReport = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { register, handleSubmit, formState, getValues } = useForm();
  const { isSubmitting, isDirty } = formState;
  const { loading, error, data, refetch } = useQuery(GET_CHANGE_LOG_REPORT, {
    skip: !organization,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const onSubmit = async (vals) => {
    await refetch({
      timeframe: vals.timeframe,
    });
  };

  if (error) return <ErrorRedirect error={error} />;

  return (
    <Fragment>
      <Grid.Row className="justify-content-center">
        <div className="d-flex align-items-center justify-content-end">
          View logs from past
        </div>
        <Grid.Col width={2}>
          <select
            className="custom-select form-select"
            ref={register}
            name="timeframe"
            defaultValue="1_month"
          >
            <option value="1_month">1 Month</option>
            <option value="3_months">3 Months</option>
            <option value="6_months">6 Months</option>
            <option value="1_year">1 Year</option>
            <option value="3_years">3 Years</option>
          </select>
        </Grid.Col>
        <Grid.Col width={2}>
          <SaveButton
            onSubmit={handleSubmit(onSubmit)}
            className="px-3 mr-1"
            disabled={!isDirty || isSubmitting}
            block
          >
            Update
          </SaveButton>
        </Grid.Col>
      </Grid.Row>
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix={`change-log`} />
      </div>
      <Dimmer active={loading} loader>
        <ChangeLogReportPdf
          color={color}
          organization={organization}
          timeframe={getValues().timeframe}
          changeLogReport={data?.changeLogReport}
          webOnly={true}
        />
      </Dimmer>
      <div className="d-none">
        <ChangeLogReportPdf
          color={color}
          organization={organization}
          timeframe={getValues().timeframe}
          changeLogReport={data?.changeLogReport}
        />
      </div>
    </Fragment>
  );
};

export default ChangeLogReport;
