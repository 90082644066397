import React, { useContext, useState } from "react";
import { Dropdown, Loader } from "tabler-react";
import {
  findAndGenerateExcel,
  findAndGeneratePdf,
} from "../../../utils/helpers";
import UserContext from "../common/UserContext";

const FinancialDashboardHelperMenu = () => {
  const [pdfLoading, setPdfLoading] = useState(false);
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  return (
    <Dropdown
      trigger={<Dropdown.Trigger>Actions</Dropdown.Trigger>}
      type="button"
      color="primary"
      items={[
        <div
          className="dropdown-item cursor-pointer"
          key="dropdown-item-0"
          onClick={async (e) => {
            e.preventDefault();
            if (pdfLoading) return;
            findAndGenerateExcel(`${organization.name} financial-dashboard`);
          }}
        >
          Download as Excel
        </div>,

        <div
          className="dropdown-item cursor-pointer"
          key="dropdown-item-1"
          onClick={async () => {
            if (pdfLoading) return;
            setPdfLoading(true);
            await findAndGeneratePdf(
              `${organization.name} financial-dashboard`
            );
            setPdfLoading(false);
          }}
        >
          {pdfLoading ? <Loader className="sm center" /> : "Download as PDF"}
        </div>,
      ]}
    />
  );
};

export default FinancialDashboardHelperMenu;
