import ReportsHelperMenu from "./ReportsHelperMenu";
import React, { useContext } from "react";
import PhotoAlbumPdf from "./PhotoAlbumPdf";
import UserContext from "../common/UserContext";
import { Alert, Card } from "tabler-react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_RESERVE_ITEMS } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";

const PhotoAlbum = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { loading, data, error } = useQuery(GET_RESERVE_ITEMS, {
    fetchPolicy: "no-cache",
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;

  const reserveItems = data.reserveItems.filter((e) => e.enabled);

  if (!reserveItems.length)
    return (
      <Card statusColor={color} className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>Photo Album</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view The Photo Album, please enable at least 1 Reserve
              Item on the&nbsp;
              <Link to="/reserve_items" className="text-dark">
                <strong>Reserve Items</strong>
              </Link>
              &nbsp;Page
            </p>
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <div className="photo-album">
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix="photo-album" />
      </div>
      <PhotoAlbumPdf
        color={color}
        organization={organization}
        reserveItems={reserveItems}
        webOnly={true}
      />
      <div className="d-none">
        <PhotoAlbumPdf
          organization={organization}
          reserveItems={reserveItems}
        />
      </div>
    </div>
  );
};
export default PhotoAlbum;
