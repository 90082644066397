import React from "react";
import { ApolloProvider } from "@apollo/client";
import PhotoUpload from "./PhotoUpload";

const ReserveItemPhotoUpload = ({ cell, canEdit, client }) => {
  return (
    <ApolloProvider client={client}>
      <PhotoUpload cell={cell} canEdit={canEdit} />
    </ApolloProvider>
  );
};

export default ReserveItemPhotoUpload;
