import React from "react";
import DefaultLayout from "./DefaultLayout";

const DefaultCenteredLayout = ({ children }) => {
  return (
    <DefaultLayout mainClassName="d-flex flex-column">
      <div className="page-main d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center">{children}</div>
      </div>
    </DefaultLayout>
  );
};

export default DefaultCenteredLayout;
