import React from "react";
import { NumericFormat } from "react-number-format";
import { sentenceCase } from "change-case";

const isString = (obj) => typeof obj === "string" || obj instanceof String;
const isNumber = (obj) => typeof obj === "number";
const isBoolean = (obj) => typeof obj === "boolean";
const isArray = (obj) => Array.isArray(obj);

export const formatChangedAttributeName = (attr) => {
  if (attr === "desired_additional_annual_funds")
    attr = '"What if" Simulation Reserves Fund Increase';
  attr = attr.replace("base64", "");
  return sentenceCase(attr);
};

export const formatChange = (obj, className, key) => {
  if (obj === null) return <span className={className}>(Empty)</span>;
  if (isBoolean(obj))
    return <span className={className}>{obj.toString()}</span>;

  if (isArray(obj)) {
    return (
      <div>
        {obj.map((item, index) => (
          <div className="d-flex" key={`${key}-item-${index}`}>
            <div className={`mr-1 ${className}`}>start: {item.start_year}</div>
            <div className={`mr-1 ${className}`}>end: {item.end_year}</div>
            <div className={`mr-1 ${className}`}>rate: {item.rate}</div>
          </div>
        ))}
      </div>
    );
  }

  if (isString(obj) && obj.startsWith("data:"))
    return (
      <img
        src={obj}
        className={`my-1 ${className}`}
        height="50"
        alt="reserve item"
      />
    );

  if (isNumber(obj) && key.includes("year"))
    return (
      <NumericFormat className={className} value={obj} displayType="text" />
    );

  if (isNumber(obj))
    return (
      <NumericFormat
        className={className}
        thousandSeparator={true}
        value={obj}
        displayType="text"
        decimalScale={2}
      />
    );
  // This case is the object is just a string of letters
  return <div className={className}>{obj}</div>;
};
