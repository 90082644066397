import { createRef } from "react";
import { sleep } from "./helpers";

export const baselinePlanChartRef = createRef();
export const baselinePercentFundedChartRef = createRef();
export const simulationPlanChartRef = createRef();
export const simulationPercentFundedChartRef = createRef();
export const alternatePlanChartRef = createRef();
export const alternatePercentFundedChartRef = createRef();

export const getResizedChartHtml = async (selector, ref) => {
  const chartElement = ref.current.chart.element;

  // save original size so we can resize back
  const { width, height } = chartElement.getBoundingClientRect();
  // resize to max 240px height and 7.25in width
  ref.current.chart.resize({ height: "240", width: "696" });
  // use sleep hack to ensure repainting is done before continuing
  await sleep(1);
  // get the html of the resized chart
  const chartHtml = document.querySelector(selector)?.outerHTML;
  // resize chart back to normal
  ref.current.chart.resize({ height, width });

  return chartHtml;
};

export const getTheBookStyles = () => {
  const linkTags = [...document.querySelectorAll("link[rel='stylesheet']")];
  const linkTagStrs = linkTags.reduce((memo, el) => {
    let absolutePath = el.href;
    let html = el.outerHTML;
    return (memo += html.replace(/\/.*\.css/, absolutePath));
  }, "");

  const styles = [...document.querySelectorAll("style")];
  // remove all the sourcemap content from the css
  const styleStrs = styles.reduce(
    (memo, el) =>
      (memo += el.outerHTML.replace(/\/\*# sourceMappingURL=.*\*\//, "")),
    ""
  );

  return linkTagStrs + styleStrs;
};
