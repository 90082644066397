import React from "react";

import { formatPercent } from "../../../utils/helpers";

const PercentFormat = ({ children }) => {
  if (children < 0) {
    return <span className="text-danger">{formatPercent(children)}</span>;
  }

  return formatPercent(children);
};

export default PercentFormat;
