import React from "react";
import { Card, Table } from "tabler-react";
import {
  formatChange,
  formatChangedAttributeName,
} from "../../../utils/versionFormatters";
import { changeLogPdfOptions } from "../../../utils/pdfOptions";

const ChangeLogVersionLogs = ({
  versions,
  organization,
  title,
  generatedAt,
  forReserveItemLogs,
  color,
  webOnly,
}) => {
  versions = versions || [];
  return (
    <div
      className={`change-log-pdf ${webOnly ? "web-only" : "pdf"}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(changeLogPdfOptions)
      }
    >
      <Card statusColor={color}>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>{organization.name}</Card.Title>
          <div style={{ textAlign: "center" }}>
            <div>
              <Card.Title>{title}</Card.Title>
            </div>
          </div>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="single-log">
            <div className="table-responsive">
              <Table className="card-table table-vcenter">
                <Table.Header>
                  <Table.Row className="text-center">
                    <Table.ColHeader>User</Table.ColHeader>
                    {forReserveItemLogs && (
                      <Table.ColHeader>Reserve Item Name</Table.ColHeader>
                    )}
                    <Table.ColHeader>Changes</Table.ColHeader>
                    <Table.ColHeader>Date</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {versions.length === 0 && (
                    <Table.Row>
                      <Table.Col colSpan={forReserveItemLogs ? 4 : 3}>
                        <div className="d-flex justify-content-center">
                          <div className="py-5">No changes recorded</div>
                        </div>
                      </Table.Col>
                    </Table.Row>
                  )}
                  {versions.length > 0 &&
                    versions?.map((version, index) => (
                      <Table.Row key={`reserve-item-version-${index}`}>
                        <Table.Col className="text-center">
                          {version.whodunnit}
                        </Table.Col>
                        {forReserveItemLogs && (
                          <Table.Col className="text-center">
                            {version.name}
                          </Table.Col>
                        )}
                        <Table.Col className="text-center">
                          {Object.keys(version.objectChanges).map((key) => (
                            <div key={`reserve-item-version-key-${key}`}>
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="mr-1">
                                  {formatChangedAttributeName(key)}:
                                </div>
                                <div className="mr-1">
                                  {formatChange(
                                    version.objectChanges[key][0],
                                    "removed text-danger",
                                    key
                                  )}
                                </div>
                                <div>
                                  {formatChange(
                                    version.objectChanges[key][1],
                                    "added text-success",
                                    key
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </Table.Col>
                        <Table.Col className="text-center">
                          {new Date(version.createdAt).toLocaleString("en-US")}
                        </Table.Col>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ChangeLogVersionLogs;
