import React, { Fragment, useContext, useState } from "react";
import { Dropdown, Loader } from "tabler-react";
import "../../../utils/html2pdf-custom-pagebreak-settings";
import {
  findAndGenerateExcel,
  findAndGeneratePdf,
} from "../../../utils/helpers";
import NewReserveItemModal from "./NewReserveItemModal";
import NewReportsCategoryModal from "./NewReportsCategoryModal";
import UserContext from "../common/UserContext";

const ReserveItemsHelperMenu = ({
  existingCustomReportsCategory,
  downloadPdfAllowed,
  pdfFilenamePrefix,
  refetch,
  canEdit,
  canExportExcel,
}) => {
  const [pdfLoading, setPdfLoading] = useState(false);
  const [showNewResItemModal, setShowNewResItemModal] = useState(false);
  const [showNewReportsCategoryModal, setShowNewReportsCategoryModal] =
    useState(false);
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const canEditItems = () => {
    if (canEdit)
      return [
        <div
          className="dropdown-item cursor-pointer"
          key="dropdown-item-0"
          onClick={() => setShowNewResItemModal(true)}
        >
          Add a Custom Reserve Item
        </div>,
        <div
          className="dropdown-item cursor-pointer"
          key="dropdown-item-1"
          onClick={() => setShowNewReportsCategoryModal(true)}
        >
          {existingCustomReportsCategory
            ? "Edit Custom Reports Category"
            : "Add a Custom Reports Category"}
        </div>,
      ];

    return [];
  };

  return (
    <Fragment>
      <NewReserveItemModal
        show={showNewResItemModal}
        setShow={setShowNewResItemModal}
        refetch={refetch}
      />
      <NewReportsCategoryModal
        show={showNewReportsCategoryModal}
        setShow={setShowNewReportsCategoryModal}
        existingCustomReportsCategory={existingCustomReportsCategory}
      />
      <Dropdown
        trigger={<Dropdown.Trigger>Actions</Dropdown.Trigger>}
        type="button"
        color="primary"
        items={[
          ...canEditItems(),
          <Dropdown.Item
            to="/reserve_items/inflation_projected_costs"
            key="dropdown-item-2"
          >
            Inflation Projected Costs
          </Dropdown.Item>,
          <Dropdown.Item to="/reserve_items/glossary" key="dropdown-item-3">
            Glossary
          </Dropdown.Item>,
          <div key="dropdown-item-4" className="dropdown-divider"></div>,
          canExportExcel && (
            <div
              className={`dropdown-item ${
                downloadPdfAllowed ? "cursor-pointer" : "disabled text-muted"
              }`}
              key="dropdown-item-5"
              onClick={async (e) => {
                e.preventDefault();
                if (pdfLoading) return;
                if (!downloadPdfAllowed) return;
                findAndGenerateExcel(
                  `${organization.name} ${pdfFilenamePrefix}`
                );
              }}
            >
              Download as Excel
            </div>
          ),
          <div
            className={`dropdown-item ${
              downloadPdfAllowed ? "cursor-pointer" : "disabled text-muted"
            }`}
            key="dropdown-item-6"
            onClick={async (e) => {
              e.preventDefault();
              if (pdfLoading) return;
              if (!downloadPdfAllowed) return;
              setPdfLoading(true);
              await findAndGeneratePdf(
                `${organization.name} ${pdfFilenamePrefix}`
              );
              setPdfLoading(false);
            }}
          >
            {pdfLoading ? <Loader className="sm center" /> : "Download as PDF"}
          </div>,
        ]}
      />
    </Fragment>
  );
};

export default ReserveItemsHelperMenu;
