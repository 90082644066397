import { Card, Table } from "tabler-react";
import React, { Fragment } from "react";
import ReportChart from "./ReportChart";
import AccountingFormat from "../common/AccountingFormat";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";

const SimulationPlanTable = ({
  color,
  organization,
  plan,
  baselinePlan,
  generatedAt,
  chartRef,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  return (
    <Card statusColor={color}>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>{organization.name}</Card.Title>
        <div style={{ textAlign: "center" }}>
          <div>
            <Card.Title>Reserves Simulation Plan</Card.Title>
          </div>
          <div style={{ fontSize: "12px" }}>
            {`${organization.numberOfUnits} ${titleCase(
              abbrevMeasurementUnit(organization.measurementUnits)
            )}`}
          </div>
        </div>
        <Card.Title>Generated: {generatedAt}</Card.Title>
      </Card.Header>
      {plan && baselinePlan && (
        <Fragment>
          <ReportChart
            chartRef={chartRef}
            className="mt-5 d-flex justify-content-center"
            chart1={{
              data: baselinePlan.map((row) => row.yearEndBalance),
              groupName: "baseline",
              displayName: "Baseline Account Balance",
              color: "blue",
            }}
            chart2={{
              data: plan.map((row) => row.accountBalance),
              groupName: "simulation",
              displayName: "Simulation Account Balance",
              color: "orange",
            }}
            years={plan.map((row) => row.year)}
          />
          <Card.Body>
            <div className="table-responsive">
              <Table className="card-table table-vcenter">
                <Table.Header>
                  <Table.Row className="text-center">
                    <Table.ColHeader>Year</Table.ColHeader>
                    <Table.ColHeader>Beginning Balance</Table.ColHeader>
                    <Table.ColHeader>Available Investment</Table.ColHeader>
                    <Table.ColHeader>Interest Earned</Table.ColHeader>
                    <Table.ColHeader>Annual Contribution</Table.ColHeader>
                    <Table.ColHeader>Total</Table.ColHeader>
                    <Table.ColHeader>Expenses</Table.ColHeader>
                    <Table.ColHeader>Account Balance</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {plan.map((row, index) => (
                    <Table.Row className="text-center" key={`year-${index}`}>
                      <Table.Col>{row.year}</Table.Col>
                      <Table.Col>
                        <AccountingFormat>
                          {row.beginningBalance}
                        </AccountingFormat>
                      </Table.Col>
                      <Table.Col>
                        <AccountingFormat>
                          {row.availableInvestment}
                        </AccountingFormat>
                      </Table.Col>
                      <Table.Col>
                        <AccountingFormat>
                          {row.interestEarned}
                        </AccountingFormat>
                      </Table.Col>
                      <Table.Col>
                        <AccountingFormat>
                          {row.collectionFees}
                        </AccountingFormat>
                      </Table.Col>
                      <Table.Col>
                        <AccountingFormat>{row.total}</AccountingFormat>
                      </Table.Col>
                      <Table.Col>
                        <AccountingFormat>{row.expenses}</AccountingFormat>
                      </Table.Col>
                      <Table.Col>
                        <AccountingFormat>
                          {row.accountBalance}
                        </AccountingFormat>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Card.Body>
        </Fragment>
      )}
    </Card>
  );
};

export default SimulationPlanTable;
