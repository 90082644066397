import React, { useMemo } from "react";
import { Card } from "tabler-react";
import AccountingFormat from "../common/AccountingFormat";
import { useFlexLayout, useTable } from "react-table";
import capitalize from "capitalize";
import { annualReportPdfOptions } from "../../../utils/pdfOptions";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";

const AnnualReportPdf = ({
  organization,
  annualReport,
  reportType,
  generatedAt,
  customReportsCategoryName,
  hasCustomReportsCategory,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
      width: 30,
      maxWidth: 250,
    }),
    []
  );

  const columns = useMemo(() => {
    const cols = [
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Beg Balance",
        accessor: "beginningBalance",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.beginningBalance}</AccountingFormat>
        ),
      },
      {
        Header: "Annual Contribution",
        accessor: "annualContribution",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.annualContribution}</AccountingFormat>
        ),
      },
      {
        Header: "Interest Earned",
        accessor: "interestEarned",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.interestEarned}</AccountingFormat>
        ),
      },
      {
        Header: "Community",
        accessor: "community",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.community}</AccountingFormat>
        ),
      },
      {
        Header: "Doors",
        accessor: "doors",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.doors}</AccountingFormat>
        ),
      },
      {
        Header: "Exterior",
        accessor: "exterior",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.exterior}</AccountingFormat>
        ),
      },
      {
        Header: "Interior",
        accessor: "interior",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.interior}</AccountingFormat>
        ),
      },
      {
        Header: "Windows",
        accessor: "windows",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.windows}</AccountingFormat>
        ),
      },
    ];

    if (hasCustomReportsCategory) {
      cols.push({
        Header: customReportsCategoryName,
        accessor: "customCategory",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.customCategory}</AccountingFormat>
        ),
      });
    }

    cols.push(
      {
        Header: "Total Expenses",
        accessor: "totalExpenses",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.totalExpenses}</AccountingFormat>
        ),
      },
      {
        Header: "End Balance",
        accessor: "cumulativeAccountBalance",
        Cell: ({ row }) => (
          <AccountingFormat>
            {row.original.cumulativeAccountBalance}
          </AccountingFormat>
        ),
      }
    );
    return cols;
  }, [organization, annualReport]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: annualReport, defaultColumn }, useFlexLayout);

  return (
    <div
      className="report-pdf"
      data-pdf-options={JSON.stringify({
        filenamePrefix: `${reportType}-annual-report`,
        ...annualReportPdfOptions,
      })}
    >
      <div className="annual-plan">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title>{organization.name}</Card.Title>
            <div style={{ textAlign: "center" }}>
              <div>
                <Card.Title>
                  Annual Report - {capitalize(reportType)}
                </Card.Title>
              </div>
              <div style={{ fontSize: "12px" }}>
                {`${organization.numberOfUnits} ${titleCase(
                  abbrevMeasurementUnit(organization.measurementUnits)
                )}`}
              </div>
            </div>
            <Card.Title>Generated: {generatedAt}</Card.Title>
          </Card.Header>
          <Card.Body>
            <div {...getTableProps()} className="table">
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map((column) => (
                      <div
                        {...column.getHeaderProps()}
                        className="th d-flex align-items-center justify-content-center text-center"
                      >
                        {column.render("Header")}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div {...getTableBodyProps()} className="tbody">
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} className="tr">
                      {row.cells.map((cell) => {
                        return (
                          <div
                            {...cell.getCellProps()}
                            className="td d-flex align-items-center justify-content-center"
                          >
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AnnualReportPdf;
