import React from "react";
import { Container, Page, Grid } from "tabler-react";
import logo from "../logo.png";

const StandaloneFormLayout = ({ imageURL, children, className, width }) => {
  width = width || 4;
  imageURL = imageURL || logo;
  return (
    <Page className={className}>
      <div className="page-single">
        <Container>
          <Grid.Row>
            <Grid.Col lg={width} className={`mx-auto`}>
              <div className="text-center mb-6">
                <img src={imageURL} className="h-9" alt="logo" />
                <h3 className="mt-6">Pemaquid Reserves Planner</h3>
              </div>
              {children}
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    </Page>
  );
};

export default StandaloneFormLayout;
