import React, { useContext } from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Card, Form, Grid, Page } from "tabler-react";
import { useMutation } from "@apollo/client";
import { CREATE_ORGANIZATION } from "../../graphql/mutations";
import { Redirect, useHistory } from "react-router-dom";
import OrganizationFormUtils from "../../utils/OrganizationFormUtils";
import { Controller, useForm } from "react-hook-form";
import SaveButton from "../../components/authenticated/common/SaveButton";
import UserContext from "../../components/authenticated/common/UserContext";
import { sentenceCase } from "change-case";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { NumericFormat } from "react-number-format";

const NewOrganizationPage = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: { measurementUnits: "doors" },
    resolver: yupResolver(OrganizationFormUtils.incomingSchema),
  });
  const measurementUnits = watch("measurementUnits");
  const [createOrganization] = useMutation(CREATE_ORGANIZATION, {
    refetchQueries: ["GetUser", "GetCollaborators"],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (values) => {
    try {
      values.name = values.name.trim();

      const { data } = await createOrganization({
        variables: {
          attributes: OrganizationFormUtils.outgoingSchema.cast(values),
        },
      });
      if (data.createOrganization.errors.length) {
        const messages = data.createOrganization.errors
          .map((e) => e.message)
          .join("\n");
        toast.error(messages);
        return;
      }
      history.push("/organizations/new/reserve_items");
    } catch (e) {
      console.error("Error creating organization: ", e);
    }
  };
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization || {};

  // case when they created an organization but did not finish onboarding
  if (organization.id && !organization.onboarded)
    return <Redirect to="/organizations/new/reserve_items" />;

  if (organization.id && organization.onboarded)
    return <Redirect to="/reserve_items" />;

  return (
    <DefaultLayout>
      <Page.Content>
        <Page.Header title="New Organization" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid.Row className="justify-content-center">
            <Grid.Col width={12} lg={10}>
              <Card title="Organization Details">
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col
                      md={3}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <label>Organization Name</label>
                    </Grid.Col>
                    <Grid.Col md={9}>
                      <Form.Group>
                        <input
                          name="name"
                          placeholder="Organization Name"
                          type="text"
                          ref={register}
                          className={`form-control ${
                            errors.name && "is-invalid"
                          }`}
                          defaultValue={organization.name}
                        />
                        <div className="invalid-feedback">
                          {errors.name?.message}
                        </div>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col
                      md={3}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <label className="m-0">Measurement Units</label>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group>
                        <select
                          ref={register}
                          name="measurementUnits"
                          className="custom-select form-control"
                        >
                          <option value="doors">Doors</option>
                          <option value="square_feet">Square feet</option>
                        </select>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col
                      md={3}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <label>Number of {sentenceCase(measurementUnits)}</label>
                    </Grid.Col>
                    <Grid.Col md={3}>
                      <Controller
                        control={control}
                        name="numberOfUnits"
                        defaultValue={organization.numberOfUnits}
                        render={({ onChange, value }) => (
                          <NumericFormat
                            id="new-organization-number-of-units-input"
                            placeholder={`Number of ${sentenceCase(
                              measurementUnits
                            )}`}
                            className={`form-control ${
                              errors.numberOfUnits && "is-invalid"
                            }`}
                            allowNegative={false}
                            thousandSeparator={true}
                            valueIsNumericString={true}
                            name="numberOfUnits"
                            onValueChange={(v) => onChange(v.value)}
                            value={value}
                          />
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors.numberOfUnits?.message}
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col offset={3}>
                      <small className="text-muted">
                        {measurementUnits === "doors"
                          ? "The number of apartments or condos"
                          : "The overall square footage of the property"}
                      </small>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="justify-content-center">
            <Grid.Col width={12} lg={6}>
              <SaveButton onSubmit={handleSubmit(onSubmit)} block={true}>
                Next
              </SaveButton>
            </Grid.Col>
          </Grid.Row>
        </form>
      </Page.Content>
    </DefaultLayout>
  );
};

export default NewOrganizationPage;
