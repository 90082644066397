import React, { Fragment } from "react";
import { Card, Form, Grid, Icon, Table } from "tabler-react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import SaveButton from "../common/SaveButton";
import {
  CREATE_INVITATION,
  REVOKE_INVITATION,
} from "../../../graphql/mutations";
import { GET_COLLABORATORS, GET_ROLES } from "../../../graphql/queries";
import Loading from "../common/Loading";
import { sentenceCase } from "change-case";
import capitalize from "capitalize";
import { titleCase } from "title-case";
import { yupResolver } from "@hookform/resolvers/yup";
import SettingsFormUtils from "../../../utils/SettingsFormUtils";

const AddCollaboratorsCard = ({ user }) => {
  const organization = user.focusedOrganization;
  const { loading, error, data } = useQuery(GET_ROLES);
  const {
    loading: cloading,
    data: cdata,
    error: cerror,
  } = useQuery(GET_COLLABORATORS, { fetchPolicy: "no-cache" });

  const [createInvitation] = useMutation(CREATE_INVITATION, {
    refetchQueries: ["GetUser", "GetCollaborators"],
    awaitRefetchQueries: true,
  });
  const [revokeInvitation] = useMutation(REVOKE_INVITATION, {
    refetchQueries: ["GetUser", "GetCollaborators"],
    awaitRefetchQueries: true,
  });
  const { register, handleSubmit, errors, setError, reset, watch, formState } =
    useForm({
      resolver: yupResolver(SettingsFormUtils.outgoingSchema),
      mode: "onChange",
    });
  const { isSubmitting, isValid, isDirty } = formState;
  const selectedRole = watch("role");

  const onSubmit = async (vals) => {
    let { data: iData } = await createInvitation({
      variables: {
        email: vals.email,
        role: vals.role,
      },
    });
    if (iData.createInvitation.errors.length) {
      iData.createInvitation.errors.forEach((error) =>
        setError(error.path.join("."), {
          type: "manual",
          message: error.message,
        })
      );
    } else {
      reset({ email: "", role: data.roles[0] });
    }
  };

  const revoke = async (id) => {
    if (window.confirm("Are you sure?")) {
      let { data } = await revokeInvitation({
        variables: { id },
      });
      if (data.revokeInvitation.errors.length) {
        data.revokeInvitation.errors.forEach((error) =>
          setError(error.path.join("."), {
            type: "manual",
            message: error.message,
          })
        );
      }
    }
  };

  if (loading || cloading || !organization) return <Loading />;

  if (error || cerror)
    return (
      <Card className="add-collaborators">
        <Card.Body>
          <Card.Title>Add Collaborators</Card.Title>
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-center align-items-center">
              Could not load invitation settings.
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    );
  const otherErrors = Object.values(errors).filter(
    (error) => error.type === "manual"
  );

  const description = data.roles.find(
    (r) => r.role === selectedRole
  )?.description;

  return (
    <Card className="add-collaborators">
      <Card.Header>
        <Card.Title>Add Collaborators</Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row className="mb-4">
          <Grid.Col className="d-flex justify-content-between">
            {organization.remainingSeats <= 0 ? (
              <i>No remaining seats are left on your license</i>
            ) : (
              <Fragment>
                <span>Invite a collaborator to join your team</span>
                <span>Remaining seats: {organization.remainingSeats}</span>
              </Fragment>
            )}
          </Grid.Col>
        </Grid.Row>
        {otherErrors.map((error, i) => (
          <div
            key={`other-error-${i}`}
            className="d-block invalid-feedback mb-3"
          >
            {error.message}
          </div>
        ))}
        <form>
          {organization.remainingSeats > 0 && (
            <Fragment>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <input
                      placeholder="Enter collaborator's email address"
                      className={`form-control ${errors.email && "is-invalid"}`}
                      type="text"
                      name="email"
                      ref={register}
                      defaultValue={""}
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Form.Group>
                    <select
                      ref={register}
                      defaultValue=""
                      name="role"
                      className="custom-select form-control"
                    >
                      <option value="" disabled hidden>
                        Select Role
                      </option>

                      {data.roles.map((role) => (
                        <option key={`role-${role.role}`} value={role.role}>
                          {titleCase(sentenceCase(role.role))}
                        </option>
                      ))}
                    </select>
                    <small className="form-text text-muted">
                      {description}
                    </small>
                    <div className="invalid-feedback">
                      {errors.role?.message}
                    </div>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <SaveButton
                    onSubmit={handleSubmit(onSubmit)}
                    className="px-3 mr-1"
                    disabled={!isDirty || isSubmitting || !isValid}
                    block
                  >
                    Invite
                  </SaveButton>
                </Grid.Col>
              </Grid.Row>
            </Fragment>
          )}
        </form>
        <Table className="card-table table-vcenter">
          <Table.Header>
            <Table.Row className="text-center">
              <Table.ColHeader>
                <small>Email</small>
              </Table.ColHeader>
              <Table.ColHeader>
                <small>Role</small>
              </Table.ColHeader>
              <Table.ColHeader>
                <small>Status</small>
              </Table.ColHeader>
              <Table.ColHeader />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {cdata.collaborators.map((collaborator, index) => (
              <Table.Row key={`invite-user-${index}`} className="mb-5">
                <Table.Col className="text-center">
                  <small>{collaborator.email}</small>
                </Table.Col>
                <Table.Col className="text-center">
                  <small>{titleCase(sentenceCase(collaborator.role))}</small>
                </Table.Col>
                <Table.Col className="text-center">
                  <small>
                    <i>{capitalize(collaborator.status)}</i>
                  </small>
                </Table.Col>
                <Table.Col width={1}>
                  {collaborator.invitationId && (
                    <SaveButton
                      color="secondary"
                      onSubmit={() => revoke(collaborator.invitationId)}
                      className="px-3 mr-1"
                      loaderColor="cyan"
                    >
                      <Icon name="trash" className="text-danger" />
                    </SaveButton>
                  )}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {cdata.collaborators.length === 0 && (
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-center">
              <p className="mt-5 text-muted">No Collaborators</p>
            </Grid.Col>
          </Grid.Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default AddCollaboratorsCard;
