import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import LoadingPage from "./pages/LoadingPage";
import ErrorPage from "./pages/ErrorPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import "tabler-react/dist/Tabler.css";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator_bootstrap4.min.css";
import "./App.scss";
import { ApolloProvider } from "@apollo/client";
import ApolloClientBuilder from "./utils/ApolloClientBuilder";

const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
getAnalytics(app);

const App = () => {
  const [token, setToken] = useState(null);
  const [user, loading, error] = useAuthState(getAuth(app));

  useEffect(() => {
    if (user) user.getIdToken().then((idToken) => setToken(idToken));
  }, [user]);

  if (loading || (user && !token)) return <LoadingPage />;
  if (error) return <ErrorPage />;

  const client = ApolloClientBuilder.client({ user, token, setToken });

  return (
    <ApolloProvider client={client}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </ApolloProvider>
  );
};

export default App;
