import React, { useContext, useMemo, useState } from "react";
import { Card, Form, Grid, Table } from "tabler-react";
import { paramCase, sentenceCase } from "change-case";
import { titleCase } from "title-case";
import UserContext from "../common/UserContext";
import { useFieldArray, useForm } from "react-hook-form";
import OrganizationFormUtils from "../../../utils/OrganizationFormUtils";
import { useMutation } from "@apollo/client";
import { UPDATE_ORGANIZATION } from "../../../graphql/mutations";
import { range } from "../../../utils/helpers";
import AutoSaveStatus from "../common/AutoSaveStatus";
import useAutoSave from "../../../hooks/useAutoSave";

const RangeAndRatesCard = ({ attr, title, footerText, rateTitle, canEdit }) => {
  const { user } = useContext(UserContext);
  const [fiftyYearError, setFiftyYearError] = useState(null);
  const [firstZeroError, setFirstZeroError] = useState(null);
  const organization = user.focusedOrganization;
  const defaultVals = () => {
    if (user.focusedOrganization[attr].length > 0)
      return user.focusedOrganization[attr];
    return Array.from(Array(5).keys()).map((i) => ({
      id: i + 1,
      startYear: "",
      endYear: "",
      rate: "",
    }));
  };

  const { register, handleSubmit, getValues, control } = useForm({
    defaultValues: {
      [attr]: defaultVals(),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: attr,
    keyName: "_id",
  });

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: ["GetUser"],
    awaitRefetchQueries: true,
  });

  const [onSubmit, lastSubmittedAt, submitting] = useAutoSave(async () => {
    let values = OrganizationFormUtils.outgoingSchema.cast(getValues());
    let filtered = values[attr].filter(
      (el) => (el.startYear !== 0 || el.endYear !== 0) && el.rate !== null
    );
    let ranges = filtered.reduce((memo, row) => {
      memo = memo.concat(range(row.startYear, row.endYear));
      return memo;
    }, []);
    let consecutiveFifty = [...Array(51).keys()];
    if (ranges.toString() !== consecutiveFifty.toString()) {
      setFiftyYearError(
        "Settings must cover 50 years and have a valid interest rate for each range."
      );
    } else {
      setFiftyYearError(null);
    }
    if (ranges.length && ranges[0] === 0) {
      setFirstZeroError(null);
    } else {
      setFirstZeroError("Start year of first row must be 0");
    }
    await updateOrganization({
      variables: {
        attributes: OrganizationFormUtils.outgoingSchema.cast(values),
      },
    });
  }, 500);

  const fiftyYearsOptions = useMemo(
    () =>
      Array.from(Array(51).keys()).map((i) => ({
        label: i,
        value: i,
      })),
    []
  );

  return (
    <Card className={`range-and-rates ${paramCase(attr)}`}>
      <Card.Header>
        <Card.Title className="mb-0">
          {title || titleCase(sentenceCase(attr))}
        </Card.Title>
        <Card.Options>
          <AutoSaveStatus
            submitting={submitting}
            lastSubmittedAt={lastSubmittedAt}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col className={`text-danger`}>{firstZeroError}</Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className={`text-danger ${fiftyYearError ? "mb-5" : ""}`}>
            {fiftyYearError}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                name="id"
                defaultValue={organization.id}
                ref={register}
                readOnly={!canEdit}
              />
              <Table className="card-table table-vcenter text-nowrap">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader className="text-center">
                      Start Year Offset
                    </Table.ColHeader>
                    <Table.ColHeader className="text-center">
                      End Year Offset
                    </Table.ColHeader>
                    <Table.ColHeader className="text-center">
                      {rateTitle}
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {fields.map((item, index) => (
                    <Table.Row key={item._id}>
                      <Table.Col>
                        <input
                          type="hidden"
                          name={`${attr}[${index}].id`}
                          defaultValue={item.id}
                          ref={register}
                          readOnly={!canEdit}
                        />
                        <select
                          name={`${attr}[${index}].startYear`}
                          className="form-control custom-select"
                          ref={register}
                          placeholder="Start"
                          defaultValue={item.startYear}
                          onChange={handleSubmit(onSubmit)}
                          disabled={!canEdit}
                        >
                          {fiftyYearsOptions.map((option, optionIndex) => (
                            <option
                              value={option.value}
                              key={`${attr}-option-${optionIndex}`}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </Table.Col>
                      <Table.Col>
                        <select
                          name={`${attr}[${index}].endYear`}
                          className="form-control custom-select"
                          ref={register}
                          placeholder="End"
                          defaultValue={item.endYear}
                          onChange={handleSubmit(onSubmit)}
                          disabled={!canEdit}
                        >
                          {fiftyYearsOptions.map((option, optionIndex) => (
                            <option
                              value={option.value}
                              key={`${attr}-option-${optionIndex}`}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </Table.Col>
                      <Table.Col>
                        <Form.InputGroup>
                          <input
                            name={`${attr}[${index}].rate`}
                            type="number"
                            placeholder="Rate"
                            className="form-control text-center"
                            defaultValue={item.rate}
                            ref={register}
                            onChange={handleSubmit(onSubmit)}
                            readOnly={!canEdit}
                          />
                          <Form.InputGroupAppend>
                            <Form.InputGroupText>%</Form.InputGroupText>
                          </Form.InputGroupAppend>
                        </Form.InputGroup>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </form>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="text-center">
            <small className="text-muted">{footerText}</small>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};
export default RangeAndRatesCard;
