import React from "react";
import { Card, Grid } from "tabler-react";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  return (
    <div className="reports-home">
      <Grid.Row>
        <Grid.Col>
          <div
            onClick={() =>
              history.push("/plans_and_reports/reserves_plan/baseline")
            }
          >
            <Card className="cursor-pointer" statusColor="blue">
              <Card.Body>
                <Card.Title>Baseline</Card.Title>
                <p className="lead">
                  The current approved plan for your organization.
                </p>
              </Card.Body>
            </Card>
          </div>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <div
            onClick={() =>
              history.push("/plans_and_reports/reserves_plan/simulation")
            }
          >
            <Card statusColor="green" className="cursor-pointer">
              <Card.Body>
                <Card.Title>Simulation</Card.Title>
                <p className="lead">
                  A “what if” utility used to calculate a potential financial
                  model that has small monetary increase that is repeated year
                  after year.
                </p>
              </Card.Body>
            </Card>
          </div>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <div
            onClick={() =>
              history.push("/plans_and_reports/reserves_plan/alternate")
            }
          >
            <Card statusColor="purple" className="cursor-pointer">
              <Card.Body>
                <Card.Title>Alternate</Card.Title>
                <p className="lead">
                  A “what if” utility that takes a target future Reserve Balance
                  and returns an unchanging the monthly payment for the given
                  number of years.
                </p>
              </Card.Body>
            </Card>
          </div>
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

export default Home;
