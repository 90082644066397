import React, { Fragment, useCallback } from "react";
import { Card, Table } from "tabler-react";
import capitalize from "capitalize";
import AccountingFormat from "../common/AccountingFormat";
import { hoverPdfOptions } from "../../../utils/pdfOptions";

const HoverReportPdf = ({
  organization,
  reportType,
  targetYear,
  hoverReportData,
  totals,
}) => {
  const categoryName = useCallback(
    (name) => {
      if (name === "customCategory")
        return capitalize(
          organization.reportsCategories.find(
            (cat) => cat.categoryType === "custom"
          ).name
        );
      return capitalize(name);
    },
    [organization]
  );

  return (
    <div
      className="report-pdf"
      data-pdf-options={JSON.stringify(hoverPdfOptions)}
    >
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>{organization.name}</Card.Title>
          <Card.Title>
            <div className="text-center">
              <div>Hover Report</div>
              <div>
                {capitalize(reportType)} - Year {targetYear}
              </div>
            </div>
          </Card.Title>
          <Card.Title>
            <div className="text-center">
              <div>Generated</div>
              <div>{new Date().toLocaleString("en-US")}</div>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table className="card-table table-vcenter">
              <Table.Header>
                <Table.Row className="text-center">
                  <Table.ColHeader></Table.ColHeader>
                  <Table.ColHeader>
                    Prior Year
                    <br />
                    <small>{parseInt(targetYear) - 1}</small>
                  </Table.ColHeader>
                  <Table.ColHeader>
                    <strong>
                      Target Year
                      <br />
                      <small>{targetYear}</small>
                    </strong>
                  </Table.ColHeader>
                  <Table.ColHeader>
                    Next Year
                    <br />
                    <small>{parseInt(targetYear) + 1}</small>
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(hoverReportData).map((category, index) => (
                  <Fragment key={`pdf-category-${index}`}>
                    <Table.Row>
                      <Table.Col className="bg-light">
                        <strong>{categoryName(category)}</strong>
                      </Table.Col>
                      <Table.Col className="bg-light text-center">
                        <AccountingFormat>
                          {parseInt(hoverReportData[category].priorYear || 0)}
                        </AccountingFormat>
                      </Table.Col>
                      <Table.Col className="bg-light text-center">
                        <AccountingFormat>
                          {parseInt(hoverReportData[category].targetYear || 0)}
                        </AccountingFormat>
                      </Table.Col>
                      <Table.Col className="bg-light text-center">
                        <AccountingFormat>
                          {parseInt(hoverReportData[category].nextYear || 0)}
                        </AccountingFormat>
                      </Table.Col>
                    </Table.Row>
                    {Object.keys(hoverReportData[category].hoverData).map(
                      (itemName, index) => (
                        <Table.Row key={`pdf-${itemName}-${index}`}>
                          <Table.Col className="text-right">
                            {itemName}
                          </Table.Col>
                          <Table.Col className="text-center">
                            <AccountingFormat>
                              {parseInt(
                                hoverReportData[category].hoverData[itemName]
                                  ?.priorYear || 0
                              )}
                            </AccountingFormat>
                          </Table.Col>
                          <Table.Col className="text-center">
                            <AccountingFormat>
                              {parseInt(
                                hoverReportData[category].hoverData[itemName]
                                  ?.targetYear || 0
                              )}
                            </AccountingFormat>
                          </Table.Col>
                          <Table.Col className="text-center">
                            <AccountingFormat>
                              {parseInt(
                                hoverReportData[category].hoverData[itemName]
                                  ?.nextYear || 0
                              )}
                            </AccountingFormat>
                          </Table.Col>
                        </Table.Row>
                      )
                    )}
                  </Fragment>
                ))}
                <Table.Row>
                  <Table.Col className="text-right">
                    <strong>Total</strong>
                  </Table.Col>
                  <Table.Col className="text-center">
                    <AccountingFormat>
                      {parseInt(totals.priorYear)}
                    </AccountingFormat>
                  </Table.Col>
                  <Table.Col className="text-center">
                    <AccountingFormat>
                      {parseInt(totals.targetYear)}
                    </AccountingFormat>
                  </Table.Col>
                  <Table.Col className="text-center">
                    <AccountingFormat>
                      {parseInt(totals.nextYear)}
                    </AccountingFormat>
                  </Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default HoverReportPdf;
