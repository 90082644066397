import React from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Card, Grid, Icon, Page } from "tabler-react";
import { useQuery } from "@apollo/client";
import { GET_ACCELERATORS } from "../../graphql/queries";
import LoadingPage from "../LoadingPage";
import ErrorRedirect from "../../components/ErrorRedirect";

const AcceleratorsPage = () => {
  const { loading, error, data } = useQuery(GET_ACCELERATORS);
  if (loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;
  const determineColor = (ext) => {
    if (ext === "xlsx") return "green-dark";
    if (ext === "docx") return "blue";
    if (ext === "pptx") return "orange";
  };
  return (
    <DefaultLayout mainClassName="accelerators">
      <Page.Content>
        <Page.Header title="Accelerators" />

        <Grid.Row className="row-cards">
          {data.accelerators.map((item, key) => (
            <Grid.Col sm={6} lg={4} key={key}>
              <Card className="p-3">
                <div className="d-flex align-items-center">
                  <a
                    href={process.env.REACT_APP_API_URL + item.documentUrl}
                    className="btn mr-1"
                  >
                    <div className="d-flex justify-content-center">
                      <span
                        className={`stamp stamp-lg bg-${determineColor(
                          item.extension
                        )} text-large text-uppercase`}
                      >
                        {item.extension[0]}
                      </span>
                    </div>
                  </a>
                  <a
                    href={process.env.REACT_APP_API_URL + item.documentUrl}
                    className="name"
                  >
                    <span>{item.name}</span>
                    <Icon name="download" className="text-muted ml-2" />
                  </a>
                </div>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      </Page.Content>
    </DefaultLayout>
  );
};

export default AcceleratorsPage;
