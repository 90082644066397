import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import {
  Alert,
  Button,
  Dimmer,
  Form,
  Grid,
  Loader,
  Page,
  PricingCard,
} from "tabler-react";
import { useMutation } from "@apollo/client";
import { ACTIVATE_SUBSCRIPTION } from "../../graphql/mutations";
import { useHistory } from "react-router-dom";
import { SubscriptionFragment } from "../../graphql/fragments";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import Bugsnag from "@bugsnag/js";
import { toast } from "react-toastify";
import InvoicePreview from "../../components/authenticated/activate_subscription/InvoicePreview";
import useStripeCard from "../../hooks/useStripeCard";
import useInvoicePreview from "../../hooks/useInvoicePreview";
import RedeemInput from "../../components/authenticated/activate_subscription/RedeemInput";

const ActivateSubscriptionPage = () => {
  const [activateSubscription] = useMutation(ACTIVATE_SUBSCRIPTION, {
    update(
      cache,
      {
        data: {
          activateSubscription: { subscription },
        },
      }
    ) {
      if (subscription) {
        cache.writeFragment({
          id: subscription.id,
          fragment: SubscriptionFragment,
          data: subscription,
          fragmentName: "SubscriptionFragment",
        });
      }
    },
  });
  const { loading: stripeLoading, form: stripeForm } = useStripeCard();
  const { loading: invoicePreviewLoading, form: invoicePreviewForm } =
    useInvoicePreview();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => setLoading(stripeLoading), [stripeLoading]);

  const handleSubmit = async () => {
    setLoading(true);
    let { error, paymentMethod } = await stripeForm.submit();

    if (error) {
      Bugsnag.notify(error);
      toast.error(error.message);
      setLoading(false);
      return;
    }

    let { data } = await activateSubscription({
      variables: {
        promoCode: invoicePreviewForm.promoCode,
        quantity: invoicePreviewForm.invoicePreview.licenseQuantity,
        startedAt: new Date().toISOString(),
        stripePaymentMethodId: paymentMethod.id,
      },
    });
    if (data.activateSubscription.errors.length) {
      data.activateSubscription.errors.forEach((error) =>
        toast.error(error.message)
      );
      return setLoading(false);
    }

    history.push("/");
  };

  return (
    <DefaultLayout mainClassName="activate-subscription">
      <Page.Content title="Activate Subscription">
        <div className="row mb-5">
          <div className="col">
            <Alert type="primary">Activate your subscription below.</Alert>
          </div>
        </div>
        <Grid.Row className="mb-5 justify-content-center">
          <Grid.Col lg={5}>
            <PricingCard>
              <Dimmer active={loading} loader>
                <PricingCard.Category>Activate</PricingCard.Category>
                <Grid.Row>
                  <Grid.Col>
                    <Grid.Row>
                      <Grid.Col>
                        <InvoicePreview
                          loading={invoicePreviewLoading}
                          invoicePreview={invoicePreviewForm.invoicePreview}
                          setInvoicePreview={
                            invoicePreviewForm.setInvoicePreview
                          }
                          recalculate={invoicePreviewForm.recalculate}
                        />
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row className="justify-content-end">
                      <Grid.Col md={8}>
                        <RedeemInput
                          promoCode={invoicePreviewForm.promoCode}
                          setPromoCode={invoicePreviewForm.setPromoCode}
                          recalculate={invoicePreviewForm.recalculate}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Grid.Row>
                      <Grid.Col>
                        <div className="mt-5 pt-1 pb-3 text-uppercase text-secondary">
                          Payment Details
                        </div>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group>
                          <CardNumberElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#495057",
                                  fontFamily:
                                    '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
                                  "::placeholder": {
                                    color: "darkgray",
                                  },
                                },
                              },
                            }}
                            onChange={(e) =>
                              stripeForm.setCardObj({
                                error: e.error,
                                complete: e.complete,
                              })
                            }
                            className="form-control"
                            data-openreplay-obscured
                          />
                          <div className="invalid-feedback">
                            {stripeForm.cardObj.error?.message}
                          </div>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group>
                          <CardExpiryElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#495057",
                                  fontFamily:
                                    '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
                                  "::placeholder": {
                                    color: "darkgray",
                                  },
                                },
                              },
                            }}
                            className="form-control"
                            onChange={(e) =>
                              stripeForm.setExpiryObj({
                                error: e.error,
                                complete: e.complete,
                              })
                            }
                          />
                          <div className="invalid-feedback text-left mb-3">
                            {stripeForm.expiryObj.error?.message}
                          </div>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col>
                        <Form.Group>
                          <CardCvcElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#495057",
                                  fontFamily:
                                    '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
                                  "::placeholder": {
                                    color: "darkgray",
                                  },
                                },
                              },
                            }}
                            className="form-control"
                            onChange={(e) =>
                              stripeForm.setCvcObj({
                                error: e.error,
                                complete: e.complete,
                              })
                            }
                          />
                          <div className="invalid-feedback text-left mb-3">
                            {stripeForm.cvcObj.error?.message}
                          </div>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col>
                        <Form.Group>
                          <input
                            className="form-control"
                            name="zip"
                            onChange={(e) => stripeForm.setZip(e.target.value)}
                            placeholder="ZIP"
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Button
                      type="button"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={loading}
                      className="px-7 mr-3 mt-5"
                      block={true}
                    >
                      {loading ? (
                        <Loader className="sm white center" />
                      ) : (
                        "Activate"
                      )}
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Dimmer>
            </PricingCard>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </DefaultLayout>
  );
};

export default ActivateSubscriptionPage;
