import { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { GET_INVOICE_PREVIEW } from "../graphql/queries";

const useInvoicePreview = () => {
  const [promoCode, setPromoCode] = useState(null);
  const [invoicePreview, setInvoicePreview] = useState({ licenseQuantity: 1 });
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();
  const getInvoicePreview = useCallback(
    async (variables) => {
      setLoading(true);
      let result = await client.query({
        query: GET_INVOICE_PREVIEW,
        fetchPolicy: "no-cache",
        variables: variables,
      });
      setLoading(false);
      return result;
    },
    [client]
  );

  // initial page load hook
  useEffect(() => {
    let run = async () => {
      let result = await getInvoicePreview();
      setInvoicePreview(result.data.invoicePreview);
    };
    run();
  }, [setInvoicePreview, getInvoicePreview]);

  const recalculate = async () => {
    if (
      !invoicePreview.licenseQuantity ||
      invoicePreview.licenseQuantity === ""
    )
      return;
    let result = await getInvoicePreview({
      quantity: invoicePreview.licenseQuantity,
      promoCode: promoCode,
    });
    setInvoicePreview(result.data.invoicePreview);
  };

  const form = {
    invoicePreview,
    setInvoicePreview,
    promoCode,
    setPromoCode,
    recalculate,
  };
  return { loading, form };
};

export default useInvoicePreview;
