import React, { useState } from "react";
import Modal from "react-modal";
import { Dimmer, Form, Table } from "tabler-react";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOM_RESERVE_ITEM } from "../../../graphql/mutations";

const NewReserveItemModal = ({ show, setShow, refetch }) => {
  const [errors, setErrors] = useState({});
  const [showFormLoader, setShowFormLoader] = useState(false);
  const [newReserveItem, setNewReserveItem] = useState({
    itemName: "",
    enabled: true,
  });
  const [createCustomReserveItem] = useMutation(CREATE_CUSTOM_RESERVE_ITEM, {
    refetchQueries: ["GetUser"],
    awaitRefetchQueries: true,
  });

  const save = async () => {
    if (newReserveItem.itemName.trim().length === 0) {
      setErrors({ itemName: { message: "Item name is required" } });
      setNewReserveItem({ ...newReserveItem, itemName: "" });
      return;
    }
    setShowFormLoader(true);
    let result = await createCustomReserveItem({
      variables: {
        attributes: newReserveItem,
      },
    });
    setShowFormLoader(false);

    let newErrors = {};
    result.data.createCustomReserveItem.errors.forEach(
      (error) => (newErrors.itemName = error)
    );
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    await refetch();
    setShow(false);
    setNewReserveItem({ itemName: "", enabled: true });
  };
  return (
    <Modal
      isOpen={show}
      onAfterOpen={(el) => {
        el.contentEl.getElementsByClassName("form-control")[0].focus();
      }}
      onRequestClose={() => setShow(false)}
      bodyOpenClassName="modal-open"
      overlayClassName="modal"
      className="modal-dialog"
      ariaHideApp={false}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">New Custom Reserve Item</h5>
          <button
            type="button"
            className="close"
            onClick={() => setShow(false)}
          />
        </div>
        <div className="modal-body">
          <Dimmer loader active={showFormLoader}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Enabled?</Table.ColHeader>
                  <Table.ColHeader>Item Name</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Col>
                    <Form.Switch
                      label="Enabled?"
                      name="enabled"
                      checked={newReserveItem.enabled}
                      onChange={(e) =>
                        setNewReserveItem({
                          ...newReserveItem,
                          enabled: e.target.checked,
                        })
                      }
                    />
                  </Table.Col>
                  <Table.Col>
                    <input
                      name="itemName"
                      type="text"
                      className={`form-control ${
                        errors.itemName && "is-invalid"
                      }`}
                      value={newReserveItem.itemName}
                      onChange={(e) =>
                        setNewReserveItem({
                          ...newReserveItem,
                          itemName: e.target.value,
                        })
                      }
                    />
                    <div className="invalid-feedback">
                      {errors.itemName?.message}
                    </div>
                  </Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </Dimmer>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={save}>
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewReserveItemModal;
