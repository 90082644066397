import React, { useContext } from "react";
import UserContext from "../common/UserContext";
import { useQuery } from "@apollo/client";
import {
  GET_BASELINE_PLAN_SETTING,
  GET_PARM_REPORT,
  GET_PERCENT_FUNDED,
} from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import ReportsHelperMenu from "./ReportsHelperMenu";
import { Alert, Card } from "tabler-react";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import ParmReportPdf from "./ParmReportPdf";

const ParmReport = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const {
    data: bData,
    loading: bLoading,
    error: bError,
  } = useQuery(GET_BASELINE_PLAN_SETTING, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  const {
    loading: pLoading,
    error: pError,
    data: pData,
  } = useQuery(GET_PERCENT_FUNDED, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      reportType: "baseline",
    },
    fetchPolicy: "no-cache",
  });

  const { loading, error, data } = useQuery(GET_PARM_REPORT, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  if (loading || bLoading || pLoading) return <LoadingPage />;
  if (error || bError || pError)
    return <ErrorRedirect error={error || bError || pError} />;

  const missingPrereqs = [
    ...data.parmReport.missingPrerequisites,
    ...bData.baselinePlanSetting.missingPrerequisites,
    ...pData.percentFunded.missingPrerequisites,
  ];
  if (missingPrereqs.length)
    return (
      <Card statusColor={color} className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>Pemaquid Aggressive Recovery Model Report</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the PARM report, please complete the following
              steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <div className="parm-report">
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix={`parm-report`} />
      </div>
      <ParmReportPdf
        color={color}
        organization={organization}
        parmReport={data.parmReport}
        baselinePlanSetting={bData.baselinePlanSetting}
        percentFunded={pData.percentFunded.rows[0].percent}
        webOnly={true}
      />
      <div className="d-none">
        <ParmReportPdf
          organization={organization}
          parmReport={data.parmReport}
          baselinePlanSetting={bData.baselinePlanSetting}
          percentFunded={pData.percentFunded.rows[0].percent}
        />
      </div>
    </div>
  );
};

export default ParmReport;
