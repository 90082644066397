export const glossaryPdfOptions = {
  filenamePrefix: `glossary`,
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".glossary-pdf",
    avoidSelector: "tbody tr",
    spacerHtml: `
      <tr class="no-stripe">
        <td class="no-stripe" colspan="${
          document.querySelectorAll(".glossary-pdf th").length
        }">
          <div class="spacer"></div>
        </td>
      </tr>
    `,
  },
};

export const theBookTocPdfOptions = {
  filenamePrefix: `the-book-toc`,
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".reserve-items-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: '<div class="spacer"></div>',
  },
};

export const reserveItemsPdfOptions = {
  filenamePrefix: `reserve-items`,
  jsPDF: {
    orientation: "landscape",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".reserve-items-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: '<div class="spacer"></div>',
  },
};

export const financialDashboardPdfOptions = {
  filenamePrefix: "financial-dashboard",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".financial-dashboard-pdf",
    avoidSelector: "tbody tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const baselinePlanPdfOptions = {
  filenamePrefix: "baseline-plan",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const annualReportPdfOptions = {
  jsPDF: {
    orientation: "landscape",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const percentFundedPdfOptions = {
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const simulationPlanPdfOptions = {
  filenamePrefix: "simulation-plan",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const alternatePlanPdfOptions = {
  filenamePrefix: "alternate-plan",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const specialRequirementReportPdfOptions = {
  filenamePrefix: "special-requirements-report",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".special-requirements-report-pdf",
    avoidSelector: "tbody tr",
    spacerHtml: `
      <tr class="no-stripe">
        <td class="no-stripe" colspan="${
          document.querySelectorAll(".special-requirements-report-pdf th")
            .length
        }">
          <div class="spacer"></div>
        </td>
      </tr>
    `,
  },
};

export const fundingSummaryPdfOptions = {
  filenamePrefix: "funding-summary",
  jsPDF: {
    orientation: "landscape",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const parmReportPdfOptions = {
  filenamePrefix: "parm-report",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".parm-report-pdf",
    avoidSelector: "tbody tr",
    spacerHtml: `
      <tr class="no-stripe">
        <td class="no-stripe" colspan="${
          document.querySelectorAll(".parm-report-pdf th").length
        }">
          <div class="spacer"></div>
        </td>
      </tr>
    `,
  },
};

export const photoAlbumPdfOptions = {
  filenamePrefix: `photo-album`,
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".photo-album-pdf",
    avoidSelector: ".row",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const hoverPdfOptions = {
  filenamePrefix: "hover",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".report-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `<div class="spacer"></div>`,
  },
};

export const changeLogPdfOptions = {
  filenamePrefix: "change-log",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".change-log-pdf",
    avoidSelector: "tbody tr",
    spacerHtml: `
      <tr class="no-stripe">
        <td class="no-stripe" colspan="${
          document.querySelectorAll(".change-log-pdf th").length
        }">
          <div class="spacer"></div>
        </td>
      </tr>
    `,
  },
};
export const changeLogTocPdfOptions = {
  filenamePrefix: "change-log",
  jsPDF: {
    orientation: "portrait",
  },
  html2canvas: { scrollY: 0, scale: 2 },
  pemaquidSettings: {
    pdfWrapperSelector: ".change-log-pdf",
    avoidSelector: ".tbody .tr",
    spacerHtml: `
      <tr class="no-stripe">
        <td class="no-stripe" colspan="${
          document.querySelectorAll(".change-log-pdf th").length
        }">
          <div class="spacer"></div>
        </td>
      </tr>
    `,
  },
};
