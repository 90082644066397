const customMoneyFormatter = (cell, formatterParams, onRendered) => {
  const emptyToSpace = (value) =>
    value === null || typeof value === "undefined" || value === ""
      ? "&nbsp;"
      : value;
  const sanitizeHTML = (value) => {
    if (value) {
      let entityMap = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;",
        "/": "&#x2F;",
        "`": "&#x60;",
        "=": "&#x3D;",
      };

      return String(value).replace(/[&<>"'`=/]/g, (s) => entityMap[s]);
    }
    return value;
  };

  let floatVal = parseFloat(cell.getValue()),
    number,
    integer,
    decimal,
    rgx;

  let decimalSym = formatterParams.decimal || ".";
  let thousandSym = formatterParams.thousand || ",";
  let symbol = formatterParams.symbol || "";
  let after = !!formatterParams.symbolAfter;
  let precision =
    typeof formatterParams.precision !== "undefined"
      ? formatterParams.precision
      : 2;

  if (isNaN(floatVal)) {
    return formatterParams.placeholder
      ? `<div class="placeholder">${formatterParams.placeholder}</div>`
      : emptyToSpace(sanitizeHTML(cell.getValue()));
  }

  number = precision !== false ? floatVal.toFixed(precision) : floatVal;
  number = String(number).split(".");

  integer = number[0];
  decimal = number.length > 1 ? decimalSym + number[1] : "";

  rgx = /(\d+)(\d{3})/;

  while (rgx.test(integer)) {
    integer = integer.replace(rgx, "$1" + thousandSym + "$2");
  }

  return after ? integer + decimal + symbol : symbol + integer + decimal;
};

export default customMoneyFormatter;
