import React from "react";
import { Card, colors, Table } from "tabler-react";
import capitalize from "capitalize";
import C3Chart from "react-c3js";
import AccountingFormat from "../common/AccountingFormat";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";
import PercentFormat from "../common/PercentFormat";

const PercentFundedTable = ({
  color,
  organization,
  percentFunded,
  reportType,
  generatedAt,
  chartRef,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  const chartData = percentFunded.reduce(
    (memo, obj, index) => {
      if (index % 5 === 0) {
        memo.years.push(obj.year);
        memo.percentages.push(obj.percent);
      }
      return memo;
    },
    { years: [], percentages: [] }
  );
  return (
    <Card statusColor={color}>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>{organization.name}</Card.Title>
        <div style={{ textAlign: "center" }}>
          <div>
            <Card.Title>{capitalize(reportType)} Percent Funded</Card.Title>
          </div>
          <div style={{ fontSize: "12px" }}>
            {`${organization.numberOfUnits} ${titleCase(
              abbrevMeasurementUnit(organization.measurementUnits)
            )}`}
          </div>
        </div>
        <Card.Title>Generated: {generatedAt}</Card.Title>
      </Card.Header>
      <C3Chart
        ref={chartRef}
        className="mt-5 d-flex justify-content-center"
        style={{ height: "20rem" }}
        data={{
          x: "years",
          columns: [
            ["years", ...chartData.years],
            ["percentFunded", ...chartData.percentages],
          ],
          colors: {
            percentFunded: ({ value }) =>
              value >= 0 ? colors["yellow"] : colors["red"],
          },
          types: {
            percentFunded: "bar",
            zero: "line",
            one: "line",
          },
          names: { percentFunded: "Percent Funded" },
          labels: {
            show: true,
            format: (v) => `${(v * 100).toFixed(0)}%`,
          },
        }}
        legend={{ show: false }}
        point={{ show: false }}
        axis={{
          y: {
            max: 1,
            tick: {
              format: (v) => `${(v * 100).toFixed(0)}%`,
            },
          },
        }}
        grid={{
          y: {
            lines: [
              {
                value: 0,
                class: "color-gray",
              },
              {
                value: 1,
                class: "color-green",
              },
            ],
          },
        }}
        tooltip={{
          format: {
            value: (v) => `${(v * 100).toFixed(0)}%`,
          },
        }}
      />
      <Card.Body>
        <div className="table-responsive">
          <Table className="table-vcenter">
            <Table.Body>
              <Table.Row className="text-center">
                <Table.Col>
                  {"< 30% ="} <strong className="color-red">Weak</strong>
                </Table.Col>
                <Table.Col>
                  {"31 - 60% ="} <span className="color-red">Fair</span>
                </Table.Col>
                <Table.Col>
                  {"61 - 90% ="} <span className="color-green">Strong</span>
                </Table.Col>
                <Table.Col>
                  {"> 90% ="}{" "}
                  <strong className="color-green">Recommended</strong>
                </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
          <Table className="card-table table-vcenter">
            <Table.Header>
              <Table.Row className="text-center">
                <Table.ColHeader>Year</Table.ColHeader>
                <Table.ColHeader>Accumulated Depreciation</Table.ColHeader>
                <Table.ColHeader>
                  {capitalize(reportType)} Balance
                </Table.ColHeader>
                <Table.ColHeader>Percent Funded</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {percentFunded.map((row, index) => (
                <Table.Row
                  className="text-center"
                  key={`${reportType}-percent-funded-year-${index}`}
                >
                  <Table.Col>{row.year}</Table.Col>
                  <Table.Col>
                    <AccountingFormat>
                      {row.accumulatedDepreciation}
                    </AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.balance}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <PercentFormat>{row.percent}</PercentFormat>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PercentFundedTable;
