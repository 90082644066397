import React, { Fragment } from "react";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";

const ReserveItemsExcel = ({ organization, reserveItems }) => {
  return (
    <Fragment>
      <table data-excel-table="Reserve Items">
        <thead>
          <tr>
            <td colSpan={11}>{`${organization.name} ${
              organization.numberOfUnits
            } ${titleCase(
              abbrevMeasurementUnit(organization.measurementUnits, {
                plural: true,
              })
            )}`}</td>
          </tr>
          <tr></tr>
          <tr>
            <th>Item Name</th>
            <th>Reports Category</th>
            <th>Last Installed Date</th>
            <th>Est. Useful Life (Yrs)</th>
            <th>Yrs to Replace</th>
            <th>Quantity</th>
            <th>Latest Cost Year</th>
            <th>Cost per Unit</th>
            <th>Total Cost</th>
            <th>RUL</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {reserveItems.map((item, index) => (
            <tr key={`reserve-item-excel-${index}`}>
              <td>{item.itemName}</td>
              <td>{item.reportsCategory?.name}</td>
              <td>{item.lastInstall}</td>
              <td>{item.estimatedUsefulLife}</td>
              <td>{item.yearsToReplace}</td>
              <td>{item.itemQuantity}</td>
              <td>{item.latestCostYear}</td>
              <td>{item.costPerItem}</td>
              <td>{item.totalCost}</td>
              <td>{item.remainingUsefulLife}</td>
              <td>{item.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ReserveItemsExcel;
