import React, { Fragment } from "react";
import { Loader } from "tabler-react";

const AutoSaveStatus = ({ submitting, lastSubmittedAt }) => {
  const submittingFragment = (
    <div className="d-flex">
      <Loader className="sm" />
      <div className="ml-2">Saving...</div>
    </div>
  );

  const lastSubmittedFragment = lastSubmittedAt !== null && (
    <div className="text-muted" title={`Last Saved: ${lastSubmittedAt}`}>
      Saved
    </div>
  );
  return (
    <Fragment>
      {submitting ? submittingFragment : lastSubmittedFragment}
    </Fragment>
  );
};

export default AutoSaveStatus;
