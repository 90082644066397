import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../components/authenticated/common/UserContext";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Grid, Page } from "tabler-react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ReserveItemsTable from "../../components/authenticated/reserve_items/ReserveItemsTable";
import Glossary from "../../components/authenticated/reserve_items/Glossary";
import GlossaryPdf from "../../components/authenticated/reserve_items/GlossaryPdf";
import ProjectedItemCosts from "../../components/authenticated/reserve_items/ProjectedItemCosts";
import ReserveItemsPdf from "../../components/authenticated/reserve_items/ReserveItemsPdf";
import ReserveItemsNav from "../../components/authenticated/reserve_items/ReserveItemsNav";
import { abbrevMeasurementUnit } from "../../utils/helpers";
import { titleCase } from "title-case";
import { GET_RESERVE_ITEMS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import Loading from "../../components/authenticated/common/Loading";
import ErrorRedirect from "../../components/ErrorRedirect";
import ReserveItemsExcel from "../../components/authenticated/reserve_items/ReserveItemsExcel";

const ReserveItemsPage = () => {
  const { user } = useContext(UserContext);
  const organizationRef = useRef(user.focusedOrganization);
  const [isSaving, setIsSaving] = useState(false);
  const { loading, data, refetch, error } = useQuery(GET_RESERVE_ITEMS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  // This useEffect is for 2 scenarios:
  // 1) We switch focused organizations and want to refetch reserve items for the new focused org.
  //    We deliberately are comparing focused organization by id, since comparing objects does so by reference,
  //    and they will never be equal.
  // 2) We have updated a reports category name, and we want that name to update accordingly in the dropdown
  //    in the reserve items table without having to manually refresh the page
  useEffect(() => {
    if (user.organizations.length === 0) return;
    if (!user.focusedOrganization) return;

    if (user.focusedOrganization.id !== organizationRef.current.id) {
      organizationRef.current = user.focusedOrganization;
      refetch();
    }

    const prevReportCategories = organizationRef.current.reportsCategories.map(
      (r) => r.name
    );
    const currentReportCategories =
      user.focusedOrganization.reportsCategories.map((r) => r.name);

    if (
      JSON.stringify(prevReportCategories) !==
      JSON.stringify(currentReportCategories)
    ) {
      organizationRef.current = user.focusedOrganization;
      refetch();
    }
  }, [user, organizationRef, refetch]);

  let { path } = useRouteMatch();

  if (
    user.organizations.length === 0 ||
    (organizationRef.current && !organizationRef.current.onboarded) ||
    !organizationRef.current
  )
    return <Redirect to="/organizations/new" />;

  if (loading) return <Loading />;
  if (error) return <ErrorRedirect error={error} />;

  const canEdit = organizationRef.current.canEdit.value;

  const disabledReserveItems = data.reserveItems.filter((e) => !e.enabled);
  const enabledReserveItems = data.reserveItems.filter((e) => e.enabled);

  return (
    <DefaultLayout mainClassName="reserve-items">
      <Page.Content>
        <Page.Header
          title={organizationRef.current.name}
          subTitle={`${organizationRef.current.numberOfUnits} ${titleCase(
            abbrevMeasurementUnit(organizationRef.current.measurementUnits, {
              plural: true,
            })
          )}`}
          className="mb-0"
        />
        <Switch>
          <Route exact path={`${path}/`}>
            <Grid.Row>
              <Grid.Col className="d-flex justify-content-between">
                <ReserveItemsNav
                  refetch={refetch}
                  organizationRef={organizationRef}
                  enabledReserveItems={enabledReserveItems}
                  disabledReserveItems={disabledReserveItems}
                  isSaving={isSaving}
                  pdfFilenamePrefix="reserve-items-enabled"
                  canEdit={canEdit}
                  canExportExcel
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <ReserveItemsTable
                  organization={organizationRef.current}
                  reserveItems={data.reserveItems}
                  setIsSaving={setIsSaving}
                  showingTab="enabled"
                  canEdit={canEdit}
                />
                <div className="d-none">
                  <div className="reserve-items-pdf">
                    <ReserveItemsPdf
                      organization={organizationRef.current}
                      showingTab="enabled"
                      reserveItems={enabledReserveItems}
                    />
                  </div>
                </div>
                <div className="d-none">
                  <ReserveItemsExcel
                    organization={organizationRef.current}
                    reserveItems={enabledReserveItems}
                  />
                </div>
              </Grid.Col>
            </Grid.Row>
          </Route>
          <Route exact path={`${path}/disabled`}>
            <Grid.Row>
              <Grid.Col className="d-flex justify-content-between">
                <ReserveItemsNav
                  refetch={refetch}
                  organizationRef={organizationRef}
                  enabledReserveItems={enabledReserveItems}
                  disabledReserveItems={disabledReserveItems}
                  isSaving={isSaving}
                  pdfFilenamePrefix="reserve-items-disabled"
                  canEdit={canEdit}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <ReserveItemsTable
                  organization={organizationRef.current}
                  reserveItems={data.reserveItems}
                  setIsSaving={setIsSaving}
                  showingTab="disabled"
                  canEdit={canEdit}
                />
                <div className="d-none">
                  <div className="reserve-items-pdf">
                    <ReserveItemsPdf
                      organization={organizationRef.current}
                      showingTab="disabled"
                      reserveItems={disabledReserveItems}
                    />
                  </div>
                </div>
              </Grid.Col>
            </Grid.Row>
          </Route>
          <Route exact path={`${path}/glossary`}>
            <Grid.Row>
              <Grid.Col className="d-flex justify-content-between">
                <ReserveItemsNav
                  refetch={refetch}
                  organizationRef={organizationRef}
                  enabledReserveItems={enabledReserveItems}
                  disabledReserveItems={disabledReserveItems}
                  isSaving={isSaving}
                  pdfFilenamePrefix="glossary"
                  canEdit={canEdit}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={12}>
                <Glossary />
                <div className="d-none">
                  <GlossaryPdf organization={organizationRef.current} />
                </div>
              </Grid.Col>
            </Grid.Row>
          </Route>
          <Route exact path={`${path}/inflation_projected_costs`}>
            <Grid.Row>
              <Grid.Col className="d-flex justify-content-between">
                <ReserveItemsNav
                  refetch={refetch}
                  organizationRef={organizationRef}
                  enabledReserveItems={enabledReserveItems}
                  disabledReserveItems={disabledReserveItems}
                  isSaving={isSaving}
                  downloadPdfAllowed={false}
                  canEdit={canEdit}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={12} className="p-0">
                <ProjectedItemCosts />
              </Grid.Col>
            </Grid.Row>
          </Route>
        </Switch>
      </Page.Content>
    </DefaultLayout>
  );
};

export default ReserveItemsPage;
