import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Button, Card, Form, Grid, Icon, Page } from "tabler-react";
import { sentenceCase } from "change-case";
import SaveButton from "../../components/authenticated/common/SaveButton";
import React, { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ROLES } from "../../graphql/queries";
import { CREATE_INVITATIONS } from "../../graphql/mutations";
import LoadingPage from "../LoadingPage";
import UserContext from "../../components/authenticated/common/UserContext";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, object } from "yup";
import { toast } from "react-toastify";
import { titleCase } from "title-case";
import SettingsFormUtils from "../../utils/SettingsFormUtils";

const NewOrganizationInviteUsersPage = () => {
  const { user } = useContext(UserContext);
  const { loading, data } = useQuery(GET_ROLES);
  const [createInvitations] = useMutation(CREATE_INVITATIONS);
  const { control, register, handleSubmit, errors, reset, formState } = useForm(
    {
      defaultValues: {
        inviteUsers: [],
      },
      mode: "onChange",
      resolver: yupResolver(
        object({
          inviteUsers: array().of(SettingsFormUtils.outgoingSchema),
        })
      ),
    }
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "inviteUsers",
  });
  const [remainingSeats, setRemainingSeats] = useState(
    user.subscription.remainingSeats
  );

  const addRow = (row) => {
    if (remainingSeats > 0) {
      append(row);
      setRemainingSeats(remainingSeats - 1);
    }
  };

  const removeRow = (index) => {
    remove(index);
    setRemainingSeats(remainingSeats + 1);
  };

  useEffect(() => {
    if (!loading)
      reset({
        inviteUsers: [{ email: "", role: "" }],
      });
  }, [loading, reset]);

  const history = useHistory();

  const onSubmit = async (values) => {
    values = values.inviteUsers.filter((row) => row.email.length);
    await createInvitations({
      variables: {
        attributesArray: values,
      },
    });
    toast.success("Invitations sent.");
    history.push("/reserve_items");
  };

  if (loading) return <LoadingPage />;

  if (!user.subscription.canInviteUsers.value)
    return <Redirect to="/reserve_items" />;

  return (
    <DefaultLayout>
      <Page.Content>
        <Page.Header title="New Organization" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid.Row className="justify-content-center">
            <Grid.Col width={12} lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <Card.Title>Invite Users</Card.Title>
                  <Card.Title>Remaining Invites: {remainingSeats}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row className="mb-5">
                    <Grid.Col>
                      <p>Invite collaborators to join your team</p>
                    </Grid.Col>
                  </Grid.Row>
                  {fields.map((item, index) => (
                    <Grid.Row key={item.id} className="mb-5">
                      <Grid.Col md={4}>
                        <Form.Group className="m-0">
                          <input
                            ref={register}
                            name={`inviteUsers[${index}].email`}
                            placeholder="Email"
                            className={`form-control ${
                              errors.inviteUsers &&
                              errors.inviteUsers[index]?.email &&
                              "is-invalid"
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.inviteUsers &&
                              errors.inviteUsers[index].email?.message}
                          </div>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group className="m-0">
                          <select
                            ref={register}
                            name={`inviteUsers[${index}].role`}
                            defaultValue=""
                            className="custom-select form-control"
                          >
                            <option value="" disabled hidden>
                              Select Role
                            </option>
                            {data.roles.map((role) => (
                              <option
                                key={`role-${role.role}`}
                                value={role.role}
                              >
                                {sentenceCase(role.role)}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            {errors.inviteUsers &&
                              errors.inviteUsers[index].role?.message}
                          </div>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={1}>
                        <Button
                          type="button"
                          color="secondary"
                          onClick={() => removeRow(index)}
                        >
                          <Icon name="trash" className="text-danger" />
                        </Button>
                      </Grid.Col>
                    </Grid.Row>
                  ))}
                  <Grid.Row>
                    <Grid.Col className="d-flex justify-content-end">
                      <Button
                        type="button"
                        color="secondary"
                        disabled={remainingSeats === 0}
                        onClick={() => addRow({ email: "", role: "" })}
                      >
                        Add Another User
                      </Button>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-5">
                    <Grid.Col>
                      <p>
                        <u>Types of roles:</u>
                      </p>
                      {data.roles.map((r) => (
                        <div key={`description-${r.role}`}>
                          <small>
                            <b>{titleCase(sentenceCase(r.role))}:</b>{" "}
                            <span>{r.description}</span>
                          </small>
                        </div>
                      ))}
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="justify-content-center">
            <Grid.Col width={12} lg={6}>
              <SaveButton
                onSubmit={handleSubmit(onSubmit)}
                block={true}
                disabled={
                  !formState.isDirty ||
                  formState.isSubmitting ||
                  !formState.isValid
                }
              >
                Send Invitations
              </SaveButton>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-center">
              <NavLink to={`/reserve_items`} className="mt-3">
                Skip
              </NavLink>
            </Grid.Col>
          </Grid.Row>
        </form>
      </Page.Content>
    </DefaultLayout>
  );
};

export default NewOrganizationInviteUsersPage;
