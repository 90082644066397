import React, { Fragment } from "react";
import { bool, object, string } from "yup";
import StandaloneFormLayout from "../components/StandaloneFormLayout";
import logo from "../logo.png";
import {
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Loader,
  PricingCard,
} from "tabler-react";
import { Link } from "react-router-dom";
import { CREATE_SUBSCRIPTION } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const SignUpPage = () => {
  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const { register, handleSubmit, errors, formState, setError } = useForm({
    defaultValues: {
      email: "",
      password: "",
      agreedToTermsAndPrivacyPolicy: false,
    },
    resolver: yupResolver(
      object({
        email: string().email().required(),
        password: string().required(),
        agreedToTermsAndPrivacyPolicy: bool().oneOf(
          [true],
          "Agreeing to the terms and privacy policy is required."
        ),
      })
    ),
  });
  const { isSubmitting, submitCount } = formState;

  const onSubmit = async ({
    email,
    password,
    agreedToTermsAndPrivacyPolicy,
  }) => {
    let { data } = await createSubscription({
      variables: {
        email: email,
        password: password,
        agreedToTermsAndPrivacyPolicy: agreedToTermsAndPrivacyPolicy,
      },
    });

    if (data.createSubscription.errors.length) {
      data.createSubscription.errors.forEach((error) =>
        setError(error.path.join("."), { message: error.message })
      );
      return;
    }

    try {
      const auth = getAuth(getApp());
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      throw e;
    }
  };

  return (
    <StandaloneFormLayout imageURL={logo} className="sign-up" width={7}>
      <Form className="card" onSubmit={handleSubmit(onSubmit)}>
        <Card.Body className="p-6">
          <Dimmer active={isSubmitting} loader>
            <Card.Title RootComponent="div" className="text-center">
              Sign Up for a New Account
            </Card.Title>
            <Grid.Row>
              <Grid.Col>
                <PricingCard.AttributeList>
                  <PricingCard.AttributeItem>
                    <strong>Each license includes:</strong>
                  </PricingCard.AttributeItem>
                  <PricingCard.AttributeItem>
                    <strong>4 </strong>
                    Collaborators
                  </PricingCard.AttributeItem>
                  <PricingCard.AttributeItem hasIcon available>
                    Single HOA Organization
                  </PricingCard.AttributeItem>
                  <PricingCard.AttributeItem hasIcon available>
                    Reserves Projections
                  </PricingCard.AttributeItem>
                  <PricingCard.AttributeItem hasIcon available>
                    Annual Reports
                  </PricingCard.AttributeItem>
                  <PricingCard.AttributeItem hasIcon available>
                    Customer Support
                  </PricingCard.AttributeItem>
                </PricingCard.AttributeList>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Email">
                  <input
                    ref={register}
                    name="email"
                    placeholder="Enter Email"
                    className={`form-control ${errors.email && "is-invalid"}`}
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </Form.Group>
                <Form.Group label="Password">
                  <input
                    data-openreplay-obscured
                    ref={register}
                    name="password"
                    placeholder="Password"
                    type="password"
                    className={`form-control ${
                      errors.password && "is-invalid"
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                </Form.Group>
                <Form.Group>
                  <label className="custom-switch">
                    <input
                      ref={register}
                      type="checkbox"
                      name="agreedToTermsAndPrivacyPolicy"
                      className="custom-switch-input"
                    />
                    <span className="custom-switch-indicator" />
                    <span className="custom-switch-description">
                      <Fragment>
                        I agree with the&nbsp;
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          terms
                        </a>
                        &nbsp;and the&nbsp;
                        <a
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacy policy
                        </a>
                      </Fragment>
                    </span>
                  </label>
                  <span
                    className={`invalid-feedback ${
                      submitCount > 0 && errors.agreedToTermsAndPrivacyPolicy
                        ? "d-block"
                        : ""
                    }`}
                  >
                    {errors.agreedToTermsAndPrivacyPolicy?.message}
                  </span>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Form.Footer>
              <Button
                type="submit"
                color="primary"
                block={true}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Loader className="sm white center" />
                ) : (
                  "Sign up"
                )}
              </Button>
            </Form.Footer>
          </Dimmer>
        </Card.Body>
        <Card.Footer className="text-center">
          Already have an account? <Link to="/">Log in</Link>
        </Card.Footer>
      </Form>
    </StandaloneFormLayout>
  );
};

export default SignUpPage;
