import React from "react";
import { NumericFormat } from "react-number-format";

const CurrencyFormat = ({ children, className, decimalScale }) => {
  return (
    <NumericFormat
      className={className}
      decimalScale={decimalScale}
      value={children}
      thousandSeparator={true}
      displayType="text"
      prefix="$"
    />
  );
};

export default CurrencyFormat;
