import React, { Fragment, useState } from "react";
import logo from "../logo.png";
import { Button, Card, Form } from "tabler-react";
import StandaloneFormLayout from "../components/StandaloneFormLayout";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { ACCEPT_INVITATION_UNAUTHENTICATED } from "../graphql/mutations";
import useQueryParams from "../hooks/useQueryParams";
import { getApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { GET_INVITATION } from "../graphql/queries";
import LoadingPage from "./LoadingPage";
import ErrorRedirect from "../components/ErrorRedirect";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const AcceptInvitationPage = () => {
  const query = useQueryParams();
  let invitationToken = query.get("invitation_token");
  const history = useHistory();

  const {
    data: iData,
    loading,
    error,
  } = useQuery(GET_INVITATION, {
    skip: !invitationToken || invitationToken.length === 0,
    variables: {
      token: invitationToken,
    },
  });
  const AUTH_ERRORS = ["auth/user-not-found", "auth/wrong-password"];
  const { register, handleSubmit, formState } = useForm();
  const [errorMessages, setErrorMessages] = useState([]);
  const { isSubmitting } = formState;

  const [acceptInvitation] = useMutation(ACCEPT_INVITATION_UNAUTHENTICATED);

  const onSubmit = async (vals) => {
    let { data } = await acceptInvitation({
      variables: {
        password: vals.password,
        invitationToken: invitationToken,
        agreedToTermsAndPrivacyPolicy: vals.agreedToTermsAndPrivacyPolicy,
      },
    });
    if (data.acceptInvitationUnauthenticated?.errors?.length > 0) {
      setErrorMessages(
        data.acceptInvitationUnauthenticated.errors.map((e) => e.message)
      );
    } else {
      try {
        const auth = getAuth(getApp());
        await signInWithEmailAndPassword(
          auth,
          data.acceptInvitationUnauthenticated.user.email,
          vals.password
        );
        toast.info("Invitation accepted.", { toastId: "invitation-accepted" });
        history.push("/");
      } catch (e) {
        if (AUTH_ERRORS.includes(e.code)) {
          setErrorMessages([
            "The username or password you entered is incorrect.",
          ]);
        } else {
          throw e;
        }
      }
    }
  };

  if (!invitationToken || invitationToken.length === 0)
    return (
      <StandaloneFormLayout imageURL={logo}>
        <Card.Body className="p-6">
          <Card.Title className="text-center">Invitation Not Found</Card.Title>
        </Card.Body>
      </StandaloneFormLayout>
    );

  if (isSubmitting || loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;

  return (
    <StandaloneFormLayout imageURL={logo}>
      <form className="card" onSubmit={handleSubmit(onSubmit)}>
        <Card.Body className="p-6">
          <Card.Title>Welcome</Card.Title>
          <p>
            To accept your invitation, set a password and review the terms and
            conditions below
          </p>
          <input
            ref={register}
            type="hidden"
            name="invitationToken"
            defaultValue={invitationToken}
          />
          <Form.Group label="Email">
            <span>{iData?.invitation?.email}</span>
          </Form.Group>
          <Form.Group label="Set Password">
            <input
              ref={register}
              name="password"
              placeholder="Password"
              className="form-control"
              type="password"
              data-openreplay-obscured
            />
          </Form.Group>
          <Form.Group>
            <label className="custom-switch m-0">
              <input
                type="checkbox"
                ref={register}
                className="custom-switch-input"
                name="agreedToTermsAndPrivacyPolicy"
                onChange={async () => {}}
              />
              <span className="custom-switch-indicator" />
              <span className="custom-switch-description">
                <Fragment>
                  I agree with the&nbsp;
                  <a href="/terms" target="_blank" rel="noopener noreferrer">
                    terms
                  </a>
                  &nbsp;and the&nbsp;
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    privacy policy
                  </a>
                </Fragment>
              </span>
            </label>
          </Form.Group>
          {errorMessages.map((message, index) => (
            <span key={`error-${index}`} className="invalid-feedback d-block">
              {message}
            </span>
          ))}
          <Form.Footer>
            <Button
              disabled={isSubmitting}
              type="submit"
              color="primary"
              block={true}
            >
              Submit
            </Button>
          </Form.Footer>
        </Card.Body>
      </form>
    </StandaloneFormLayout>
  );
};
export default AcceptInvitationPage;
