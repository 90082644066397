import ChangeLogVersionLogs from "./ChangeLogVersionLogs";
import React, { Fragment } from "react";
import ChangeLogReportToc from "./ChangeLogReportToc";
import { sentenceCase } from "change-case";

const ChangeLogReportPdf = ({
  generatedAt,
  webOnly,
  color,
  organization,
  timeframe,
  changeLogReport,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");
  const humanTimeframe = sentenceCase(timeframe || "");
  return (
    <Fragment>
      <ChangeLogReportToc
        organization={organization}
        webOnly={webOnly}
        generatedAt={generatedAt}
      />
      <div id="organization-change-log">
        <ChangeLogVersionLogs
          organization={organization}
          versions={changeLogReport?.organizationVersions}
          title={`Organization/Dashboard Change Log (${humanTimeframe})`}
          generatedAt={generatedAt}
          color={color}
          webOnly={webOnly}
        />
      </div>
      <div id="reserve-items-change-log">
        <ChangeLogVersionLogs
          versions={changeLogReport?.reserveItemVersions}
          organization={organization}
          title={`Reserve Item Change Log (${humanTimeframe})`}
          generatedAt={generatedAt}
          color={color}
          webOnly={webOnly}
          forReserveItemLogs={true}
        />
      </div>
      <div id="baseline-plan-settings-change-log">
        <ChangeLogVersionLogs
          organization={organization}
          versions={changeLogReport?.baselinePlanSettingVersions}
          title={`Baseline Plan Change Log (${humanTimeframe})`}
          generatedAt={generatedAt}
          color={color}
          webOnly={webOnly}
        />
      </div>
      <div id="simulation-plan-settings-change-log">
        <ChangeLogVersionLogs
          organization={organization}
          versions={changeLogReport?.simulationPlanSettingVersions}
          title={`Simulation Plan Change Log (${humanTimeframe})`}
          generatedAt={generatedAt}
          color={color}
          webOnly={webOnly}
        />
      </div>
      <div id="alternate-plan-settings-change-log">
        <ChangeLogVersionLogs
          organization={organization}
          versions={changeLogReport?.alternatePlanSettingVersions}
          title={`Alternate Plan Change Log (${humanTimeframe})`}
          generatedAt={generatedAt}
          color={color}
          webOnly={webOnly}
        />
      </div>
      <div id="srr-settings-change-log">
        <ChangeLogVersionLogs
          organization={organization}
          versions={changeLogReport?.specialRequirementReportSettingVersions}
          title={`Special Req Report Change Log (${humanTimeframe})`}
          generatedAt={generatedAt}
          color={color}
          webOnly={webOnly}
        />
      </div>
    </Fragment>
  );
};

export default ChangeLogReportPdf;
