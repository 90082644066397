import React, { Fragment, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useMutation } from "@apollo/client";
import {
  DESTROY_UPLOADED_FILE,
  UPLOAD_RESERVE_ITEM_FILE_MUTATION,
} from "../../../graphql/mutations";
import { ReserveItemFragment } from "../../../graphql/fragments";
import { Button, Loader } from "tabler-react";
import { toast } from "react-toastify";

const PhotoUpload = ({ cell, canEdit }) => {
  const reserveItem = cell.getData();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const mountedRef = useRef(true);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [photoBase64Preview, setPhotoBase64Preview] = useState(null);

  useEffect(() => {
    if (reserveItem.photoUrl) setPhotoUrl(reserveItem.photoUrl);
    if (reserveItem.photoBase64Preview)
      setPhotoBase64Preview(reserveItem.photoBase64Preview);
  }, [reserveItem]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const [uploadReserveItemFile] = useMutation(
    UPLOAD_RESERVE_ITEM_FILE_MUTATION,
    {
      update(cache, { data: { uploadReserveItemFile } }) {
        if (uploadReserveItemFile.reserveItem) {
          cache.writeFragment({
            id: uploadReserveItemFile.id,
            fragment: ReserveItemFragment,
            data: uploadReserveItemFile.reserveItem,
            fragmentName: "ReserveItemFragment",
          });
        } else {
          uploadReserveItemFile.errors.forEach((error) =>
            toast.error(error.message)
          );
        }
      },
    }
  );

  const [destroyFile] = useMutation(DESTROY_UPLOADED_FILE, {
    update(cache, { data: { destroyReserveItemUploadedFile } }) {
      cache.writeFragment({
        id: destroyReserveItemUploadedFile.id,
        fragment: ReserveItemFragment,
        data: destroyReserveItemUploadedFile,
        fragmentName: "ReserveItemFragment",
      });
    },
  });

  const performDestroy = async () => {
    setSubmitting(true);
    await destroyFile({
      variables: {
        reserveItemId: reserveItem.id,
        photo: true,
      },
    });
    if (cell) cell.setValue(null);
    if (mountedRef.current) setSubmitting(false);
  };

  const performUpload = async (e) => {
    setSubmitting(true);
    if (e.target.validity.valid) {
      let { data } = await uploadReserveItemFile({
        variables: {
          attributes: {
            reserveItemId: reserveItem.id,
            photo: e.currentTarget.files[0],
          },
        },
      });
      if (cell) {
        let row = cell.getRow();
        row.update({
          photoUrl: data.uploadReserveItemFile.reserveItem.photoUrl,
          photoBase64Preview:
            data.uploadReserveItemFile.reserveItem.photoBase64Preview,
        });
      }
    }
    if (mountedRef.current) setSubmitting(false);
  };

  if (submitting)
    return (
      <div className="d-flex w-100 justify-content-center align-items-center">
        <Loader />
      </div>
    );

  if (photoBase64Preview)
    return (
      <Fragment>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div>
            <div
              className="photo text-center"
              onClick={() => setImageModalOpen(true)}
            >
              <img
                src={photoBase64Preview}
                alt="reserveItem"
                height="50"
                width="50"
              />
            </div>
            {canEdit && (
              <div>
                <Button
                  size="sm"
                  link
                  type="button"
                  onClick={() => performDestroy()}
                >
                  remove
                </Button>
              </div>
            )}
          </div>
        </div>

        <Modal
          isOpen={imageModalOpen}
          onRequestClose={() => setImageModalOpen(false)}
          bodyOpenClassName="modal-open"
          overlayClassName="modal"
          className="modal-dialog photo"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{reserveItem.itemName}</h5>
              <button
                type="button"
                className="close"
                onClick={() => setImageModalOpen(false)}
              />
            </div>
            <div className="modal-body">
              <img
                src={`${process.env.REACT_APP_API_URL}${photoUrl}`}
                alt={reserveItem.itemName}
              />
            </div>
          </div>
        </Modal>
      </Fragment>
    );

  if (canEdit)
    return (
      <div className="w-100 d-flex justify-content-center">
        <div className="choose-file">
          <Button type="button" color="secondary">
            Browse
          </Button>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => performUpload(e)}
          />
        </div>
      </div>
    );

  return (
    <div className="w-100 d-flex justify-content-center">
      <small className="text-muted">
        No
        <br />
        Photo
      </small>
    </div>
  );
};

export default PhotoUpload;
