import React from "react";
import { Card, Grid } from "tabler-react";
import { theBookTocPdfOptions } from "../../../utils/pdfOptions";

const TheBookToc = ({ organization, webOnly, generatedAt }) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");
  return (
    <div
      className={webOnly ? undefined : "report-pdf"}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(theBookTocPdfOptions)
      }
    >
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="display-3 mb-3">{organization.name}</h2>
              <h5 className="text-muted">Generated: {generatedAt}</h5>
              <h4 className="mt-5">Contents</h4>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#reserve-items"
                >
                  Reserve Items - Enabled
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#financial-dashboard"
                >
                  Financial Dashboard
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#funding-summary"
                >
                  Funding Summary
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#baseline-plan"
                >
                  Baseline Plan
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#baseline-annual-report"
                >
                  Baseline Annual Report
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#baseline-percent-funded"
                >
                  Baseline Percent Funded
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#simulation-plan"
                >
                  Simulation Plan
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#simulation-annual-report"
                >
                  Simulation Annual Report
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#simulation-percent-funded"
                >
                  Simulation Percent Funded
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#alternate-plan"
                >
                  Alternate Plan
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#alternate-annual-report"
                >
                  Alternate Annual Report
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#alternate-percent-funded"
                >
                  Alternate Percent Funded
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#srr-report"
                >
                  Special Requirements Report
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#parm-report"
                >
                  Pemaquid Aggressive Recovery Model Report
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#glossary"
                >
                  Glossary
                </a>
              </p>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TheBookToc;
