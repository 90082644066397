import html2pdf from "html2pdf.js";

/* Custom override of https://github.com/eKoopmans/html2pdf.js/blob/master/src/plugin/pagebreaks.js */

// Refs to original functions.
var orig = {
  toContainer: html2pdf.Worker.prototype.toContainer,
};

html2pdf.Worker.prototype.toContainer = function toContainer() {
  return orig.toContainer.call(this).then(function toContainer_graph() {
    let root = this.prop.container;
    let paths = root.querySelectorAll(
      ".c3-line-baseline,.c3-line-alternate, .c3-line-simulation"
    );
    for (let path of paths) {
      path.style["fill-opacity"] = 0;
    }

    let legends = root.querySelectorAll(".c3-legend-item");
    for (let legend of legends) {
      let text = legend.querySelector("text");
      text.style["font-size"] = 12;
      text.style["font-family"] = "sans-serif";
    }

    let axisTicks = root.querySelectorAll(".tick");
    for (let tick of axisTicks) {
      let text = tick.querySelector("text");
      text.style["font-size"] = 10;
      text.style["font-family"] = "sans-serif";
      let line = tick.querySelector("line");
      line.style.fill = "transparent";
      line.style.stroke = "#000";
    }

    let axisPaths = root.querySelectorAll(".domain");
    for (let path of axisPaths) {
      path.style.fill = "transparent";
      path.style.stroke = "#000";
    }
  });
};
