import React, { Fragment, useContext } from "react";
import { Alert, Card } from "tabler-react";
import UserContext from "../common/UserContext";
import { useQuery } from "@apollo/client";
import { GET_BASELINE_PLAN } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import ReportsHelperMenu from "./ReportsHelperMenu";
import BaselinePlanPdf from "./BaselinePlanPdf";
import BaselinePlanTable from "./BaselinePlanTable";

const BaselinePlan = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { data, loading, error } = useQuery(GET_BASELINE_PLAN, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;

  const missingPrereqs = data.baselinePlan.missingPrerequisites;
  if (missingPrereqs.length)
    return (
      <Card statusColor={color}>
        <Card.Header>
          <Card.Title>Reserves Baseline Plan</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p>
              In order to view the Baseline Plan Report, please complete the
              following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <Fragment>
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix="baseline" />
      </div>
      <BaselinePlanTable
        organization={organization}
        color={color}
        plan={data.baselinePlan.rows}
      />
      <div className="d-none">
        <BaselinePlanPdf
          organization={organization}
          plan={data.baselinePlan.rows}
        />
      </div>
    </Fragment>
  );
};

export default BaselinePlan;
