import ApolloClientOptions from "./ApolloClientOptions";
import { ApolloClient } from "@apollo/client";

class ApolloClientBuilder {
  static client = ({ user, token, setToken }) => {
    const clientOptions = new ApolloClientOptions({ user, token, setToken });
    return new ApolloClient({
      link: clientOptions.link(),
      cache: clientOptions.cache(),
    });
  };
}

export default ApolloClientBuilder;
