import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SUBSCRIPTION_BILLING_DETAILS } from "../../../graphql/queries";
import { Card, Grid, Loader } from "tabler-react";
import CurrencyFormat from "../common/CurrencyFormat";
import capitalize from "capitalize";
import { CREATE_STRIPE_PORTAL_SESSION } from "../../../graphql/mutations";

const SubscriptionBillingDetailsCard = () => {
  const { loading, error, data } = useQuery(GET_SUBSCRIPTION_BILLING_DETAILS);
  const [createPortalSession] = useMutation(CREATE_STRIPE_PORTAL_SESSION);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionUrl, setSessionUrl] = useState(null);

  useEffect(() => {
    const run = async () => {
      setSessionLoading(true);
      const { data } = await createPortalSession();
      setSessionUrl(data.createStripePortalSession.url);
      setSessionLoading(false);
    };
    run();
  }, [createPortalSession]);

  if (error)
    return (
      <Card className="subscription-details">
        <Card.Body>
          <Card.Title>Subscription Details</Card.Title>
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-center align-items-center">
              <span>Could not load subscription details</span>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    );

  if (loading || sessionLoading)
    return (
      <Card className="subscription-details">
        <Card.Body>
          <Card.Title>Subscription Details</Card.Title>
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-center align-items-center">
              <Loader />
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    );
  return (
    <Card className="subscription-details">
      <Card.Header>
        <Card.Title>Subscription Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col>
            <Grid.Row>
              <Grid.Col>
                Status:{" "}
                <b>{capitalize(data.subscriptionBillingDetails.status)}</b>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className="mt-5">
                <small className="text-muted">Next payment</small>
                <div>
                  on{" "}
                  {new Date(
                    data.subscriptionBillingDetails.currentPeriodEnd
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col>
            <Grid.Row>
              <Grid.Col className="d-flex align-items-start justify-content-end">
                <CurrencyFormat className="display-4">
                  {data.subscriptionBillingDetails.amount}
                </CurrencyFormat>{" "}
                <span className="ml-1 mt-1">/ year</span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col className="d-flex align-items-end justify-content-end mt-3">
                <a
                  className="btn btn-sm btn-secondary"
                  href={sessionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Manage Subscription
                </a>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default SubscriptionBillingDetailsCard;
