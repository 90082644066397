import React, { useContext, useState } from "react";
import CustomSiteWrapper from "../common/CustomSiteWrapper";
import { RouterContextProvider } from "tabler-react";
import { NavLink, withRouter } from "react-router-dom";
import logo from "../../../logo.png";
import UserContext from "../common/UserContext";
import OrganizationSelect from "../common/OrganizationSelect";
import LoadingPage from "../../../pages/LoadingPage";
import { sentenceCase } from "change-case";
import { titleCase } from "title-case";

const DefaultLayout = ({ children, mainClassName }) => {
  const { user } = useContext(UserContext);
  const isActiveUser = user.subscription.status === "active";
  const [loading, setLoading] = useState(false);

  const accountDropdownProps = {
    avatarURL: "",
    name: user.email,
    description: titleCase(sentenceCase(user.role || "")),
    options: [
      {
        value: "Settings",
        to: "/settings",
        icon: "settings",
        RootComponent: NavLink,
        useExact: true,
      },
      { isDivider: true },
      {
        icon: "log-out",
        value: "Sign out",
        to: "/logout",
        RootComponent: NavLink,
      },
    ],
  };
  const navItems = [
    {
      value: "Reserve Items",
      to: "/reserve_items",
      icon: "home",
      LinkComponent: NavLink,
      useExact: true,
    },
    {
      value: "Financial Dashboard",
      to: "/financial_dashboard",
      icon: "file-plus",
      LinkComponent: NavLink,
      useExact: true,
    },
    {
      value: "Accelerators",
      to: "/accelerators",
      icon: "fast-forward",
      LinkComponent: NavLink,
      useExact: true,
    },
    {
      value: "Plans & Reports",
      to: "/plans_and_reports",
      icon: "bar-chart",
      LinkComponent: NavLink,
      useExact: true,
    },
    {
      value: "Training",
      to: "/training",
      icon: "film",
      LinkComponent: NavLink,
      useExact: true,
    },
  ];
  return (
    <CustomSiteWrapper
      mainClassName={mainClassName}
      headerProps={{
        href: "/",
        alt: "Pemaquid Reserves Planner",
        imageURL: logo,
        accountDropdown: accountDropdownProps,
      }}
      routerContextComponentType={withRouter(RouterContextProvider)}
      navProps={{
        itemsObjects: navItems,
        rightColumnComponent: isActiveUser ? (
          <OrganizationSelect setPageLoading={setLoading} />
        ) : (
          <></>
        ),
      }}
    >
      <div
        className="d-none"
        data-organization-logo-base64={user?.focusedOrganization?.logoBase64}
      ></div>
      {loading ? <LoadingPage /> : children}
    </CustomSiteWrapper>
  );
};

export default DefaultLayout;
