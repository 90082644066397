import React, { Fragment, useContext } from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Container, Grid, Icon, Page } from "tabler-react";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import BaselinePlan from "../../components/authenticated/plans_and_reports/BaselinePlan";
import SimulationPlan from "../../components/authenticated/plans_and_reports/SimulationPlan";
import AlternatePlan from "../../components/authenticated/plans_and_reports/AlternatePlan";
import AnnualReport from "../../components/authenticated/plans_and_reports/AnnualReport";
import UserContext from "../../components/authenticated/common/UserContext";
import Home from "../../components/authenticated/plans_and_reports/Home";
import HoverReport from "../../components/authenticated/plans_and_reports/HoverReport";
import { titleCase } from "title-case";
import { sentenceCase } from "change-case";
import PercentFunded from "../../components/authenticated/plans_and_reports/PercentFunded";
import FundingSummary from "../../components/authenticated/plans_and_reports/FundingSummary";
import TheBook from "../../components/authenticated/plans_and_reports/TheBook";
import PhotoAlbum from "../../components/authenticated/plans_and_reports/PhotoAlbum";
import SpecialRequirementReport from "../../components/authenticated/plans_and_reports/SpecialRequirementReport";
import ChangeLogReport from "../../components/authenticated/plans_and_reports/ChangeLogReport";
import ParmReport from "../../components/authenticated/plans_and_reports/ParmReport";

const PlansAndReportsPage = () => {
  let { path, url } = useRouteMatch();
  const { user } = useContext(UserContext);

  if (user.organizations.length === 0 || !user.focusedOrganization)
    return <Redirect to="/organizations/new" />;
  const categories = {
    baseline: { icon: "clipboard", color: "blue" },
    simulation: { icon: "send", color: "green" },
    alternate: { icon: "cloud-rain", color: "purple" },
  };

  const isAdmin = user.focusedOrganization?.canAdmin?.value;
  const otherCategories = {
    fundingSummary: { path: "funding_summary" },
    parmReport: { title: "PARM Report", path: "parm_report" },
    specialReqReport: { path: "special_requirements_report" },
    theBook: { path: "the_book" },
    photoAlbum: { path: "photo_album" },
  };
  if (isAdmin) otherCategories["changeLog"] = { path: "change_log" };

  return (
    <DefaultLayout mainClassName="reports">
      <Page.Content>
        <div className="my-3 my-md-5">
          <Container>
            <Grid.Row>
              <Grid.Col md={2}>
                <Page.Title className="mb-5">Plans & Reports</Page.Title>
                {Object.keys(categories).map((name) => (
                  <Fragment key={`category-${name}`}>
                    <h5 className={`color-${categories[name].color}`}>
                      <span className="mr-2 icon">
                        <Icon
                          prefix="fe"
                          name={categories[name].icon}
                          className={`color-${categories[name].color}`}
                        />
                      </span>
                      {titleCase(sentenceCase(name))}
                    </h5>
                    <ul className="list-unstyled ml-5 sidenav">
                      <li>
                        <NavLink
                          to={`${url}/reserves_plan/${name}`}
                          className="text-secondary"
                          activeClassName="active"
                        >
                          Plan
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${url}/annual_report/${name}`}
                          className="text-secondary"
                          activeClassName="active"
                        >
                          Annual Report
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${url}/percent_funded/${name}`}
                          className="text-secondary"
                          activeClassName="active"
                        >
                          Percent Funded
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={`${url}/hover_report/${name}`}
                          className="text-secondary"
                          activeClassName="active"
                        >
                          Hover Report
                        </NavLink>
                      </li>
                    </ul>
                  </Fragment>
                ))}

                <h5 className="color-cyan">
                  <span className="mr-2 icon">
                    <Icon prefix="fe" name="grid" className="color-cyan" />
                  </span>
                  Other
                </h5>
                <ul className="list-unstyled ml-5 sidenav">
                  {Object.keys(otherCategories).map((name) => (
                    <li key={`other-category-${name}`}>
                      <NavLink
                        to={`${url}/${otherCategories[name].path}`}
                        className="text-secondary"
                        activeClassName="active"
                      >
                        {otherCategories[name].title ||
                          titleCase(sentenceCase(name))}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Grid.Col>
              <Grid.Col md={10}>
                <Switch>
                  <Route exact path={`${path}/`} component={Home} />
                  <Route
                    exact
                    path={`${path}/reserves_plan/:type`}
                    render={({ match }) => {
                      if (match.params.type === "baseline")
                        return (
                          <BaselinePlan color={categories["baseline"].color} />
                        );
                      if (match.params.type === "simulation")
                        return (
                          <SimulationPlan
                            color={categories["simulation"].color}
                          />
                        );
                      if (match.params.type === "alternate")
                        return (
                          <AlternatePlan
                            color={categories["alternate"].color}
                          />
                        );
                    }}
                  />
                  <Route
                    exact
                    path={`${path}/annual_report/:type`}
                    render={({ match }) => (
                      <AnnualReport
                        reportType={match.params.type}
                        color={categories[match.params.type].color}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${path}/percent_funded/:type`}
                    render={({ match }) => (
                      <PercentFunded
                        reportType={match.params.type}
                        color={categories[match.params.type].color}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${path}/hover_report/:type`}
                    render={({ match }) => (
                      <HoverReport
                        defaultReportType={match.params.type}
                        color={categories[match.params.type].color}
                      />
                    )}
                  />
                  <Route exact path={`${path}/funding_summary`}>
                    <FundingSummary color="cyan" />
                  </Route>
                  <Route exact path={`${path}/the_book`}>
                    <TheBook color="cyan" />
                  </Route>
                  <Route exact path={`${path}/parm_report`}>
                    <ParmReport color="cyan" />
                  </Route>
                  <Route exact path={`${path}/photo_album`}>
                    <PhotoAlbum color="cyan" />
                  </Route>
                  <Route exact path={`${path}/special_requirements_report`}>
                    <SpecialRequirementReport color="cyan" />
                  </Route>
                  <Route exact path={`${path}/change_log`}>
                    <ChangeLogReport color="cyan" />
                  </Route>
                </Switch>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </Page.Content>
    </DefaultLayout>
  );
};

export default PlansAndReportsPage;
