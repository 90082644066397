import React, { Fragment, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ReserveItemsHelperMenu from "./ReserveItemsHelperMenu";

const ReserveItemsNav = ({
  enabledReserveItems,
  disabledReserveItems,
  isSaving,
  pdfFilenamePrefix,
  organizationRef,
  downloadPdfAllowed = true,
  refetch,
  canEdit,
  canExportExcel,
}) => {
  const [showIsSaving, setShowIsSaving] = useState(false);
  const timer = useRef(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    if (mountedRef.current && isSaving) {
      if (timer.current) clearTimeout(timer.current);
      setShowIsSaving(true);
      timer.current = setTimeout(
        () => mountedRef.current && setShowIsSaving(false),
        5000
      );
    }
  }, [isSaving, setShowIsSaving]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const { pathname } = useLocation();
  const splitPath = pathname.split("/");
  const showingTab =
    splitPath.length === 2 ? "enabled" : splitPath[splitPath.length - 1];
  const existingCustomReportsCategory =
    organizationRef.current?.reportsCategories?.find(
      (c) => c.categoryType === "custom"
    );

  return (
    <Fragment>
      <div className="reserve-items-nav">
        <NavLink
          to="/reserve_items"
          activeClassName="selected"
          exact
          className="content-heading d-inline-block"
        >
          Reserve Items ({enabledReserveItems.length})
        </NavLink>
        <NavLink
          to="/reserve_items/disabled"
          activeClassName="selected"
          className="content-heading d-inline-block ml-5"
        >
          Disabled ({disabledReserveItems.length})
        </NavLink>
        {showIsSaving && (
          <small className="ml-5">
            <i>{isSaving ? "Saving..." : "Change Saved"}</i>
          </small>
        )}
      </div>
      <div className="helper-menu d-inline-block">
        <ReserveItemsHelperMenu
          showingTab={showingTab}
          downloadPdfAllowed={downloadPdfAllowed}
          pdfFilenamePrefix={pdfFilenamePrefix}
          existingCustomReportsCategory={existingCustomReportsCategory}
          refetch={refetch}
          canEdit={canEdit}
          canExportExcel={canExportExcel}
        />
      </div>
    </Fragment>
  );
};

export default ReserveItemsNav;
