import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import UserContext from "./UserContext";
import { useMutation } from "@apollo/client";
import { SET_FOCUSED_ORGANIZATION } from "../../../graphql/mutations";
import Select, { components } from "react-select";
import { Button, Dimmer } from "tabler-react";
import { useHistory } from "react-router-dom";
import { abbrevMeasurementUnit } from "../../../utils/helpers";
import { titleCase } from "title-case";
import { NumericFormat } from "react-number-format";

const OrganizationSelect = ({ setPageLoading }) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [updateFocusedOrganization] = useMutation(SET_FOCUSED_ORGANIZATION, {
    refetchQueries: ["GetUser", "GetCollaborators"],
    awaitRefetchQueries: true,
  });
  const history = useHistory();
  const mountedRef = useRef(true);
  const measUnitsTitle = (measurementUnits) =>
    titleCase(abbrevMeasurementUnit(measurementUnits, { plural: true }));

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  if (user.organizations.length === 0)
    return (
      <Select
        isDisabled={true}
        className="react-select-container"
        classNamePrefix="react-select"
        options={[]}
      />
    );
  const formatOptionLabel = ({ label, numberOfUnits, measurementUnits }) =>
    numberOfUnits ? (
      <Fragment>
        <span>
          {label}{" "}
          <small className="text-muted">
            <NumericFormat
              thousandSeparator={true}
              displayType="text"
              value={numberOfUnits}
            />{" "}
            {measurementUnits}
          </small>
        </span>
      </Fragment>
    ) : (
      <Fragment>
        <span>
          <small className="text-muted">(No Organziation Selected)</small>
        </span>
      </Fragment>
    );
  const organizationOptions = user.organizations.map((o) => ({
    value: o.id,
    label: o.name,
    numberOfUnits: o.numberOfUnits,
    measurementUnits: measUnitsTitle(o.measurementUnits),
  }));

  const onChange = async (selectedOption) => {
    if (selectedOption.value === user.focusedOrganization?.id) return;
    mountedRef.current && setLoading(true);
    mountedRef.current && setPageLoading(true);
    await updateFocusedOrganization({
      variables: {
        id: selectedOption.value,
      },
    });
    mountedRef.current && setLoading(false);
    mountedRef.current && setPageLoading(false);
  };

  const addOrganizationClick = async () => {
    await onChange({ value: null });
    history.push("/organizations/new");
  };

  const MenuList = (props) => (
    <Fragment>
      <components.MenuList {...props}>
        <Dimmer active={loading} loader>
          {props.children}
        </Dimmer>
        <div className="spacer"></div>
        {user.subscription.canManageBilling.value && (
          <div className="extra-options">
            <Button
              RootComponent="a"
              onClick={addOrganizationClick}
              color="secondary"
              icon="plus"
              block={true}
              disabled={user.subscription.remainingLicenses === 0}
            >
              Add Another Organization
            </Button>
            <div className="d-flex justify-content-center mt-2">
              <small className="text-muted">
                Remaining Licenses: {user.subscription.remainingLicenses}
              </small>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <small></small>
            </div>
          </div>
        )}
      </components.MenuList>
    </Fragment>
  );
  const key = `${user.focusedOrganization?.name}-${user.focusedOrganization?.id}-${user.focusedOrganization?.measurementUnits}`;
  return (
    // using key here is a hack to get defaultValue to re-render when org name changes
    // https://jaketrent.com/post/rerender-defaultvalue-value-change
    <div key={key}>
      <Select
        defaultValue={{
          value: user.focusedOrganization?.id,
          label: user.focusedOrganization?.name,
          numberOfUnits: user.focusedOrganization?.numberOfUnits,
          measurementUnits: measUnitsTitle(
            user.focusedOrganization?.measurementUnits
          ),
        }}
        formatOptionLabel={formatOptionLabel}
        onChange={onChange}
        className="react-select-container"
        classNamePrefix="react-select"
        options={organizationOptions}
        components={{ MenuList }}
      />
    </div>
  );
};

export default OrganizationSelect;
