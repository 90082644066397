import React from "react";
import { Card } from "tabler-react";
import GlossaryContent from "./GlossaryContent";

const Glossary = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Glossary</Card.Title>
      </Card.Header>
      <Card.Body>
        <GlossaryContent />
      </Card.Body>
    </Card>
  );
};

export default Glossary;
