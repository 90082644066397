import React, { Fragment, useContext } from "react";
import { Alert, Card } from "tabler-react";
import UserContext from "../common/UserContext";
import { useQuery } from "@apollo/client";
import {
  GET_ALTERNATE_PLAN,
  GET_BASELINE_PLAN,
} from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import ReportsHelperMenu from "./ReportsHelperMenu";
import AlternatePlanPdf from "./AlternatePlanPdf";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import AlternatePlanTable from "./AlternatePlanTable";

const AlternatePlan = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { loading, error, data } = useQuery(GET_ALTERNATE_PLAN, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });
  const {
    loading: baselineLoading,
    error: baselineError,
    data: baselineData,
  } = useQuery(GET_BASELINE_PLAN, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  if (loading || baselineLoading) return <LoadingPage />;
  if (error || baselineError)
    return <ErrorRedirect error={error || baselineError} />;

  const missingPrereqs = data.alternatePlan.missingPrerequisites;
  if (missingPrereqs.length)
    return (
      <Card statusColor={color}>
        <Card.Header>
          <Card.Title>Alternate Plan</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Alternate Plan Report, please complete the
              following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <Fragment>
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix="alternate" />
      </div>
      <AlternatePlanTable
        color={color}
        organization={organization}
        plan={data.alternatePlan.rows}
        baselinePlan={baselineData.baselinePlan.rows}
      />
      <div className="d-none">
        <AlternatePlanPdf
          organization={organization}
          alternatePlan={data.alternatePlan.rows}
          baselinePlan={baselineData.baselinePlan.rows}
        />
      </div>
    </Fragment>
  );
};

export default AlternatePlan;
