import React, { useContext } from "react";
import { Card, Grid, Loader, Table } from "tabler-react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ALTERNATE_PLAN_SETTING } from "../../../graphql/mutations";
import { GET_ALTERNATE_PLAN_SETTING } from "../../../graphql/queries";
import { Controller, useForm } from "react-hook-form";
import AlternatePlanSettingFormUtils from "../../../utils/AlternatePlanSettingFormUtils";
import ErrorRedirect from "../../ErrorRedirect";
import UserContext from "../common/UserContext";
import useAutoSave from "../../../hooks/useAutoSave";
import AutoSaveStatus from "../common/AutoSaveStatus";
import { titleCase } from "title-case";
import {
  abbrevMeasurementUnit,
  formatDecimal,
  formatDecimalForUnits,
} from "../../../utils/helpers";
import { NumericFormat } from "react-number-format";

const AlternatePlanSettingsForm = ({ canEdit }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const {
    register,
    getValues,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const { data, loading, error } = useQuery(GET_ALTERNATE_PLAN_SETTING, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  const [updateAlternatePlanSetting] = useMutation(
    UPDATE_ALTERNATE_PLAN_SETTING,
    {
      refetchQueries: ["GetAlternatePlanSetting"],
      awaitRefetchQueries: true,
    }
  );

  const missingAlternatePlanFields = (alternatePlanSetting) => {
    if (!alternatePlanSetting) return false;
    const { beginningYear, beginningBalance, term, targetAmount } =
      alternatePlanSetting;
    return [beginningYear, beginningBalance, term, targetAmount].some(
      (el) => el === null || el === undefined || el.length === 0
    );
  };

  const [onSubmit, lastSubmittedAt, submitting] = useAutoSave(async () => {
    let setting = getValues();
    let { data } = await updateAlternatePlanSetting({
      variables: {
        attributes: AlternatePlanSettingFormUtils.outgoingSchema.validateSync(
          setting,
          { stripUnknown: true }
        ),
      },
    });
    const { alternatePlanSetting, errors } = data.updateAlternatePlanSetting;

    if (missingAlternatePlanFields(alternatePlanSetting))
      setError("_refreshCalcs", {
        type: "custom",
        message:
          "Please complete all fields in the Alternate Settings and Baseline Settings in order to recalculate the fields below",
      });

    errors.forEach((e) => {
      if (e.path?.[0]) setError(e.path[0], { message: e.message });
      else setError("_refreshCalcs", { type: "custom", message: e.message });
    });
  });

  if (error) return <ErrorRedirect error={error} />;
  if (loading)
    return (
      <Card className="alternate-plan-settings">
        <Card.Header>
          <Card.Title>Alternate Settings</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Loader />
        </Card.Body>
      </Card>
    );

  return (
    <form className="alternate-plan-settings">
      <input type="hidden" name="_refreshCalcs" ref={register} />
      <Card>
        <Card.Header>
          <Card.Title>Alternate Settings</Card.Title>
          <Card.Options>
            <AutoSaveStatus
              submitting={submitting}
              lastSubmittedAt={lastSubmittedAt}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Table className="card-table table-vcenter">
                <Table.Body>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="alternate-beginning-year">
                        Beginning Year
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <div
                        id="alternate-beginning-year"
                        className="read-only p-2 text-nowrap text-right"
                      >
                        {data?.alternatePlanSetting?.beginningYear ? (
                          data?.alternatePlanSetting?.beginningYear
                        ) : (
                          <span className="text-muted">Year</span>
                        )}
                      </div>
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="alternate-beginning-balance">
                        Beginning Balance
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <NumericFormat
                        className="read-only p-2 text-nowrap text-right"
                        allowNegative={false}
                        thousandSeparator={true}
                        decimalScale="2"
                        displayType="text"
                        prefix="$"
                        value={data?.alternatePlanSetting?.beginningBalance}
                      />
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col className="pr-1 py-1">
                      <label htmlFor="alternate-term">Term (Years)</label>
                    </Table.Col>
                    <Table.Col>
                      <input
                        id="alternate-term"
                        name="term"
                        placeholder="Years"
                        defaultValue={data?.alternatePlanSetting?.term}
                        type="number"
                        ref={register}
                        className={`form-control text-right pr-2 ${
                          errors.term && "is-invalid"
                        }`}
                        onChange={handleSubmit(onSubmit)}
                        readOnly={!canEdit}
                      />
                      <div className="invalid-feedback text-right">
                        {errors.term?.message}
                      </div>
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="alternate-target-amount">
                        Target Amount
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <Controller
                        name="targetAmount"
                        control={control}
                        defaultValue={data?.alternatePlanSetting?.targetAmount}
                        render={({ onChange, value }) => (
                          <NumericFormat
                            id="alternate-target-amount"
                            name="targetAmount"
                            placeholder="Amount"
                            className="form-control text-right pr-2"
                            readOnly={!canEdit}
                            allowNegative={false}
                            thousandSeparator={true}
                            valueIsNumericString={true}
                            defaultValue={data?.alternatePlanSetting?.term}
                            decimalScale="2"
                            onValueChange={(v, { event }) => {
                              onChange(v.value);
                              if (event) handleSubmit(onSubmit)(event);
                            }}
                            prefix="$"
                            value={value}
                          />
                        )}
                      />
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col colSpan={2}>
                      <Table>
                        <Table.Body>
                          <Table.Row>
                            <Table.Col colSpan={2} className="text-left">
                              <small className="text-danger refresh-error">
                                {errors._refreshCalcs?.message}
                              </small>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col
                              className={
                                errors._refreshCalcs ? "border-top-0" : ""
                              }
                            />
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              <label htmlFor="alternate-proj-expenses-in-term">
                                Projected Expenses in Term
                              </label>
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <NumericFormat
                                id="alternate-proj-expenses-in-term"
                                thousandSeparator={true}
                                className="read-only p-2 text-nowrap text-right"
                                displayType="text"
                                prefix="$"
                                defaultValue="0"
                                valueIsNumericString={true}
                                value={formatDecimal(
                                  data?.alternatePlanSetting
                                    ?.projectedExpensesInTerm
                                )}
                              />
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              <label htmlFor="alternate-total-funds-required">
                                Total Funds Required
                              </label>
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <NumericFormat
                                id="alternate-total-funds-required"
                                thousandSeparator={true}
                                className="read-only p-2 text-nowrap text-right"
                                displayType="text"
                                prefix="$"
                                defaultValue="0"
                                value={formatDecimal(
                                  data?.alternatePlanSetting?.totalFundsRequired
                                )}
                              />
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              <label htmlFor="alternate-annual-amount-required">
                                Annual Amount Required
                              </label>
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <NumericFormat
                                id="alternate-annual-amount-required"
                                thousandSeparator={true}
                                className="read-only p-2 text-nowrap text-right"
                                displayType="text"
                                prefix="$"
                                defaultValue="0"
                                value={formatDecimal(
                                  data?.alternatePlanSetting
                                    ?.annualAmountRequired
                                )}
                              />
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              <label htmlFor="alternate-monthly-amount-required">
                                Monthly Amount Required
                              </label>
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <NumericFormat
                                id="alternate-monthly-amount-required"
                                thousandSeparator={true}
                                className="read-only p-2 text-nowrap text-right"
                                displayType="text"
                                prefix="$"
                                defaultValue="0"
                                decimalScale={2}
                                value={
                                  data?.alternatePlanSetting
                                    ?.monthlyAmountRequired
                                }
                              />
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              <label htmlFor="alternate-monthly-per-unit">
                                Monthly Per{" "}
                                {titleCase(
                                  abbrevMeasurementUnit(
                                    organization.measurementUnits
                                  )
                                )}
                              </label>
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <NumericFormat
                                id="alternate-monthly-per-unit"
                                thousandSeparator={true}
                                className="read-only p-2 text-nowrap text-right"
                                displayType="text"
                                prefix="$"
                                defaultValue="0"
                                value={formatDecimalForUnits(
                                  organization.measurementUnits,
                                  data?.alternatePlanSetting?.monthlyPerUnit
                                )}
                              />
                            </Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </form>
  );
};

export default AlternatePlanSettingsForm;
