import React from "react";
import { Loader } from "tabler-react";
import DefaultCenteredLayout from "../layouts/DefaultCenteredLayout";

const Loading = () => {
  return (
    <DefaultCenteredLayout>
      <Loader />
    </DefaultCenteredLayout>
  );
};

export default Loading;
