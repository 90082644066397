import React from "react";
import logo from "../logo.png";

const InfoLayout = ({ children }) => {
  return (
    <div className="page">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="text-center mb-6">
                <a href={process.env.REACT_APP_STATIC_SITE_URL}>
                  <img src={logo} className="h-9" alt="logo" />
                </a>
                <div className="h3 mt-6">
                  <a
                    className="text-dark"
                    href={process.env.REACT_APP_STATIC_SITE_URL}
                  >
                    Pemaquid Reserves Planner
                  </a>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoLayout;
