import Tracker from "@openreplay/tracker/cjs"; //need to import the cjs and not ts modules for jest to work
import trackerAssist from "@openreplay/tracker-assist/cjs";

// If OpenReplay API key is defined, initialize the tracker
// and it will be used throughout the app. Otherwise, initialize a dummy
// object that allows for chainable methods that all result in a noop

const noop = new Proxy(
  {},
  {
    get(target, prop, receiver) {
      const fn = () => receiver;
      Object.setPrototypeOf(fn, receiver);
      return fn;
    },
  }
);

let tracker = noop;
if (process.env.REACT_APP_OPEN_REPLAY_TOKEN) {
  tracker = new Tracker({
    projectKey: process.env.REACT_APP_OPEN_REPLAY_TOKEN,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: false,
  });
  tracker.use(trackerAssist({}));
}

export const openReplay = tracker;
