import React, { useContext } from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Grid, Page } from "tabler-react";
import RangeAndRatesCard from "../../components/authenticated/financial_dashboard/RangeAndRatesCard";
import { Redirect } from "react-router-dom";
import UserContext from "../../components/authenticated/common/UserContext";
import AlternatePlanSettingsForm from "../../components/authenticated/financial_dashboard/AlternatePlanSettingsForm";
import FinancialDashboardHelperMenu from "../../components/authenticated/financial_dashboard/FinancialDashboardHelperMenu";
import FinancialDashboardPdf from "../../components/authenticated/financial_dashboard/FinancialDashboardPdf";
import { useQuery } from "@apollo/client";
import {
  GET_ALTERNATE_PLAN_SETTING,
  GET_BASELINE_PLAN_SETTING,
  GET_RESERVE_ITEMS,
  GET_SIMULATION_PLAN_SETTING,
} from "../../graphql/queries";
import { toast } from "react-toastify";
import LoadingPage from "../LoadingPage";
import BaselinePlanSettingsForm from "../../components/authenticated/financial_dashboard/BaselinePlanSettingsForm";
import SimulationPlanSettingsForm from "../../components/authenticated/financial_dashboard/SimulationPlanSettingsForm";

const FinancialDashboardPage = () => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const canEdit = organization?.canEdit?.value;

  const { loading: resItemsLoading, data: resItemsData } = useQuery(
    GET_RESERVE_ITEMS,
    { fetchPolicy: "no-cache" }
  );

  // The following data is for PDF only
  const { data: bData, loading: bLoading } = useQuery(
    GET_BASELINE_PLAN_SETTING,
    {
      skip: !organization,
      fetchPolicy: "no-cache",
    }
  );

  const { data: sData, loading: sLoading } = useQuery(
    GET_SIMULATION_PLAN_SETTING,
    {
      skip: !organization,
      fetchPolicy: "no-cache",
    }
  );

  const { data: aData, loading: aLoading } = useQuery(
    GET_ALTERNATE_PLAN_SETTING,
    {
      skip: !organization,
      fetchPolicy: "no-cache",
    }
  );

  if (resItemsLoading) return <LoadingPage />;

  if (user.organizations.length === 0 || !organization)
    return <Redirect to="/organizations/new" />;

  if (resItemsData.reserveItems.filter((e) => e.enabled).length < 5) {
    toast.error(
      "Please enable at least 5 Reserve Items to view the Financial Dashboard"
    );
    return <Redirect to="/reserve_items" />;
  }

  return (
    <DefaultLayout mainClassName="financial-dashboard">
      <Page.Content>
        <Page.Header
          title="Financial Dashboard"
          options={
            <div className="helper-menu m-0">
              <FinancialDashboardHelperMenu />
            </div>
          }
        />
        <Grid.Row>
          <Grid.Col width={12} lg={4}>
            <BaselinePlanSettingsForm canEdit={canEdit} />
          </Grid.Col>
          <Grid.Col width={12} lg={4}>
            <SimulationPlanSettingsForm canEdit={canEdit} />
          </Grid.Col>
          <Grid.Col width={12} lg={4}>
            <AlternatePlanSettingsForm canEdit={canEdit} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12} lg={6}>
            <RangeAndRatesCard
              canEdit={canEdit}
              attr="interestEarnedOnReserves"
              rateTitle="Interest Rate"
            />
          </Grid.Col>
          <Grid.Col width={12} lg={6}>
            <RangeAndRatesCard
              canEdit={canEdit}
              attr="inflationRatesOnItemCosts"
              title="Future Inflation Rates On Item Costs"
              rateTitle="Inflation Rate"
              footerText="Inflation Rate = 3.22% for all years before Future Inflation Forecast"
            />
          </Grid.Col>
        </Grid.Row>
        <div className="d-none">
          {aLoading || bLoading || sLoading ? (
            <></>
          ) : (
            <FinancialDashboardPdf
              baselinePlanSetting={bData.baselinePlanSetting}
              simulationPlanSetting={sData.simulationPlanSetting}
              alternatePlanSetting={aData.alternatePlanSetting}
              organization={organization}
            />
          )}
        </div>
      </Page.Content>
    </DefaultLayout>
  );
};

export default FinancialDashboardPage;
