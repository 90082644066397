import React, { useContext, useEffect } from "react";
import UserContext from "../common/UserContext";
import { Card, Table } from "tabler-react";

import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_BASELINE_PLAN_SETTING,
  GET_SPECIAL_REQUIREMENT_REPORT_SETTING,
} from "../../../graphql/queries";
import { UPDATE_SPECIAL_REQUIREMENT_REPORT_SETTING } from "../../../graphql/mutations";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";
import AccountingFormat from "../common/AccountingFormat";
import { Controller, useForm } from "react-hook-form";
import useAutoSave from "../../../hooks/useAutoSave";
import AutoSaveStatus from "../common/AutoSaveStatus";
import { NumericFormat } from "react-number-format";

const SpecialRequirementReportForm = ({
  percentIncrease,
  webOnly,
  notEditable,
}) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { handleSubmit, getValues, control, reset } = useForm({
    defaultValues: {
      assessmentLimit: "",
    },
  });

  const { data, loading, error } = useQuery(
    GET_SPECIAL_REQUIREMENT_REPORT_SETTING,
    {
      skip: !organization,
      fetchPolicy: "no-cache",
    }
  );
  const {
    data: bData,
    loading: bLoading,
    error: bError,
  } = useQuery(GET_BASELINE_PLAN_SETTING, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  const [updateSpecialRequirementReportSetting] = useMutation(
    UPDATE_SPECIAL_REQUIREMENT_REPORT_SETTING,
    {
      refetchQueries: ["GetSpecialRequirementReport"],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    if (data?.specialRequirementReportSetting)
      reset({
        assessmentLimit: data.specialRequirementReportSetting.assessmentLimit,
      });
  }, [data, reset]);

  const [onSubmit, lastSubmittedAt, submitting] = useAutoSave(async () => {
    let { assessmentLimit } = getValues();
    assessmentLimit = parseFloat(assessmentLimit);
    await updateSpecialRequirementReportSetting({
      variables: { attributes: { assessmentLimit, percentIncrease } },
    });
  });

  if (loading || bLoading) return <LoadingPage />;
  if (error || bError) return <ErrorRedirect error={error || bError} />;

  return (
    <form className="special-requirements-report-form">
      <Card className="border-0 mb-0">
        <Card.Header className="border-0 align-items-center">
          <Card.Title>Settings</Card.Title>
          <Card.Options>
            {webOnly && !notEditable && (
              <AutoSaveStatus
                submitting={submitting}
                lastSubmittedAt={lastSubmittedAt}
              />
            )}
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Table className="card-table table-vcenter">
            <Table.Body>
              <Table.Row>
                <Table.Col>Beginning Balance</Table.Col>
                <Table.Col>
                  <AccountingFormat>
                    {bData.baselinePlanSetting.beginningBalance}
                  </AccountingFormat>
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>Annual Contribution</Table.Col>
                <Table.Col>
                  <AccountingFormat>
                    {
                      bData.baselinePlanSetting
                        .currentTotalAnnualReservesFunding
                    }
                  </AccountingFormat>
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  {titleCase(
                    abbrevMeasurementUnit(organization.measurementUnits, {
                      plural: true,
                    })
                  )}
                </Table.Col>
                <Table.Col>{organization.numberOfUnits}</Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>Assessment Limit</Table.Col>
                <Table.Col>
                  {webOnly && !notEditable ? (
                    <Controller
                      control={control}
                      name="assessmentLimit"
                      render={({ onChange, value }) => (
                        <NumericFormat
                          id="assessment-limit-input"
                          placeholder="Assessment Limit"
                          className="form-control"
                          readOnly={!organization?.canEdit?.value}
                          allowNegative={false}
                          thousandSeparator={true}
                          valueIsNumericString={true}
                          decimalScale="2"
                          name="assessmentLimit"
                          onValueChange={(v, { event }) => {
                            onChange(v.value);
                            if (event) handleSubmit(onSubmit)(event);
                          }}
                          prefix="$"
                          value={value}
                        />
                      )}
                    />
                  ) : (
                    <AccountingFormat>
                      {data?.specialRequirementReportSetting?.assessmentLimit}
                    </AccountingFormat>
                  )}
                </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </form>
  );
};

export default SpecialRequirementReportForm;
