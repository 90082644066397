import React from "react";
import { Card } from "tabler-react";
import InfoLayout from "../components/InfoLayout";

const PrivacyPolicyPage = () => {
  return (
    <InfoLayout>
      <Card>
        <Card.Body>
          <h1 className="text-center">PRIVACY POLICY</h1>
          <p>
            <u>EFFECTIVE: February 1, 2020</u>
          </p>
          <p>
            Hafner Technology Group LLC (<i>hereafter</i>, "HTG") welcomes you
            to the Pemaquid™ Reserves planning service, an{" "}
            <nobr>easy-to-use</nobr> <nobr>software-based</nobr> service
            designed to enable your organization to plan reserves in
            anticipation of capital expenses likely to be incurred (
            <i>hereafter</i>, "the Service").
          </p>
          <p>
            Our Privacy Policy (<i>hereafter</i>, "Policy") describes how we
            collect, use, store, protect and share the information we collect
            from your use of the Service. See Terms of Use following the Privacy
            Policy.
          </p>
          <p>
            By using the Service, you understand and agree that we are providing
            a platform designed to assist you in planning to establish capital
            expense reserves and, in the course of doing so, you will upload
            your organization’s relevant information (<i>hereafter</i>, "User
            Content") to the website through which you access the Service.
          </p>
          <p className="text-center">
            IF YOU DO NOT AGREE TO THE TERMS SET FORTH IN THIS POLICY, DO NOT
            REGISTER TO USE THE SERVICE
          </p>
          <p>
            <ol>
              <li>
                <p>
                  <u>User Registration and Login</u>
                </p>
                <p>
                  When you sign up for the Service, you will connect to the
                  Service by using a username and password on HTG’s website. It
                  is your responsibility to protect your username and password.
                  By signing up for the Service, you authorize HTG to collect
                  your authentication information, such as your username and
                  encrypted access credentials.
                </p>
              </li>
              <li>
                <p>
                  <u>Information Collected by HTG</u>
                </p>
                <p>
                  We collect the following information:
                  <ul>
                    <li> User Content that you disclose to the Service;</li>
                    <li>
                      Technical data, which may include URL information, cookie
                      data, metadata, your IP address, the types of devices you
                      are using to access or connect to the Service, unique
                      device ID, device attributes, network connection type
                      (e.g., <nobr>Wi-Fi,</nobr> 3G, LTE) and provider, network
                      and device performance, browser type, language,
                      information enabling digital rights management, operating
                      system, and Pemaquid™ application version; and
                    </li>
                    <li>Communications between you and HTG.</li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  <u>Credit Card</u>
                </p>
                <p>
                  All credit card and electronic transactions are processed
                  through a third party. The <nobr>third-party</nobr> processor
                  of all credit cards and electronic transactions may change
                  from time to time without notice to you. No credit card or
                  electronic transaction information is collected and saved by
                  HTG.
                </p>
              </li>
              <li>
                <p>
                  <u>Use of Information Collected</u>
                </p>
                <p>
                  The information we collect better assists us in providing you
                  the best experience possible when using the Service. As a
                  result, we may use the information we receive to:
                  <ul>
                    <li>
                      provide, improve, test, update, modify and monitor the
                      effectiveness of the Service;
                    </li>
                    <li>identify and resolve problems with the Service;</li>
                    <li>
                      provide personalized content through the Service, which
                      includes marketing and advertising, by us and our{" "}
                      <nobr>third-party</nobr> advertisers; and
                    </li>
                    <li>
                      obtain analytical information related to your use of the
                      Service.
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                <p>
                  <u>Storage and Retention of Information</u>
                </p>
                <p>
                  HTG uses Affiliates (<i>as defined herein</i>) and Service
                  Providers (<i>as defined herein</i>), located in the United
                  States, to store and process the information you provide
                  through the Service. By registering to use the Service, you
                  agree to allowing HTG to store and process your information
                  with its Affiliates and Services Providers and understand that
                  the information may be stored anywhere in the United States.
                </p>
                <p>
                  While HTG, its Affiliates and Service Providers will use
                  commercially reasonable safeguards to protect your
                  information, we cannot guarantee the security of the
                  information we obtain through your use of the Service nor can
                  we guarantee that your information that is obtained from your
                  use of the Service may not be altered or destroyed.
                </p>
                <p>
                  Please note that we retain all information that you provide
                  for a commercially reasonable period of time for backup,
                  archival, and/or audit purposes. We may extend the time we
                  retain any information from you should there be a legal
                  dispute, violation of the Terms of Use and/or legal request
                  from a governmental institution. See Section 6 for further
                  details about the sharing of your information with third
                  parties. If you would like us to delete User Content that you
                  have provided via the Service, please contact us at
                  info@pemaquidreserves.com and we will respond in a reasonable
                  time. Please note that some or all of the User Content may be
                  required in order for the Service to function properly.
                </p>
              </li>
              <li>
                <p>
                  <u>Sharing of Information to Third Parties</u>
                </p>
                <p>
                  The Service is designed for you to share User Content
                  publicly. As a result, we are not responsible for any User
                  Content, including sensitive, confidential and/or personal
                  content that was shared (whether intentional or unintentional)
                  publicly.
                </p>
                <p>
                  That said, we will never sell or rent any of the information
                  we obtain from your use of the Service without your consent,
                  except as noted in this Policy.
                </p>
                <p>
                  We may share User Content and your information (including but
                  not limited to, information from cookies, log files, device
                  identifiers, location data, and usage data) with businesses
                  that have a legal business relationship with HTG (
                  <i>hereafter</i>, “Affiliates”). Affiliates may use this
                  information to help provide, understand, and improve the
                  Service and the Affiliates' own services.
                </p>
                <p>
                  We may share your information in response to a legal request
                  (like a search warrant, court order or subpoena) if we have a
                  good faith belief that the law requires us to do so. In
                  addition, we may share your information in the event we have a
                  good faith belief to (1) detect, prevent and address fraud and
                  other illegal activity; (2) protect ourselves, your
                  organization and others; or (3) prevent death or imminent
                  bodily harm.
                </p>
                <p>
                  We may share information in the event of a sale (including a
                  merger) of the Service and/or of HTG.
                </p>
              </li>
              <li>
                <p>
                  <u>Children’s Privacy</u>
                </p>
                <p>
                  We do not use the Service to knowingly solicit data from or
                  market to children under the age of 18. If a parent or
                  guardian becomes aware that his or her child has provided us
                  with information without their consent, he or she should
                  contact us at{" "}
                  <a href="mailto:info@pemaquidreserves.com.">
                    info@pemaquidreserves.com.
                  </a>
                  the phone number on our web page. We will delete such
                  information from our files within a reasonable period of time.
                </p>
              </li>
              <li>
                <p>
                  <u>Changes to Policy</u>
                </p>
                <p>
                  This Policy may be updated from time to time for any reason.
                  We will notify you of any changes to this Policy by posting
                  the new Privacy Policy here. You are advised to consult this
                  Policy regularly for any changes, as continued use is deemed
                  approval of all changes.
                </p>
              </li>
              <li>
                <p>
                  <u>Contact Us</u>
                </p>
                <p>
                  If you have any questions regarding this Policy while using
                  the Service or having any questions, please contact us at{" "}
                  <a href="mailto:info@pemaquidreserves.com">
                    info@pemaquidreserves.com
                  </a>{" "}
                  or the phone number on our web page.
                </p>
              </li>
            </ol>
          </p>
        </Card.Body>
      </Card>
    </InfoLayout>
  );
};
export default PrivacyPolicyPage;
