import React, { useContext } from "react";
import ReportsHelperMenu from "./ReportsHelperMenu";
import { useQuery } from "@apollo/client";
import { GET_PERCENT_FUNDED } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import UserContext from "../common/UserContext";
import PercentFundedPdf from "./PercentFundedPdf";
import PercentFundedTable from "./PercentFundedTable";
import { Alert, Card } from "tabler-react";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import capitalize from "capitalize";

const PercentFunded = ({ reportType, color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { loading, error, data } = useQuery(GET_PERCENT_FUNDED, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      reportType: reportType,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;

  const missingPrereqs = data.percentFunded.missingPrerequisites;
  if (missingPrereqs.length)
    return (
      <Card statusColor={color} className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>{capitalize(reportType)} Percent Funded</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Percent Funded, please complete the following
              steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <div className="percent-funded">
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix={`${reportType}-percent-funded`} />
      </div>
      <PercentFundedTable
        organization={organization}
        percentFunded={data.percentFunded.rows}
        color={color}
        reportType={reportType}
      />

      <div className="d-none">
        <PercentFundedPdf
          organization={organization}
          percentFunded={data.percentFunded.rows}
          color={color}
          reportType={reportType}
        />
      </div>
    </div>
  );
};

export default PercentFunded;
