// Taken from https://divyanshu013.dev/blog/react-debounce-throttle-hooks/
import { useCallback, useEffect, useRef } from "react";
import debounce from "debounce-promise";

const useDebounce = (callback, delay) => {
  // eslint-disable-next-line
  const memoizedCallback = useCallback(callback, []);
  const debouncedFn = useRef(debounce(memoizedCallback, delay));

  useEffect(() => {
    debouncedFn.current = debounce(memoizedCallback, delay);
  }, [memoizedCallback, debouncedFn, delay]);

  return debouncedFn.current;
};

export default useDebounce;
