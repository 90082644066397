import React from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Card, Grid, Page } from "tabler-react";

const ContactUsPage = () => {
  return (
    <DefaultLayout mainClassName="contact-us">
      <Page.Content>
        <Page.Header title="Contact Us" />
        <Grid.Row className="d-flex justify-content-center align-items-center">
          <Grid.Col width={12} lg={6}>
            <Card>
              <Card.Body className="text-center">
                <h3>Pemaquid Reserves Planner</h3>
                <address>PO Box 892 Kennebunk, ME 04043</address>
                <p>
                  Office: 1-207-232-8859
                  <br />
                  Fax: 1-207-957-4453
                </p>
                <p>
                  <a href="mailto:info@pemaquidreserves.com">
                    info@pemaquidreserves.com
                  </a>
                </p>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </DefaultLayout>
  );
};

export default ContactUsPage;
