import { Observable } from "@apollo/client/utilities";

const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });
};

export default promiseToObservable;
