import React, { useContext } from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Alert, Grid, Page } from "tabler-react";
import { NavLink, Redirect } from "react-router-dom";
import OrganizationDetailsCard from "../../components/authenticated/settings/OrganizationDetailsCard";
import UserContext from "../../components/authenticated/common/UserContext";
import ResetPasswordCard from "../../components/authenticated/settings/ResetPasswordCard";
import AddCollaboratorsCard from "../../components/authenticated/settings/AddCollaboratorsCard";
import SubscriptionBillingDetailsCard from "../../components/authenticated/settings/SubscriptionBillingDetailsCard";

const SettingsPage = () => {
  const { user } = useContext(UserContext);

  const pendingSubscription = user.subscription.status === "pending";
  const activeSubscription = user.subscription.status === "active";
  const canInviteUsers =
    user.subscription.canInviteUsers.value ||
    user.focusedOrganization?.canInviteUsers?.value;

  if (pendingSubscription)
    return (
      <DefaultLayout>
        <Page.Content title="Settings">
          <Alert type="primary">
            Please{" "}
            <NavLink className="text-dark" to="/activate">
              <strong>subscribe</strong>
            </NavLink>{" "}
            to enable your account.
          </Alert>
          <Grid.Row>
            <Grid.Col md={6}>
              <ResetPasswordCard />
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </DefaultLayout>
    );

  if (activeSubscription && user.organizations.length === 0)
    return <Redirect to="/organizations/new" />;

  return (
    <DefaultLayout mainClassName="settings">
      <Page.Content title="Settings">
        <Grid.Row>
          {activeSubscription && user.focusedOrganization?.canEdit?.value && (
            <Grid.Col md={6}>
              <OrganizationDetailsCard />
            </Grid.Col>
          )}
          {user.subscription.canManageBilling.value && (
            <Grid.Col>
              <SubscriptionBillingDetailsCard />
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row>
          {activeSubscription && canInviteUsers && (
            <Grid.Col md={6}>
              <AddCollaboratorsCard user={user} />
            </Grid.Col>
          )}
          <Grid.Col md={6}>
            <ResetPasswordCard />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row></Grid.Row>
      </Page.Content>
    </DefaultLayout>
  );
};

export default SettingsPage;
