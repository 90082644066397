import React, { Fragment } from "react";
import { Grid } from "tabler-react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import CurrencyFormat from "../common/CurrencyFormat"; // optional

const HoverDataPopover = ({ year, hoverData, children }) => {
  if (hoverData) {
    hoverData = hoverData.sort((a, b) => a.item.localeCompare(b.item));
    const tooltip = (
      <Grid.Row>
        <Grid.Col>
          {hoverData.map((item, i) => (
            <div key={`${year}-hover-data-${item.item}-${i}`}>
              <span>{item.item}&#58;&nbsp;</span>
              <span>
                <CurrencyFormat>{parseInt(item.cost)}</CurrencyFormat>
              </span>
            </div>
          ))}
        </Grid.Col>
      </Grid.Row>
    );
    return (
      <div className="cursor-pointer breakdown">
        <Tippy content={tooltip} trigger="click">
          {children}
        </Tippy>
      </div>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default HoverDataPopover;
