import React from "react";
import { Link } from "react-router-dom";

const MissingPrerequisitesList = ({ missingPrerequisites }) => {
  return (
    <ul className="list-unstyled prerequisites">
      {missingPrerequisites.includes("reserve_items") && (
        <li>
          Enable at least 5 Reserve Items on the&nbsp;
          <Link to="/reserve_items" className="text-dark">
            <strong>Reserve Items</strong>
          </Link>
          &nbsp;Page. Be sure to fill out all fields for every enabled reserve
          item.
        </li>
      )}
      {missingPrerequisites.includes("baseline_settings") && (
        <li>
          Complete the <i>Baseline Settings</i> on the&nbsp;
          <Link to="/financial_dashboard" className="text-dark">
            <strong>Financial Dashboard</strong>
          </Link>
          &nbsp;Page
        </li>
      )}
      {missingPrerequisites.includes("simulation_settings") && (
        <li>
          Complete the <i>Simulation Settings</i> on the&nbsp;
          <Link to="/financial_dashboard" className="text-dark">
            <strong>Financial Dashboard</strong>
          </Link>
          &nbsp;Page
        </li>
      )}
      {missingPrerequisites.includes("alternate_settings") && (
        <li>
          Complete the <i>Alternate Settings</i> on the&nbsp;
          <Link to="/financial_dashboard" className="text-dark">
            <strong>Financial Dashboard</strong>
          </Link>
          &nbsp;Page
        </li>
      )}
      {missingPrerequisites.includes("interest_earned_on_reserves") && (
        <li>
          Complete <i>Interest Earned on Reserves</i> on the&nbsp;
          <Link to="/financial_dashboard" className="text-dark">
            <strong>Financial Dashboard</strong>
          </Link>
          &nbsp;Page
        </li>
      )}
      {missingPrerequisites.includes("inflation_rates_on_item_costs") && (
        <li>
          Complete <i>Inflation Rates on Item Costs</i> on the&nbsp;
          <Link to="/financial_dashboard" className="text-dark">
            <strong>Financial Dashboard</strong>
          </Link>
          &nbsp;Page
        </li>
      )}
    </ul>
  );
};

export default MissingPrerequisitesList;
