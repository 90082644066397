import React from "react";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import { Card, Container, Grid, Page } from "tabler-react";
import youtube from "../../youtube.png";

const TrainingPage = () => {
  const items = [
    {
      title: "Training 1",
      subtitle: "Kickoff",
      link: "https://youtu.be/dhViAyCZ3Gk",
    },
    {
      title: "Training 2",
      subtitle: "Completing the Baseline",
      link: "https://youtu.be/1v9sohIj9Pg",
    },
    {
      title: "Training 3 ",
      subtitle: "Simulation, Alternate, and Finalized Plan",
      link: "https://youtu.be/MeCT76u5JTM",
    },
  ];
  return (
    <DefaultLayout mainClassName="training">
      <Page.Content>
        <Page.Header title="Refresher Training" />
        <Container>
          <Grid.Row className="row-cards">
            {items.map((item, key) => (
              <Grid.Col sm={6} lg={4} key={key}>
                <a
                  href={item.link}
                  className="text-decoration-none text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card>
                    <Card.Body className="cursor-pointer text-center">
                      <div className="video d-flex align-items-center justify-content-center text-muted mb-4">
                        <div className="play cursor-pointer p-9 rounded">
                          <img src={youtube} alt="play" height={60} />
                        </div>
                      </div>
                      <h2 className="mb-1">{item.title}</h2>
                      <h5 className="text-muted">{item.subtitle}</h5>
                    </Card.Body>
                  </Card>
                </a>
              </Grid.Col>
            ))}
          </Grid.Row>
        </Container>
      </Page.Content>
    </DefaultLayout>
  );
};

export default TrainingPage;
