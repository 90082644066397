import React, { Fragment } from "react";
import BaselinePlanPdf from "./BaselinePlanPdf";
import SimulationPlanPdf from "./SimulationPlanPdf";
import AnnualReportPdf from "./AnnualReportPdf";
import PercentFundedPdf from "./PercentFundedPdf";
import GlossaryPdf from "../reserve_items/GlossaryPdf";
import AlternatePlanPdf from "./AlternatePlanPdf";
import ReserveItemsPdf from "../reserve_items/ReserveItemsPdf";
import FinancialDashboardPdf from "../financial_dashboard/FinancialDashboardPdf";
import FundingSummaryPdf from "./FundingSummaryPdf";
import TheBookToc from "./TheBookToc";

const TheBookPdf = ({ theBook, organization, reserveItems, generatedAt }) => {
  return (
    <Fragment>
      <TheBookToc organization={organization} generatedAt={generatedAt} />

      <ReserveItemsPdf
        organization={organization}
        reserveItems={reserveItems}
        showingTab="enabled"
        generatedAt={generatedAt}
      />
      <FinancialDashboardPdf
        baselinePlanSetting={theBook.baselinePlanSetting}
        simulationPlanSetting={theBook.simulationPlanSetting}
        alternatePlanSetting={theBook.alternatePlanSetting}
        organization={organization}
        generatedAt={generatedAt}
      />
      <FundingSummaryPdf
        organization={organization}
        fundingSummary={theBook.fundingSummary}
        generatedAt={generatedAt}
      />
      <BaselinePlanPdf
        organization={organization}
        plan={theBook.baselinePlan.rows}
        generatedAt={generatedAt}
      />
      <AnnualReportPdf
        reportType="baseline"
        organization={organization}
        annualReport={theBook.baselineAnnualReport.rows}
        generatedAt={generatedAt}
      />
      <PercentFundedPdf
        reportType="baseline"
        organization={organization}
        percentFunded={theBook.baselinePercentFunded.rows}
        generatedAt={generatedAt}
      />

      <SimulationPlanPdf
        organization={organization}
        simulationPlan={theBook.simulationPlan.rows}
        baselinePlan={theBook.baselinePlan.rows}
        generatedAt={generatedAt}
      />
      <AnnualReportPdf
        organization={organization}
        reportType="simulation"
        annualReport={theBook.simulationAnnualReport.rows}
        generatedAt={generatedAt}
      />
      <PercentFundedPdf
        organization={organization}
        reportType="simulation"
        percentFunded={theBook.simulationPercentFunded.rows}
        generatedAt={generatedAt}
      />

      <AlternatePlanPdf
        organization={organization}
        alternatePlan={theBook.alternatePlan.rows}
        baselinePlan={theBook.baselinePlan.rows}
        generatedAt={generatedAt}
      />
      <AnnualReportPdf
        organization={organization}
        reportType="alternate"
        annualReport={theBook.alternateAnnualReport.rows}
        generatedAt={generatedAt}
      />
      <PercentFundedPdf
        organization={organization}
        reportType="alternate"
        percentFunded={theBook.alternatePercentFunded.rows}
        generatedAt={generatedAt}
      />

      <GlossaryPdf />
    </Fragment>
  );
};

export default TheBookPdf;
