import { array, boolean, number, object, string } from "yup";
import SharedFormUtils from "./SharedFormUtils";

class OrganizationFormUtils extends SharedFormUtils {
  static incomingSchema = object({
    name: string().required("Organization name is required."),
    measurementUnits: string().required("Measurement units is required."),
    numberOfUnits: number()
      .integer()
      .required("Number of units is required.")
      .typeError("Number of units is required."),
    interestEarnedOnReserves: array().of(
      object({
        id: number(),
        startYear: this.defaultBlank(number().integer().min(0)),
        endYear: this.defaultBlank(number().integer().min(1)),
        rate: this.defaultBlank(number().moreThan(0)),
      })
    ),
    inflationRatesOnItemCosts: array().of(
      object({
        id: number(),
        startYear: this.defaultBlank(number().integer().min(0)),
        endYear: this.defaultBlank(number().integer().min(1)),
        rate: this.defaultBlank(number().moreThan(0)),
      })
    ),
    reserveItems: array().of(
      object({
        itemName: string(),
        itemQuantity: this.defaultBlank(number().integer()),
        yearsToReplace: this.defaultBlank(number().integer().min(0)),
        costPerItem: this.defaultBlank(number().min(0)),
        lastInstall: this.defaultBlank(number().integer().min(1900)),
        estimatedUsefulLife: this.defaultBlank(number().integer().min(0)),
        totalCost: this.defaultBlank(number().min(0)),
        notes: string().nullable(),
        reportsCategory: string(),
        photoUrl: string().nullable(),
        documentUrl: string().nullable(),
        enabled: boolean(),
      })
    ),
  });

  static outgoingSchema = object({
    name: string(),
    measurementUnits: string(),
    numberOfUnits: this.blankToNull(number().integer()),
    interestEarnedOnReserves: array().of(
      object({
        id: number(),
        startYear: this.blankToNull(number().integer().min(0)),
        endYear: this.blankToNull(number().integer().min(1)),
        rate: this.blankToNull(number().moreThan(0)),
      })
    ),
    inflationRatesOnItemCosts: array().of(
      object({
        id: number(),
        startYear: this.blankToNull(number().integer().min(0)),
        endYear: this.blankToNull(number().integer().min(1)),
        rate: this.blankToNull(number().moreThan(0)),
      })
    ),
  });
}

export default OrganizationFormUtils;
