import React, { Fragment, useMemo } from "react";
import { Card } from "tabler-react";
import capitalize from "capitalize";
import CurrencyFormat from "../common/CurrencyFormat";
import { useFlexLayout, useTable } from "react-table";
import { reserveItemsPdfOptions } from "../../../utils/pdfOptions";

const ReserveItemsPdf = ({
  color,
  organization,
  reserveItems,
  showingTab,
  webOnly,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  const grandTotal = reserveItems.reduce(
    (memo, item) => memo + item.totalCost,
    0
  );
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
      width: 40,
      maxWidth: 250,
    }),
    []
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Item Name",
        accessor: "itemName",
      },
      {
        Header: "Reports Category",
        accessor: "reportsCategory.name",
        Cell: ({ row }) => row.original.reportsCategory?.name,
      },
      {
        Header: "Last Installed Date",
        accessor: "lastInstall",
      },
      {
        Header: "Est. Useful Life (Yrs)",
        accessor: "estimatedUsefulLife",
      },
      {
        Header: "Yrs to Replace",
        accessor: "yearsToReplace",
      },
      {
        Header: "Quantity",
        accessor: "itemQuantity",
      },
      {
        Header: "Latest Cost Year",
        accessor: "latestCostYear",
      },
      {
        Header: "Cost per Unit",
        accessor: "costPerItem",
        Cell: ({ row }) => (
          <CurrencyFormat>
            {parseFloat(row.original.costPerItem)}
          </CurrencyFormat>
        ),
        Footer: "TOTAL",
      },
      {
        Header: "Total Cost",
        accessor: "totalCost",
        Cell: ({ row }) => (
          <CurrencyFormat decimalScale={2}>
            {parseFloat(row.original.totalCost)}
          </CurrencyFormat>
        ),
        Footer: () => <CurrencyFormat>{parseFloat(grandTotal)}</CurrencyFormat>,
      },
      {
        Header: "RUL",
        accessor: "remainingUsefulLife",
      },
      {
        Header: "Photo",
        accessor: "photoBase64Preview",
        Cell: ({ row }) =>
          row.original.photoBase64Preview && (
            <img src={row.original.photoBase64Preview} alt="reserve item" />
          ),
      },
      {
        Header: "Document",
        accessor: "documentBase64Preview",
        Cell: ({ row }) =>
          row.original.documentBase64Preview && (
            <img src={row.original.documentBase64Preview} alt="reserve item" />
          ),
      },
    ];
  }, [grandTotal]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({ columns, data: reserveItems, defaultColumn }, useFlexLayout);
  return (
    <div
      className={`reserve-items-pdf ${webOnly ? "web-only" : ""}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(reserveItemsPdfOptions)
      }
    >
      <Card statusColor={color}>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>{organization.name}</Card.Title>
          <Card.Title>
            Reserve Items <br />({reserveItems.length} {capitalize(showingTab)})
          </Card.Title>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div {...getTableProps()} className="table">
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}
                      className="th d-flex align-items-center justify-content-center text-center"
                    >
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="tbody">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className="td d-flex align-items-center justify-content-center"
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                    {row.original.notes && (
                      <Fragment>
                        <div className="break"></div>
                        <div className="py-4 text-right w-100">
                          <i>{row.original.notes}</i>
                        </div>
                      </Fragment>
                    )}
                  </div>
                );
              })}
              {footerGroups.map((group) => (
                <div
                  {...group.getFooterGroupProps()}
                  className="tr py-4 border-0"
                >
                  {group.headers.map((column) => (
                    <div
                      {...column.getFooterProps()}
                      className="td d-flex align-items-center justify-content-center"
                    >
                      {column.render("Footer")}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReserveItemsPdf;
