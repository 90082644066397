import React, { Fragment, useContext } from "react";
import { useQuery } from "@apollo/client";
import { GET_RESERVE_ITEMS, GET_THE_BOOK } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import UserContext from "../common/UserContext";
import ReportsHelperMenu from "./ReportsHelperMenu";
import TheBookPdf from "./TheBookPdf";
import BaselinePlanTable from "./BaselinePlanTable";
import SimulationPlanTable from "./SimulationPlanTable";
import AnnualReportTable from "./AnnualReportTable";
import PercentFundedTable from "./PercentFundedTable";
import FundingSummaryPdf from "./FundingSummaryPdf";
import Glossary from "../reserve_items/Glossary";
import AlternatePlanTable from "./AlternatePlanTable";
import ReserveItemsPdf from "../reserve_items/ReserveItemsPdf";
import FinancialDashboardPdf from "../financial_dashboard/FinancialDashboardPdf";
import TheBookToc from "./TheBookToc";
import { Alert, Card } from "tabler-react";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import {
  alternatePercentFundedChartRef,
  alternatePlanChartRef,
  baselinePercentFundedChartRef,
  baselinePlanChartRef,
  simulationPercentFundedChartRef,
  simulationPlanChartRef,
} from "../../../utils/theBookHelpers";
import SpecialRequirementReportPdf from "./SpecialRequirementReportPdf";
import ParmReportPdf from "./ParmReportPdf";

const TheBook = () => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { data, loading, error } = useQuery(GET_THE_BOOK, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      refreshCalculations: true,
    },
    fetchPolicy: "no-cache",
  });
  const { loading: resItemsLoading, data: resItemsData } = useQuery(
    GET_RESERVE_ITEMS,
    { fetchPolicy: "no-cache" }
  );

  if (loading || resItemsLoading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;

  const reserveItems = resItemsData.reserveItems.filter((e) => e.enabled);
  const missingPrereqs = data.theBook.missingPrerequisites;
  if (missingPrereqs.length)
    return (
      <Card statusColor="gray" className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>The Book</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view The Book, please complete the following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  const hasCustomReportsCategory =
    !!data.theBook.baselineAnnualReport.rows[0].customCategory;
  const customReportsCategoryName =
    hasCustomReportsCategory &&
    organization.reportsCategories.find((c) => c.categoryType === "custom")
      .name;
  const generatedAt = new Date().toLocaleString("en-US");
  return (
    <Fragment>
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix="the_book" forTheBook={true} />
      </div>

      <TheBookToc
        organization={organization}
        webOnly={true}
        generatedAt={generatedAt}
      />

      <div id="reserve-items">
        <ReserveItemsPdf
          organization={organization}
          color="gray"
          reserveItems={reserveItems}
          showingTab="enabled"
          webOnly={true}
          generatedAt={generatedAt}
        />
      </div>

      <div id="financial-dashboard">
        <FinancialDashboardPdf
          baselinePlanSetting={data.theBook.baselinePlanSetting}
          simulationPlanSetting={data.theBook.simulationPlanSetting}
          alternatePlanSetting={data.theBook.alternatePlanSetting}
          organization={organization}
          webOnly={true}
          generatedAt={generatedAt}
        />
      </div>

      <div id="funding-summary">
        <FundingSummaryPdf
          color="cyan"
          organization={organization}
          fundingSummary={data.theBook.fundingSummary}
          webOnly={true}
          generatedAt={generatedAt}
        />
      </div>

      <div id="baseline-plan">
        <BaselinePlanTable
          organization={organization}
          color="blue"
          plan={data.theBook.baselinePlan.rows}
          generatedAt={generatedAt}
          chartRef={baselinePlanChartRef}
        />
      </div>

      <div id="baseline-annual-report" className="annual-plan">
        <AnnualReportTable
          organization={organization}
          reportType="baseline"
          color="blue"
          report={data.theBook.baselineAnnualReport.rows}
          hasCustomReportsCategory={hasCustomReportsCategory}
          customReportsCategoryName={customReportsCategoryName}
          generatedAt={generatedAt}
        />
      </div>

      <div id="baseline-percent-funded">
        <PercentFundedTable
          organization={organization}
          reportType="baseline"
          color="blue"
          percentFunded={data.theBook.baselinePercentFunded.rows}
          generatedAt={generatedAt}
          chartRef={baselinePercentFundedChartRef}
        />
      </div>
      <div id="simulation-plan">
        <SimulationPlanTable
          organization={organization}
          color="green"
          plan={data.theBook.simulationPlan.rows}
          baselinePlan={data.theBook.baselinePlan.rows}
          generatedAt={generatedAt}
          chartRef={simulationPlanChartRef}
        />
      </div>
      <div id="simulation-annual-report" className="annual-plan">
        <AnnualReportTable
          organization={organization}
          reportType="simulation"
          color="green"
          report={data.theBook.simulationAnnualReport.rows}
          hasCustomReportsCategory={hasCustomReportsCategory}
          customReportsCategoryName={customReportsCategoryName}
          generatedAt={generatedAt}
        />
      </div>
      <div id="simulation-percent-funded">
        <PercentFundedTable
          organization={organization}
          reportType="simulation"
          color="green"
          percentFunded={data.theBook.simulationPercentFunded.rows}
          generatedAt={generatedAt}
          chartRef={simulationPercentFundedChartRef}
        />
      </div>

      <div id="alternate-plan">
        <AlternatePlanTable
          organization={organization}
          color="purple"
          plan={data.theBook.alternatePlan.rows}
          baselinePlan={data.theBook.baselinePlan.rows}
          generatedAt={generatedAt}
          chartRef={alternatePlanChartRef}
        />
      </div>
      <div id="alternate-annual-report" className="annual-plan">
        <AnnualReportTable
          organization={organization}
          reportType="alternate"
          color="purple"
          report={data.theBook.alternateAnnualReport.rows}
          hasCustomReportsCategory={hasCustomReportsCategory}
          customReportsCategoryName={customReportsCategoryName}
          generatedAt={generatedAt}
        />
      </div>
      <div id="alternate-percent-funded">
        <PercentFundedTable
          organization={organization}
          reportType="alternate"
          color="purple"
          percentFunded={data.theBook.alternatePercentFunded.rows}
          generatedAt={generatedAt}
          chartRef={alternatePercentFundedChartRef}
        />
      </div>

      <div id="srr-report">
        <SpecialRequirementReportPdf
          color="purple"
          organization={organization}
          specialRequirementReport={data.theBook.specialRequirementsReport}
          reportLoading={loading}
          webOnly={true}
          notEditable={true}
        />
      </div>

      <div id="parm-report">
        <ParmReportPdf
          color="purple"
          organization={organization}
          parmReport={data.theBook.parmReport}
          baselinePlanSetting={data.theBook.baselinePlanSetting}
          percentFunded={data.theBook.baselinePercentFunded.rows[0].percent}
          webOnly={true}
        />
      </div>

      <div id="glossary">
        <Glossary />
      </div>

      <div className="d-none">
        <TheBookPdf
          theBook={data.theBook}
          organization={organization}
          reserveItems={reserveItems}
          generatedAt={generatedAt}
        />
      </div>
    </Fragment>
  );
};

export default TheBook;
