import React, { useState } from "react";
import Modal from "react-modal";
import { Dimmer, Table } from "tabler-react";
import { useMutation } from "@apollo/client";
import { CREATE_OR_UPDATE_CUSTOM_REPORTS_CATEGORY } from "../../../graphql/mutations";

const NewReportsCategoryModal = ({
  show,
  setShow,
  existingCustomReportsCategory,
}) => {
  const [showFormLoader, setShowFormLoader] = useState(false);
  const [reportsCategory, setReportsCategory] = useState({
    name: existingCustomReportsCategory?.name || "",
  });
  const [createOrUpdateCustomReportsCategory] = useMutation(
    CREATE_OR_UPDATE_CUSTOM_REPORTS_CATEGORY,
    {
      refetchQueries: ["GetUser"],
      awaitRefetchQueries: true,
    }
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => setShow(false)}
      bodyOpenClassName="modal-open"
      overlayClassName="modal"
      className="modal-dialog"
      ariaHideApp={false}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {existingCustomReportsCategory ? "Edit" : "New"} Custom Reports
            Category
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => setShow(false)}
          />
        </div>
        <div className="modal-body">
          <Dimmer loader active={showFormLoader}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Reports Category Name</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Col>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      value={reportsCategory.name}
                      onChange={(e) =>
                        setReportsCategory({
                          ...reportsCategory,
                          name: e.target.value,
                        })
                      }
                    />
                  </Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </Dimmer>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              setShowFormLoader(true);
              await createOrUpdateCustomReportsCategory({
                variables: {
                  attributes: reportsCategory,
                },
              });
              setShow(false);
              setShowFormLoader(false);
            }}
          >
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewReportsCategoryModal;
