import React from "react";
import { Card, Grid, Page } from "tabler-react";

const FaqPage = () => {
  return (
    <Page.Content>
      <Page.Header title="Frequently Asked Questions" />
      <Grid.Row className="d-flex justify-content-center align-items-center">
        <Grid.Col width={12}>
          <Card>
            <Card.Body>
              <p>
                <strong>What Type of Application is this?</strong>
              </p>
              <p>A Web based Portal application that is subscription based.</p>
              <p>
                <strong>
                  What is the Availability of the Pemaquid&trade; Reserves
                  Planner?
                </strong>
              </p>
              <p>
                This is available 7 days a week x 24 hours a day x 365 Days a
                year, otherwise known as 7x24x365
              </p>
              <p>
                <strong>
                  How Many Users have access to the Pemaquid&trade; Reserves
                  Planner?
                </strong>
              </p>
              <p>
                Each License/Subscription provides for up to 5 Users (Subscription Admin + 4)
              </p>
              <p>
                <strong>What about Training?</strong>
              </p>
              <p>
                The Subscription price includes 3 hours of training
              </p>
              <p>
                <strong>How is the Training Conducted?</strong>
              </p>
              <p>
                This is done using Zoom providing live, interactive dialogue.
              </p>
              <p>
                <strong>Any other training?</strong>
              </p>
              <p>
                Yes, 3 Refresher Training You Tube recordings that mimic the
                live training sessions, available 7x24x365
              </p>
              <p>
                <strong>What device types does this application run on?</strong>
              </p>
              <p>
                Desktop, Laptop and Personal Computer (PC) and similar devices.
              </p>
              <p>
                <strong>
                  What browsers work with this Pemaquid Reserve Planner?
                </strong>
              </p>
              <p>Google Chrome and Microsoft Edge</p>
              <p>
                <strong>
                  What source documents are available for Estimated Useful Life
                  determination?
                </strong>
              </p>
              <p>InterNACHI and Fannie Mae</p>
              <p>
                <strong>What are the types of reports available?</strong>
              </p>
              <p>All Reports and Graphs are created in PDF Format</p>
              <p>
                <strong>What format is needed to post photographs?</strong>
              </p>
              <p>.JPEG and .PDF are the only 2 formats</p>
              <p>
                <strong>
                  How far into the future do the reports calculate?
                </strong>
              </p>
              <p>
                The Pemaquid&trade; Reserves Planner calculates on a standard 45
                years on all reports
              </p>
              <p>
                <strong>How can the information created be shared?</strong>
              </p>
              <p>Authorized users may download all reports for future use</p>
              <p>
                <strong>
                  What are the types of tools one may need to commence this
                  work?
                </strong>
              </p>
              <p>
                Look in the Accelerator section that contains recommendations to
                get started quickly
              </p>
              <p>
                <strong>What if I forget to Logout?</strong>
              </p>
              <p>
                After 30 minutes of inactivity, the system will automatically
                log you out as a security measure
              </p>
              <p>
                <strong>What if I would like incremental help?</strong>
              </p>
              <p>Ad hoc support can be secured in hourly blocks of time</p>
              <p>
                <strong>Who do I contact?</strong>
              </p>
              <p>Utilize Contact Us options on our web page</p>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
};

export default FaqPage;
