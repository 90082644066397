import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer } from "react-toastify";
import { openReplay } from "./utils/OpenReplay";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
});

const ErrorBoundary = Bugsnag.getPlugin("react");

openReplay.start();

ReactDOM.render(
  <ErrorBoundary>
    <ToastContainer
      position="top-center"
      autoClose={10000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
    />
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
