import { number, object, string } from "yup";
import SharedFormUtils from "./SharedFormUtils";

class BaselinePlanSettingFormUtils extends SharedFormUtils {
  static incomingSchema = object({
    id: this.defaultBlank(string()),
    beginningYear: this.defaultBlank(string()),
    beginningBalance: this.defaultBlank(string()),
    currentMonthlyHomeownerContributions: this.defaultBlank(string()),
    currentTotalAnnualReservesFunding: this.defaultBlank(string()),
  });

  static outgoingSchema = object({
    id: this.blankToNull(number().integer()),
    beginningYear: this.blankToNull(number().integer()),
    beginningBalance: this.blankToNull(number()),
    currentMonthlyHomeownerContributions: this.blankToNull(
      number().nullable(true)
    ),
    currentTotalAnnualReservesFunding: this.blankToNull(number()),
  });
}

export default BaselinePlanSettingFormUtils;
