import logo from "../logo.png";
import { Button, Card, Form, Loader } from "tabler-react";
import React from "react";
import StandaloneFormLayout from "../components/StandaloneFormLayout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getApp } from "firebase/app";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";

const ResetPasswordPage = () => {
  const history = useHistory();

  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(
      object({
        email: string().email().required(),
      })
    ),
  });

  const { isSubmitting } = formState;

  const onSubmit = async ({ email }) => {
    const auth = getAuth(getApp());
    await sendPasswordResetEmail(auth, email);
    toast.info(`A password reset password email has been sent.`);
    history.push("/login");
  };
  return (
    <StandaloneFormLayout imageURL={logo}>
      <Form
        className="card"
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card.Body className="p-6">
          <Card.Title RootComponent="div">
            Enter your email to reset your password:
          </Card.Title>
          <Form.Group label="Email">
            <input
              name="email"
              ref={register}
              className={`form-control ${errors.email && "is-invalid"}`}
              placeholder="Enter Email"
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </Form.Group>
          <Form.Footer>
            <Button
              type="submit"
              color="primary"
              block={true}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Loader className="sm white center" />
              ) : (
                "Reset Password"
              )}
            </Button>
          </Form.Footer>
        </Card.Body>
        <Card.Footer className="text-center">
          <div>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </div>
          <div>
            <Link to="/login">Login</Link>
          </div>
        </Card.Footer>
      </Form>
    </StandaloneFormLayout>
  );
};
export default ResetPasswordPage;
