import gql from "graphql-tag";
import {
  AcceleratorFragment,
  AlternatePlanFragment,
  AlternatePlanSettingFragment,
  AnnualReportFragment,
  BaselinePlanFragment,
  BaselinePlanSettingFragment,
  ChangeLogReportFragment,
  CollaboratorFragment,
  FundingSummaryFragment,
  InflationCostsFragment,
  InvitationFragment,
  InvoicePreviewFragment,
  PaidInvoiceFragment,
  ParmReportFragment,
  PaymentMethodFragment,
  PercentFundedFragment,
  ReserveItemFragment,
  RoleFragment,
  SimulationPlanFragment,
  SimulationPlanSettingFragment,
  SpecialRequirementReportFragment,
  SpecialRequirementReportSettingFragment,
  SubscriptionBillingDetailsFragment,
  TheBookFragment,
  UserFragment,
} from "./fragments";

export const GET_USER = gql`
  query GetUser {
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_RESERVE_ITEMS = gql`
  query GetReserveItems {
    reserveItems {
      ...ReserveItemFragment
    }
  }
  ${ReserveItemFragment}
`;

export const GET_ANNUAL_REPORT = gql`
  query GetAnnualReport($organizationId: ID!, $reportType: String!) {
    annualReport(organizationId: $organizationId, reportType: $reportType) {
      ...AnnualReportFragment
    }
  }
  ${AnnualReportFragment}
`;

export const GET_PERCENT_FUNDED = gql`
  query GetPercentFunded($organizationId: ID!, $reportType: String!) {
    percentFunded(organizationId: $organizationId, reportType: $reportType) {
      ...PercentFundedFragment
    }
  }
  ${PercentFundedFragment}
`;

export const GET_SPECIAL_REQUIREMENT_REPORT = gql`
  query GetSpecialRequirementReport {
    specialRequirementReport {
      ...SpecialRequirementReportFragment
    }
  }
  ${SpecialRequirementReportFragment}
`;

export const GET_SPECIAL_REQUIREMENT_REPORT_SETTING = gql`
  query GetSpecialRequirementReportSetting {
    specialRequirementReportSetting {
      ...SpecialRequirementReportSettingFragment
    }
  }
  ${SpecialRequirementReportSettingFragment}
`;

export const GET_BASELINE_PLAN = gql`
  query GetBaselinePlan {
    baselinePlan {
      ...BaselinePlanFragment
    }
  }
  ${BaselinePlanFragment}
`;

export const GET_BASELINE_PLAN_SETTING = gql`
  query GetBaselinePlanSetting {
    baselinePlanSetting {
      ...BaselinePlanSettingFragment
    }
  }
  ${BaselinePlanSettingFragment}
`;

export const GET_SIMULATION_PLAN = gql`
  query GetSimulationPlan {
    simulationPlan {
      ...SimulationPlanFragment
    }
  }
  ${SimulationPlanFragment}
`;

export const GET_SIMULATION_PLAN_SETTING = gql`
  query GetSimulationPlanSetting {
    simulationPlanSetting {
      ...SimulationPlanSettingFragment
    }
  }
  ${SimulationPlanSettingFragment}
`;

export const GET_ALTERNATE_PLAN = gql`
  query GetAlternatePlan {
    alternatePlan {
      ...AlternatePlanFragment
    }
  }
  ${AlternatePlanFragment}
`;

export const GET_ALTERNATE_PLAN_SETTING = gql`
  query GetAlternatePlanSetting {
    alternatePlanSetting {
      ...AlternatePlanSettingFragment
    }
  }
  ${AlternatePlanSettingFragment}
`;

export const GET_ACCELERATORS = gql`
  query GetAccelerators {
    accelerators {
      ...AcceleratorFragment
    }
  }
  ${AcceleratorFragment}
`;

export const GET_INFLATION_COSTS = gql`
  query GetInflationCosts {
    inflationCosts {
      ...InflationCostsFragment
    }
  }
  ${InflationCostsFragment}
`;

export const GET_FUNDING_SUMMARY = gql`
  query GetFundingSummary {
    fundingSummary {
      ...FundingSummaryFragment
    }
  }
  ${FundingSummaryFragment}
`;

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    paymentMethods {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragment}
`;

export const GET_INVOICE_PREVIEW = gql`
  query GetInvoicePreview($promoCode: String, $quantity: Int) {
    invoicePreview(promoCode: $promoCode, quantity: $quantity) {
      ...InvoicePreviewFragment
    }
  }
  ${InvoicePreviewFragment}
`;

export const GET_THE_BOOK = gql`
  query GetTheBook {
    theBook {
      ...TheBookFragment
    }
  }
  ${TheBookFragment}
`;

export const GET_ROLES = gql`
  query GetRoles {
    roles {
      ...RoleFragment
    }
  }
  ${RoleFragment}
`;

export const GET_PAID_INVOICES = gql`
  query GetPaidInvoices {
    paidInvoices {
      ...PaidInvoiceFragment
    }
  }
  ${PaidInvoiceFragment}
`;

export const GET_SUBSCRIPTION_BILLING_DETAILS = gql`
  query GetSubscriptionBillingDetails {
    subscriptionBillingDetails {
      ...SubscriptionBillingDetailsFragment
    }
  }
  ${SubscriptionBillingDetailsFragment}
`;

export const GET_INVITATION = gql`
  query GetInvitation($token: String!) {
    invitation(token: $token) {
      ...InvitationFragment
    }
  }
  ${InvitationFragment}
`;

export const GET_COLLABORATORS = gql`
  query GetCollaborators {
    collaborators {
      ...CollaboratorFragment
    }
  }
  ${CollaboratorFragment}
`;

export const GET_CHANGE_LOG_REPORT = gql`
  query GetChangeLogReport($timeframe: String) {
    changeLogReport(timeframe: $timeframe) {
      ...ChangeLogReportFragment
    }
  }
  ${ChangeLogReportFragment}
`;

export const GET_PARM_REPORT = gql`
  query GetParmReport {
    parmReport {
      ...ParmReportFragment
    }
  }
  ${ParmReportFragment}
`;

export const GET_THE_BOOK_PDF_URL = gql`
  query GetTheBookPdfUrl {
    theBookPdfUrl
  }
`;
