import { EditModule } from "tabulator-tables";
const customNumberEditor = function (
  cell,
  onRendered,
  success,
  cancel,
  editorParams
) {
  let input = EditModule.editors.number(
    cell,
    onRendered,
    success,
    cancel,
    editorParams
  );
  input.addEventListener("keydown", function (e) {
    //enter
    if (e.keyCode === 13) {
      cell.getElement().focus();
      cell.getTable().navigateNext();
    }
  });
  return input;
};
export default customNumberEditor;
