import { mixed } from "yup";

class SharedFormUtils {
  static isBlankString = (v) => typeof v === "string" && v === "";
  static isNull = (v) => v === null;

  static defaultBlank = (rule) => {
    return mixed().when({
      is: (v) => this.isBlankString(v) || this.isNull(v),
      then: mixed().transform((o) => (o === null ? "" : o)),
      otherwise: rule,
    });
  };

  static blankToNull = (rule) => {
    return mixed().when({
      is: (v) => this.isBlankString(v) || this.isNull(v),
      then: mixed().transform(() => null),
      otherwise: rule,
    });
  };
}

export default SharedFormUtils;
