import React, { forwardRef } from "react";
import CurrencyFormat from "./CurrencyFormat";

const AccountingFormat = forwardRef((props, ref) => {
  if (props.children < 0)
    return (
      <span ref={ref} className="text-danger">
        (<CurrencyFormat>{-1 * props.children}</CurrencyFormat>)
      </span>
    );
  return (
    <div ref={ref}>
      <CurrencyFormat>{props.children}</CurrencyFormat>
    </div>
  );
});

export default AccountingFormat;
