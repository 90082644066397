import { number, object, string } from "yup";
import SharedFormUtils from "./SharedFormUtils";

class SimulationPlanSettingFormUtils extends SharedFormUtils {
  static incomingSchema = object({
    id: this.defaultBlank(string()),
    beginningYear: this.defaultBlank(string()),
    beginningBalance: this.defaultBlank(string()),
    desiredAdditionalAnnualFunds: this.defaultBlank(string()),
    interestEarnedInBeginningYear: this.defaultBlank(string()),
    interestRate: this.defaultBlank(string()),
    monthlyFeeIncreasePerUnit: this.defaultBlank(string()),
    fundsCollectedInBeginningYear: this.defaultBlank(string()),
  });

  static outgoingSchema = object({
    id: this.blankToNull(number().integer()),
    beginningYear: this.blankToNull(number().integer()),
    beginningBalance: this.blankToNull(number()),
    desiredAdditionalAnnualFunds: this.blankToNull(number()),
    interestEarnedInBeginningYear: this.blankToNull(number()),
    interestRate: this.blankToNull(number()),
    monthlyFeeIncreasePerUnit: this.blankToNull(number()),
    fundsCollectedInBeginningYear: this.blankToNull(number()),
  });
}

export default SimulationPlanSettingFormUtils;
