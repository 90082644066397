import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import SettingsPage from "./pages/authenticated/SettingsPage";
import LogoutPage from "./pages/LogoutPage";
import NewOrganizationPage from "./pages/authenticated/NewOrganizationPage";
import PlansAndReportsPage from "./pages/authenticated/PlansAndReportsPage";
import AcceleratorsPage from "./pages/authenticated/AcceleratorsPage";
import FinancialDashboardPage from "./pages/authenticated/FinancialDashboardPage";
import TrainingPage from "./pages/authenticated/TrainingPage";
import ContactUsPage from "./pages/authenticated/ContactUsPage";
import FaqPage from "./pages/authenticated/FaqPage";
import UserProvider from "./components/authenticated/common/UserProvider";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DefaultLayout from "./components/authenticated/layouts/DefaultLayout";
import ReserveItemsPage from "./pages/authenticated/ReserveItemsPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ActivateSubscriptionPage from "./pages/authenticated/ActivateSubscriptionPage";
import NewOrganizationReserveItemsPage from "./pages/authenticated/NewOrganizationReserveItemsPage";
import NewOrganizationInviteUsersPage from "./pages/authenticated/NewOrganizationInviteUsersPage";
import AcceptInvitationAuthenticatedRedirect from "./components/AcceptInvitationAuthenticatedRedirect";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AuthenticatedApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/login">
          <Redirect to="/" />
        </Route>
        <Route exact path="/accept">
          <AcceptInvitationAuthenticatedRedirect />
        </Route>
        <Route exact path="/activate">
          <UserProvider>
            <Elements stripe={stripePromise}>
              <ActivateSubscriptionPage />
            </Elements>
          </UserProvider>
        </Route>
        <Route exact path="/organizations/new">
          <UserProvider>
            <NewOrganizationPage />
          </UserProvider>
        </Route>
        <Route exact path="/organizations/new/reserve_items">
          <UserProvider>
            <NewOrganizationReserveItemsPage />
          </UserProvider>
        </Route>
        <Route exact path="/organizations/new/invite_users">
          <UserProvider>
            <NewOrganizationInviteUsersPage />
          </UserProvider>
        </Route>
        <Route exact path="/settings">
          <UserProvider>
            <SettingsPage />
          </UserProvider>
        </Route>
        <Route path="/reserve_items">
          <UserProvider>
            <ReserveItemsPage />
          </UserProvider>
        </Route>
        <Route path="/plans_and_reports">
          <UserProvider>
            <PlansAndReportsPage />
          </UserProvider>
        </Route>
        <Route exact path="/accelerators">
          <UserProvider>
            <AcceleratorsPage />
          </UserProvider>
        </Route>
        <Route exact path="/financial_dashboard">
          <UserProvider>
            <FinancialDashboardPage />
          </UserProvider>
        </Route>
        <Route exact path="/training">
          <UserProvider>
            <TrainingPage />
          </UserProvider>
        </Route>
        <Route exact path="/contact_us">
          <UserProvider>
            <ContactUsPage />
          </UserProvider>
        </Route>
        <Route exact path="/faq">
          <UserProvider>
            <DefaultLayout mainClassName="faq">
              <FaqPage />
            </DefaultLayout>
          </UserProvider>
        </Route>
        <Route exact path="/terms">
          <TermsPage />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPolicyPage />
        </Route>
        <Route path="*">
          <Redirect to="/reserve_items" />
        </Route>
      </Switch>
    </Router>
  );
};

export default AuthenticatedApp;
