import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import LoadingPage from "./LoadingPage";
import { useApolloClient } from "@apollo/client";

const LogoutPage = () => {
  let [loading, setLoading] = useState(true);
  const client = useApolloClient();

  useEffect(() => {
    let isMounted = true;
    const auth = getAuth(getApp());
    signOut(auth).then(() => {
      client.clearStore().then(() => {
        if (isMounted) setLoading(false);
      });
    });

    return () => {
      isMounted = false;
      setLoading(false);
    };
  });

  if (loading) return <LoadingPage />;
  return <Redirect to="/" />;
};

export default LogoutPage;
