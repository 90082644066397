import React, { useContext } from "react";
import UserContext from "../common/UserContext";
import { useQuery } from "@apollo/client";
import { GET_FUNDING_SUMMARY } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import ReportsHelperMenu from "./ReportsHelperMenu";
import FundingSummaryPdf from "./FundingSummaryPdf";
import { Alert, Card } from "tabler-react";
import MissingPrerequisitesList from "./MissingPrerequisitesList";

const FundingSummary = ({ color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { loading, error, data } = useQuery(GET_FUNDING_SUMMARY, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;

  const missingPrereqs = data.fundingSummary.missingPrerequisites;
  if (missingPrereqs.length)
    return (
      <Card statusColor={color} className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>Funding Summary</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Funding Summary, please complete the
              following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <div className="funding-summary">
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu filenamePrefix={`funding-summary`} />
      </div>
      <FundingSummaryPdf
        color={color}
        organization={organization}
        fundingSummary={data.fundingSummary}
        webOnly={true}
      />
      <div className="d-none">
        <FundingSummaryPdf
          organization={organization}
          fundingSummary={data.fundingSummary}
        />
      </div>
    </div>
  );
};

export default FundingSummary;
