import { Button, Card, Grid, Loader } from "tabler-react";
import { getApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import React, { useContext, useState } from "react";
import UserContext from "../common/UserContext";

const ResetPasswordCard = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Reset Password</Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col className="mb-4">
            To reset your password, click the button below and you will be sent
            an email with a link to reset your password.
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="justify-content-center">
          <Grid.Col width={6}>
            <Button
              block={true}
              color="primary"
              type="button"
              onClick={async () => {
                setLoading(true);
                const auth = getAuth(getApp());
                await sendPasswordResetEmail(auth, user.email);
                toast.info(
                  `A password reset email has been sent to ${user.email}`
                );
                setLoading(false);
              }}
            >
              {loading ? (
                <Loader className="sm white center" />
              ) : (
                "Reset Password"
              )}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Card>
  );
};

export default ResetPasswordCard;
