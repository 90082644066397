import React from "react";
import { Button, Form } from "tabler-react";

const RedeemInput = ({ promoCode, setPromoCode, recalculate }) => {
  const handleRedeem = () => {
    if (!promoCode || promoCode === "") return;
    recalculate();
  };
  return (
    <Form.InputGroup>
      <input
        className="form-control"
        name="promoCode"
        onChange={(e) => setPromoCode(e.target.value)}
        placeholder="Promo Code (optional)"
      />
      <Form.InputGroup append>
        <Button color="secondary" onClick={handleRedeem}>
          Redeem
        </Button>
      </Form.InputGroup>
    </Form.InputGroup>
  );
};

export default RedeemInput;
