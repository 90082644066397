import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Loader, Table } from "tabler-react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_SIMULATION_PLAN_SETTING } from "../../../graphql/mutations";
import SimulationPlanSettingFormUtils from "../../../utils/SimulationPlanSettingFormUtils";
import UserContext from "../common/UserContext";
import { GET_SIMULATION_PLAN_SETTING } from "../../../graphql/queries";
import ErrorRedirect from "../../ErrorRedirect";
import {
  abbrevMeasurementUnit,
  formatDecimalForUnits,
} from "../../../utils/helpers";
import { titleCase } from "title-case";
import { Controller, useForm } from "react-hook-form";
import useAutoSave from "../../../hooks/useAutoSave";
import AutoSaveStatus from "../common/AutoSaveStatus";
import { NumericFormat } from "react-number-format";

const SimulationPlanSettingsForm = ({ canEdit }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { register, handleSubmit, reset, getValues, control } = useForm({
    defaultValues: {
      desiredAdditionalAnnualFunds: "",
    },
  });
  const [monthlyIncrease, setMonthlyIncrease] = useState(0);

  const { data, loading, error } = useQuery(GET_SIMULATION_PLAN_SETTING, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.simulationPlanSetting) {
      const newDefaults = SimulationPlanSettingFormUtils.incomingSchema.cast(
        data.simulationPlanSetting,
        { stripUnknown: true }
      );
      reset(newDefaults);
      setMonthlyIncrease(newDefaults.monthlyFeeIncreasePerUnit);
    }
  }, [data, reset]);

  const [updateSimulationPlanSetting] = useMutation(
    UPDATE_SIMULATION_PLAN_SETTING
  );

  const [onSubmit, lastSubmittedAt, submitting] = useAutoSave(async () => {
    let values = getValues();
    const { data } = await updateSimulationPlanSetting({
      variables: {
        attributes: SimulationPlanSettingFormUtils.outgoingSchema.validateSync(
          values,
          { stripUnknown: true }
        ),
      },
    });
    const savedValues = SimulationPlanSettingFormUtils.incomingSchema.cast(
      data.updateSimulationPlanSetting,
      { stripUnknown: true }
    );
    setMonthlyIncrease(savedValues.monthlyFeeIncreasePerUnit);
  });

  if (error) return <ErrorRedirect error={error} />;

  if (loading)
    return (
      <Card className="simulation-plan-settings">
        <Card.Header>
          <Card.Title>Simulation Settings</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Loader />
        </Card.Body>
      </Card>
    );

  return (
    <form onSubmit={handleSubmit} className="simulation-plan-settings">
      <input name="id" type="hidden" ref={register} />
      <Card>
        <Card.Header>
          <Card.Title>Simulation Settings</Card.Title>
          <Card.Options>
            <AutoSaveStatus
              submitting={submitting}
              lastSubmittedAt={lastSubmittedAt}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col>
              <Table className="card-table table-vcenter">
                <Table.Body>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="simulation-beginning-year">
                        Beginning Year
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <div
                        id="simulation-beginning-year"
                        className="read-only p-2 text-nowrap text-right"
                      >
                        {data?.simulationPlanSetting?.beginningYear ? (
                          data?.simulationPlanSetting?.beginningYear
                        ) : (
                          <span className="text-muted">Year</span>
                        )}
                      </div>
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="simulation-beginning-balance">
                        Beginning Balance
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <NumericFormat
                        id="simulation-beginning-balance"
                        className="read-only p-2 text-nowrap text-right"
                        allowNegative={false}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale="2"
                        prefix="$"
                        value={data?.simulationPlanSetting?.beginningBalance}
                      />
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col className="pr-1 py-1">
                      <label htmlFor="simulation-approved-budget">
                        Approved Budget this Period
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <NumericFormat
                        id="simulation-approved-budget"
                        className="read-only p-2 text-nowrap text-right"
                        allowNegative={false}
                        thousandSeparator={true}
                        displayType="text"
                        decimalScale="2"
                        prefix="$"
                        value={
                          data?.simulationPlanSetting
                            ?.fundsCollectedInBeginningYear
                        }
                      />
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="simulation-what-if-input">
                        "What if" Simulation Reserves Fund Increase
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <Controller
                        name="desiredAdditionalAnnualFunds"
                        control={control}
                        render={({ onChange, value }) => (
                          <NumericFormat
                            id="simulation-what-if-input"
                            placeholder="Amount"
                            className="form-control text-right pr-2"
                            readOnly={!canEdit}
                            allowNegative={false}
                            thousandSeparator={true}
                            decimalScale="2"
                            valueIsNumericString={true}
                            onValueChange={(v, { event }) => {
                              onChange(v.value);
                              if (event) handleSubmit(onSubmit)(event);
                            }}
                            prefix="$"
                            value={value}
                          />
                        )}
                      />
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <label htmlFor="simulation-monthly-fee-increase">
                        Monthly Fee Increase per{" "}
                        {titleCase(
                          abbrevMeasurementUnit(organization.measurementUnits)
                        )}
                      </label>
                    </Table.Col>
                    <Table.Col className="d-flex justify-content-end">
                      <NumericFormat
                        id="simulation-monthly-fee-increase"
                        className="read-only p-2 text-nowrap text-right"
                        displayType="text"
                        prefix="$"
                        value={formatDecimalForUnits(
                          organization.measurementUnits,
                          monthlyIncrease
                        )}
                      />
                    </Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </form>
  );
};

export default SimulationPlanSettingsForm;
