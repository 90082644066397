import { number, object, string } from "yup";
import SharedFormUtils from "./SharedFormUtils";

class AlternatePlanSettingFormUtils extends SharedFormUtils {
  static incomingSchema = object({
    id: this.defaultBlank(string()),
    beginningYear: this.defaultBlank(string()),
    beginningBalance: this.defaultBlank(string()),
    annualAmountRequired: this.defaultBlank(string()),
    interestRate: this.defaultBlank(string()),
    monthlyAmountRequired: this.defaultBlank(string()),
    monthlyPerUnit: this.defaultBlank(string()),
    projectedExpensesInTerm: this.defaultBlank(string()),
    targetAmount: this.defaultBlank(string()),
    term: this.defaultBlank(string()),
    totalFundsRequired: this.defaultBlank(string()),
  });

  static outgoingSchema = object({
    id: this.blankToNull(number().integer()),
    beginningYear: this.blankToNull(number().integer()),
    beginningBalance: this.blankToNull(number()),
    interestRate: this.blankToNull(number()),
    targetAmount: this.blankToNull(number()),
    term: this.blankToNull(number()),
  });

  static isRangeAndRateRowComplete(row) {
    const allPresent =
      row.startYear !== null && row.endYear !== null && row.rate !== null;
    return allPresent && row.startYear < row.endYear;
  }
}

export default AlternatePlanSettingFormUtils;
