import React from "react";
import ReactDOM from "react-dom";

const customReactFormatter = (JSX, afterRenderCallback) => {
  return function customFormatter(cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const renderFn = function () {
      const cellEl = cell.getElement();
      if (cellEl) {
        const formatterCell = cellEl.querySelector(".formatterCell");
        if (formatterCell) {
          // Note: this will preserve original props passed in, and merge in the cell prop
          const CompWithMoreProps = React.cloneElement(JSX, { cell: cell });
          ReactDOM.render(
            CompWithMoreProps,
            cellEl.querySelector(".formatterCell"),
            () => {
              if (afterRenderCallback) afterRenderCallback(cell);
            }
          );
        }
      }
    };
    onRendered(renderFn); // initial render only.
    setTimeout(function () {
      renderFn(); // render every time cell value changed.
    }, 0);
    return '<div class="formatterCell"></div>';
  };
};
export default customReactFormatter;
