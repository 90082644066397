import React, { useContext, useEffect, useState } from "react";
import { Card, Loader, Table } from "tabler-react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_BASELINE_PLAN_SETTING } from "../../../graphql/mutations";
import BaselinePlanSettingFormUtils from "../../../utils/BaselinePlanSettingFormUtils";
import UserContext from "../common/UserContext";
import { GET_BASELINE_PLAN_SETTING } from "../../../graphql/queries";
import ErrorRedirect from "../../ErrorRedirect";
import {
  abbrevMeasurementUnit,
  formatDecimalForUnits,
} from "../../../utils/helpers";
import { titleCase } from "title-case";
import { Controller, useForm } from "react-hook-form";
import AutoSaveStatus from "../common/AutoSaveStatus";
import useAutoSave from "../../../hooks/useAutoSave";
import { NumericFormat } from "react-number-format";

const BaselinePlanSettingsForm = ({ canEdit }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { register, handleSubmit, reset, getValues, control } = useForm({
    defaultValues: {
      beginningYear: "",
      beginningBalance: "",
      currentTotalAnnualReservesFunding: "",
    },
  });
  const [monthlyContrib, setMonthlyContrib] = useState(0);
  const { data, loading, error } = useQuery(GET_BASELINE_PLAN_SETTING, {
    skip: !organization,
    fetchPolicy: "no-cache",
  });

  const [updateBaselinePlanSetting] = useMutation(
    UPDATE_BASELINE_PLAN_SETTING,
    {
      refetchQueries: ["GetSimulationPlanSetting", "GetAlternatePlanSetting"],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    if (data?.baselinePlanSetting) {
      const newDefaults = BaselinePlanSettingFormUtils.incomingSchema.cast(
        data.baselinePlanSetting,
        { stripUnknown: true }
      );
      reset(newDefaults);
      setMonthlyContrib(newDefaults.currentMonthlyHomeownerContributions);
    }
  }, [data, reset]);

  const [onSubmit, lastSubmittedAt, submitting] = useAutoSave(async () => {
    let values = getValues();
    const castedVals = BaselinePlanSettingFormUtils.outgoingSchema.cast(values);
    const { data } = await updateBaselinePlanSetting({
      variables: {
        attributes: BaselinePlanSettingFormUtils.outgoingSchema.validateSync(
          castedVals,
          { stripUnknown: true }
        ),
      },
    });
    const savedValues = BaselinePlanSettingFormUtils.incomingSchema.cast(
      data.updateBaselinePlanSetting,
      { stripUnknown: true }
    );
    setMonthlyContrib(savedValues.currentMonthlyHomeownerContributions);
  });

  if (error) return <ErrorRedirect error={error} />;

  if (loading)
    return (
      <Card className="baseline-plan-settings">
        <Card.Header>
          <Card.Title>Baseline Settings</Card.Title>
        </Card.Header>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Loader />
        </Card.Body>
      </Card>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="baseline-plan-settings">
      <input name="id" type="hidden" ref={register} />
      <Card>
        <Card.Header>
          <Card.Title>Baseline Settings</Card.Title>
          <Card.Options>
            <AutoSaveStatus
              submitting={submitting}
              lastSubmittedAt={lastSubmittedAt}
            />
          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Table className="card-table table-vcenter">
            <Table.Body>
              <Table.Row>
                <Table.Col>
                  <label htmlFor="baseline-beginning-year-input">
                    Beginning Year
                  </label>
                </Table.Col>
                <Table.Col className="d-flex justify-content-end">
                  <input
                    ref={register}
                    id="baseline-beginning-year-input"
                    name="beginningYear"
                    placeholder="Year"
                    type="number"
                    className="form-control text-right pr-2"
                    readOnly={!canEdit}
                    onChange={handleSubmit(onSubmit)}
                  />
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <label htmlFor="baseline-beginning-balance-input">
                    Beginning Balance
                  </label>
                </Table.Col>
                <Table.Col className="d-flex justify-content-end">
                  <Controller
                    control={control}
                    name="beginningBalance"
                    render={({ onChange, value }) => (
                      <NumericFormat
                        id="baseline-beginning-balance-input"
                        placeholder="Balance"
                        className="form-control text-right pr-2"
                        readOnly={!canEdit}
                        allowNegative={false}
                        thousandSeparator={true}
                        decimalScale="2"
                        valueIsNumericString={true}
                        name="beginningBalance"
                        onValueChange={(v, { event }) => {
                          onChange(v.value);
                          if (event) handleSubmit(onSubmit)(event);
                        }}
                        prefix="$"
                        value={value}
                      />
                    )}
                  />
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <label htmlFor="baseline-current-total-annual-reserves-funding">
                    Current Total Annual Reserves Funding
                  </label>
                </Table.Col>
                <Table.Col className="d-flex justify-content-end">
                  <Controller
                    control={control}
                    name="currentTotalAnnualReservesFunding"
                    render={({ onChange, value }) => (
                      <NumericFormat
                        id="baseline-current-total-annual-reserves-funding"
                        placeholder="Funding"
                        className="form-control text-right pr-2"
                        readOnly={!canEdit}
                        allowNegative={false}
                        name="currentTotalAnnualReservesFunding"
                        valueIsNumericString={true}
                        thousandSeparator={true}
                        decimalScale="2"
                        onValueChange={(v, { event }) => {
                          onChange(v.value);
                          if (event) handleSubmit(onSubmit)(event);
                        }}
                        prefix="$"
                        value={value}
                      />
                    )}
                  />
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <label htmlFor="baseline-current-monthly-contributions">
                    Current Monthly Contributions Per{" "}
                    {titleCase(
                      abbrevMeasurementUnit(organization.measurementUnits)
                    )}
                  </label>
                </Table.Col>
                <Table.Col className="d-flex justify-content-end">
                  <NumericFormat
                    id="baseline-current-monthly-contributions"
                    className="read-only p-2 text-nowrap text-right"
                    displayType="text"
                    prefix="$"
                    value={formatDecimalForUnits(
                      organization.measurementUnits,
                      monthlyContrib
                    )}
                  />
                </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
    </form>
  );
};

export default BaselinePlanSettingsForm;
