import React from "react";
import { Alert, Card, Dimmer, Table } from "tabler-react";
import { useQuery } from "@apollo/client";
import { GET_INFLATION_COSTS } from "../../../graphql/queries";
import ErrorRedirect from "../../ErrorRedirect";
import CurrencyFormat from "../common/CurrencyFormat";
import MissingPrerequisitesList from "../plans_and_reports/MissingPrerequisitesList";

const ProjectedItemCosts = () => {
  const { data, loading, error } = useQuery(GET_INFLATION_COSTS, {
    fetchPolicy: "no-cache",
  });
  if (error) return <ErrorRedirect error={error} />;
  if (loading)
    return (
      <Card>
        <Card.Header>
          <Card.Title>Projected Item Costs (Adjusted for Inflation)</Card.Title>
        </Card.Header>
        <Card.Body className="py-9">
          <Dimmer loader active={loading} />
        </Card.Body>
      </Card>
    );

  const missingPrereqs = data.inflationCosts.missingPrerequisites;

  if (missingPrereqs.length)
    return (
      <Card className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>Projected Item Costs (Adjusted for Inflation)</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Projected Item Costs, please complete the
              following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <Card className="projected-item-costs">
      <Card.Header>
        <div className="position-relative">
          <Card.Title>Projected Item Costs (Adjusted for Inflation)</Card.Title>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <Table className="card-table table-vcenter">
          <Table.Header>
            <Table.Row className="text-center">
              {data.inflationCosts.headers.map((header, index) => (
                <Table.ColHeader key={`header-${index}`}>
                  {header}
                </Table.ColHeader>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.inflationCosts.values.map((rowVals, index) => (
              <Table.Row className="text-center" key={`year-${index}`}>
                <Table.Col>{rowVals[0]}</Table.Col>
                {rowVals.slice(1).map((val, subIndex) => (
                  <Table.Col key={`sub-val-${subIndex}`}>
                    <CurrencyFormat>{val.toFixed(0)}</CurrencyFormat>
                  </Table.Col>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ProjectedItemCosts;
