import { useEffect, useState } from "react";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const useStripeCard = () => {
  const [loading, setLoading] = useState(true);
  const [cardObj, setCardObj] = useState({
    error: {},
    complete: false,
  });
  const [expiryObj, setExpiryObj] = useState({
    error: {},
    complete: false,
  });
  const [cvcObj, setCvcObj] = useState({
    error: {},
    complete: false,
  });
  const [zip, setZip] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => setLoading(!stripe || !elements), [stripe, elements]);

  const submit = async () => {
    if (!stripe || !elements) return setLoading(!stripe || !elements);
    const cardElement = elements.getElement(CardNumberElement);
    return stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          postal_code: zip,
        },
      },
    });
  };

  const form = {
    submit,
    cardObj,
    setCardObj,
    expiryObj,
    setExpiryObj,
    cvcObj,
    setCvcObj,
    zip,
    setZip,
  };

  return {
    loading,
    form,
  };
};

export default useStripeCard;
