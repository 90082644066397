import React, { Fragment, useState } from "react";
import { Dimmer, Table } from "tabler-react";
import CurrencyFormat from "../common/CurrencyFormat";

const InvoicePreview = ({
  loading,
  invoicePreview,
  setInvoicePreview,
  recalculate,
}) => {
  const [prevInvoicePreview, setPrevInvoicePreview] = useState(invoicePreview);
  const handleChange = (e) => {
    const val = e.target.value;
    if (!val || val === "") return;
    setPrevInvoicePreview(invoicePreview);
    setInvoicePreview({ ...invoicePreview, licenseQuantity: parseInt(val) });
  };

  const handleBlur = () => {
    if (prevInvoicePreview.licenseQuantity === invoicePreview.licenseQuantity)
      return;
    recalculate();
  };

  return (
    <Dimmer active={loading} loader>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Item</Table.ColHeader>
            <Table.ColHeader>Quantity</Table.ColHeader>
            <Table.ColHeader>Price</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Col className="text-left">
              Pemaquid License <br />
              <i>
                <small>Includes 4 collaborators, 1 HOA per license</small>
              </i>
            </Table.Col>
            <Table.Col>
              <div className="d-flex justify-content-center">
                <input
                  defaultValue={invoicePreview.licenseQuantity}
                  className="form-control text-center"
                  name="quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Table.Col>
            <Table.Col>
              <CurrencyFormat>
                {invoicePreview.licensePrice / 100}
              </CurrencyFormat>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            <Table.Col className="text-left">
              Maintenance Fee <br />
              <i>
                <small>
                  Free the first year.{" "}
                  <CurrencyFormat>
                    {invoicePreview.maintenancePrice / 100}
                  </CurrencyFormat>
                  /year starting the second year
                </small>
              </i>
            </Table.Col>
            <Table.Col>1</Table.Col>
            <Table.Col>$0</Table.Col>
          </Table.Row>
          {invoicePreview.couponName && (
            <Fragment>
              <Table.Row>
                <Table.Col className="text-right" colSpan={2}>
                  Subtotal
                </Table.Col>
                <Table.Col>
                  <CurrencyFormat>
                    {(
                      (invoicePreview.licensePrice / 100) *
                      invoicePreview.licenseQuantity
                    ).toFixed(2)}
                  </CurrencyFormat>
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col className="text-right border-top-0" colSpan={2}>
                  Discount
                </Table.Col>
                <Table.Col className="border-top-0 py-3 px-1">
                  {invoicePreview.couponName}
                </Table.Col>
              </Table.Row>
            </Fragment>
          )}
          <Table.Row>
            <Table.Col
              colSpan={2}
              className={`text-right ${
                invoicePreview.couponName ? "border-top-0" : ""
              }`}
            >
              <strong>Total</strong>
            </Table.Col>
            <Table.Col
              className={invoicePreview.couponName ? "border-top-0" : ""}
            >
              <strong>
                <CurrencyFormat>
                  {(invoicePreview.total / 100).toFixed(2)}
                </CurrencyFormat>
              </strong>
            </Table.Col>
          </Table.Row>
        </Table.Body>
      </Table>
    </Dimmer>
  );
};

export default InvoicePreview;
