import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AcceptInvitationPage from "./pages/AcceptInvitationPage";
import FaqPage from "./pages/authenticated/FaqPage";
import InfoLayout from "./components/InfoLayout";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const UnauthenticatedApp = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/signup">
          <SignUpPage />
        </Route>
        <Route exact path="/accept">
          <AcceptInvitationPage />
        </Route>
        <Route exact path="/terms">
          <TermsPage />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPolicyPage />
        </Route>
        <Route exact path="/faq">
          <InfoLayout>
            <FaqPage />
          </InfoLayout>
        </Route>
        <Route exact path="/reset_password">
          <ResetPasswordPage />
        </Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default UnauthenticatedApp;
