import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dropdown, Loader } from "tabler-react";
import { findAndGeneratePdf } from "../../../utils/helpers";
import UserContext from "../common/UserContext";
import { useMutation } from "@apollo/client";
import { GENERATE_PDF } from "../../../graphql/mutations";
import {
  alternatePercentFundedChartRef,
  alternatePlanChartRef,
  baselinePercentFundedChartRef,
  baselinePlanChartRef,
  getResizedChartHtml,
  getTheBookStyles,
  simulationPercentFundedChartRef,
  simulationPlanChartRef,
} from "../../../utils/theBookHelpers";

const ReportsHelperMenu = ({ filenamePrefix, forTheBook }) => {
  const [pdfLoading, setPdfLoading] = useState(false);
  const mountedRef = useRef(true);
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  // this is the severside pdf generation mutation
  const [generatePdf] = useMutation(GENERATE_PDF);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Dropdown
      trigger={<Dropdown.Trigger>Actions</Dropdown.Trigger>}
      type="button"
      color="primary"
      items={[
        <div
          className="dropdown-item cursor-pointer"
          key="dropdown-item-1"
          onClick={async () => {
            if (pdfLoading) return;
            setPdfLoading(true);
            if (forTheBook) {
              const styles = getTheBookStyles();

              const baselinePlanChart = await getResizedChartHtml(
                "#baseline-plan .c3",
                baselinePlanChartRef
              );

              const baselinePercentFundedChart = await getResizedChartHtml(
                "#baseline-percent-funded .c3",
                baselinePercentFundedChartRef
              );

              const simulationPlanChart = await getResizedChartHtml(
                "#simulation-plan .c3",
                simulationPlanChartRef
              );

              const simulationPercentFundedChart = await getResizedChartHtml(
                "#simulation-percent-funded .c3",
                simulationPercentFundedChartRef
              );

              const alternatePlanChart = await getResizedChartHtml(
                "#alternate-plan .c3",
                alternatePlanChartRef
              );

              const alternatePercentFundedChart = await getResizedChartHtml(
                "#alternate-percent-funded .c3",
                alternatePercentFundedChartRef
              );

              const { data } = await generatePdf({
                variables: {
                  pdfName: "the_book",
                  styles,
                  baselinePlanChart,
                  baselinePercentFundedChart,
                  simulationPlanChart,
                  simulationPercentFundedChart,
                  alternatePlanChart,
                  alternatePercentFundedChart,
                },
              });
              const response = await fetch(data.generatePdf.pdfUrl);
              const blob = await response.blob();
              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.setAttribute(
                "download",
                `${organization.name} ${filenamePrefix}-${
                  new Date().toISOString().split("T")[0]
                }.pdf`
              );
              a.click();
            } else {
              await findAndGeneratePdf(
                `${organization.name} ${filenamePrefix}`
              );
            }
            setPdfLoading(false);
          }}
        >
          {pdfLoading ? (
            <Fragment>
              <Loader className="sm center" />
              {forTheBook && (
                <div className="d-flex flex-column align-items-center">
                  <small>This can take a minute.</small>
                </div>
              )}
            </Fragment>
          ) : (
            "Download as PDF"
          )}
        </div>,
      ]}
    />
  );
};

export default ReportsHelperMenu;
