import React, { useContext } from "react";
import { Alert, Card, Grid } from "tabler-react";
import { useQuery } from "@apollo/client";
import { GET_ANNUAL_REPORT } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import UserContext from "../common/UserContext";
import ReportsHelperMenu from "./ReportsHelperMenu";
import AnnualReportPdf from "./AnnualReportPdf";
import AnnualReportTable from "./AnnualReportTable";
import MissingPrerequisitesList from "./MissingPrerequisitesList";
import capitalize from "capitalize";

const AnnualReport = ({ reportType, color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;

  const { loading, error, data } = useQuery(GET_ANNUAL_REPORT, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      reportType: reportType,
    },
    fetchPolicy: "no-cache",
  });

  if (loading) return <LoadingPage />;
  if (error) return <ErrorRedirect error={error} />;
  const rows = data.annualReport.rows;
  const missingPrereqs = data.annualReport.missingPrerequisites;

  if (missingPrereqs.length)
    return (
      <Card statusColor={color} className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>Annual Report - {capitalize(reportType)}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Annual Report - {capitalize(reportType)},
              please complete the following steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  const hasCustomReportsCategory = rows.some((r) => r.customCategory);
  const customReportsCategoryName =
    hasCustomReportsCategory &&
    organization.reportsCategories.find((c) => c.categoryType === "custom")
      .name;

  return (
    <div className="annual-plan">
      <div className="helper-menu d-flex justify-content-end">
        <ReportsHelperMenu
          filenamePrefix={`${reportType}-annual-report`}
          orientation="landscape"
        />
      </div>
      <AnnualReportTable
        color={color}
        organization={organization}
        report={rows}
        reportType={reportType}
        customReportsCategoryName={customReportsCategoryName}
        hasCustomReportsCategory={hasCustomReportsCategory}
      />
      <Grid.Row className="d-flex justify-content-center">
        <Grid.Col md={10}>
          <div className="d-none">
            <AnnualReportPdf
              organization={organization}
              annualReport={rows}
              reportType={reportType}
              customReportsCategoryName={customReportsCategoryName}
              hasCustomReportsCategory={hasCustomReportsCategory}
            />
          </div>
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

export default AnnualReport;
