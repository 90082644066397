import React from "react";
import { Loader } from "tabler-react";

const LoadingPage = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <Loader />
    </div>
  );
};

export default LoadingPage;
