import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Loader } from "tabler-react";
import { useMutation } from "@apollo/client";
import {
  DESTROY_UPLOADED_FILE,
  UPLOAD_RESERVE_ITEM_FILE_MUTATION,
} from "../../../graphql/mutations";
import { ReserveItemFragment } from "../../../graphql/fragments";
import { toast } from "react-toastify";

const DocumentUpload = ({ reserveItem, cell, canEdit }) => {
  const [submitting, setSubmitting] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const [destroyFile] = useMutation(DESTROY_UPLOADED_FILE, {
    update(cache, { data: { destroyReserveItemUploadedFile } }) {
      cache.writeFragment({
        id: destroyReserveItemUploadedFile.id,
        fragment: ReserveItemFragment,
        data: destroyReserveItemUploadedFile,
        fragmentName: "ReserveItemFragment",
      });
    },
  });

  const performDestroy = async () => {
    setSubmitting(true);
    await destroyFile({
      variables: {
        reserveItemId: reserveItem.id,
        document: true,
      },
    });
    if (cell) cell.setValue(null);
    if (mountedRef.current) setSubmitting(false);
  };

  const [uploadReserveItemFile] = useMutation(
    UPLOAD_RESERVE_ITEM_FILE_MUTATION,
    {
      update(cache, { data: { uploadReserveItemFile } }) {
        if (uploadReserveItemFile.reserveItem) {
          cache.writeFragment({
            id: uploadReserveItemFile.id,
            fragment: ReserveItemFragment,
            data: uploadReserveItemFile.reserveItem,
            fragmentName: "ReserveItemFragment",
          });
        } else {
          uploadReserveItemFile.errors.forEach((error) =>
            toast.error(error.message)
          );
        }
      },
    }
  );

  const performUpload = async (e) => {
    setSubmitting(true);

    if (e.target.validity.valid) {
      const { data } = await uploadReserveItemFile({
        variables: {
          attributes: {
            reserveItemId: reserveItem.id,
            document: e.currentTarget.files[0],
          },
        },
      });
      if (cell) {
        cell.getRow().update(data.uploadReserveItemFile.reserveItem);
        cell.setValue(
          data.uploadReserveItemFile.reserveItem.documentBase64Preview
        );
      }
    }
    if (mountedRef.current) setSubmitting(false);
  };

  if (submitting)
    return (
      <div className="d-flex w-100 justify-content-center align-items-center">
        <Loader />
      </div>
    );

  if (reserveItem.documentBase64Preview)
    return (
      <Fragment>
        <div className="row">
          <div className="col w-100 d-flex justify-content-center align-items-center">
            <a
              href={`${process.env.REACT_APP_API_URL}${reserveItem.documentUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={reserveItem.documentBase64Preview}
                alt={`Item ${reserveItem.id} Document`}
              />
            </a>
          </div>
        </div>
        {canEdit && (
          <div className="row">
            <div className="col">
              <Button
                size="sm"
                link
                type="button"
                onClick={() => performDestroy()}
              >
                remove
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    );

  if (canEdit)
    return (
      <div className="w-100 d-flex justify-content-center">
        <div className="choose-file">
          <Button type="button" color="secondary">
            Browse
          </Button>
          <input
            type="file"
            accept="application/pdf"
            onChange={(e) => performUpload(e)}
          />
        </div>
      </div>
    );

  return (
    <div className="w-100 d-flex justify-content-center">
      <small className="text-muted">
        No
        <br />
        Document
      </small>
    </div>
  );
};

export default DocumentUpload;
