import React, { useContext, useEffect, useMemo } from "react";
import { Button, Card, Form, Grid, Loader, Page } from "tabler-react";
import { Link, Redirect, useHistory } from "react-router-dom";
import DefaultLayout from "../../components/authenticated/layouts/DefaultLayout";
import UserContext from "../../components/authenticated/common/UserContext";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_ORGANIZATION,
  UPDATE_RESERVE_ITEMS,
} from "../../graphql/mutations";
import ReserveItemFormUtils from "../../utils/ReserveItemFormUtils";
import { useFieldArray, useForm } from "react-hook-form";
import { GET_RESERVE_ITEMS } from "../../graphql/queries";
import LoadingPage from "../LoadingPage";

const NewOrganizationReserveItemsPage = () => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { loading, data } = useQuery(GET_RESERVE_ITEMS, {
    fetchPolicy: "no-cache",
  });

  const reserveItems = useMemo(() => {
    if (loading) return [];

    return data.reserveItems
      .filter((e) => e.itemType !== "custom")
      .sort((a, b) => a.itemName - b.itemName);
  }, [data, loading]);

  const { control, register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      reserveItems: reserveItems,
    },
  });

  useEffect(() => {
    reset({ reserveItems: reserveItems });
  }, [reserveItems, reset]);

  const { fields } = useFieldArray({
    control,
    name: "reserveItems",
  });

  const { isSubmitting } = formState;
  const history = useHistory();

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: ["GetUser"],
    awaitRefetchQueries: true,
  });

  const [updateReserveItems] = useMutation(UPDATE_RESERVE_ITEMS, {
    refetchQueries: ["GetReserveItems"],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (values) => {
    await updateReserveItems({
      variables: {
        attributesArray: ReserveItemFormUtils.outgoingSchema.cast(
          values.reserveItems
            .filter((e) => e.enabled)
            .map((e) => ({ id: e.id, enabled: e.enabled }))
        ),
      },
    });
    await updateOrganization({
      variables: {
        attributes: { id: organization.id, onboarded: true },
      },
    });

    // If the user is here because they are adding a second (or third, or fourth etc)
    // org to their subscription, don't send them through the invite users flow again
    if (user.organizations.length >= 2) history.push("/reserve_items");
    history.push("/organizations/new/invite_users");
  };

  if (user.organizations.length === 0 || !user.focusedOrganization)
    return <Redirect to="/organizations/new" />;

  if (organization.id && organization.onboarded)
    return <Redirect to="/reserve_items" />;

  if (loading) return <LoadingPage />;

  return (
    <DefaultLayout mainClassName="new-organization-reserve-items">
      <Page.Content>
        <Page.Header title="New Organization" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid.Row className="justify-content-center">
            <Grid.Col width={12} lg={6}>
              <Card title="Reserve Items">
                <Card.Body>
                  <Grid.Row className="mb-5">
                    <Grid.Col>
                      <p>
                        Select which reserve items apply to your organization. A
                        minimum of 5 reserve items toggled as "On" is required.
                      </p>
                    </Grid.Col>
                  </Grid.Row>
                  <Form.Group>
                    <Grid.Row className="justify-content-center">
                      <Grid.Col width={2} className="text-center">
                        <h5>Off/On</h5>
                      </Grid.Col>
                      <Grid.Col width={10}>
                        <h5>Reserve Item</h5>
                      </Grid.Col>
                    </Grid.Row>
                  </Form.Group>
                  {fields.map((field, index) => (
                    <Form.Group key={`reserve-item-${index}`}>
                      <Grid.Row className="justify-content-center">
                        <Grid.Col width={2} className="text-center">
                          <input
                            name={`reserveItems[${index}].id`}
                            type="hidden"
                            ref={register}
                            defaultValue={field.id}
                          />
                          <label className="custom-switch m-0">
                            <input
                              name={`reserveItems[${index}].enabled`}
                              type="checkbox"
                              ref={register}
                              className="custom-switch-input"
                              defaultChecked={field.enabled}
                            />
                            <span className="custom-switch-indicator" />
                            <span className="custom-switch-description" />
                          </label>
                        </Grid.Col>
                        <Grid.Col width={10}>{field.itemName}</Grid.Col>
                      </Grid.Row>
                    </Form.Group>
                  ))}
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="justify-content-center">
            <Grid.Col width={3}>
              <Link className="btn btn-gray btn-block" to="/organizations/new">
                Back
              </Link>
            </Grid.Col>
            <Grid.Col width={3}>
              <Button
                type="submit"
                color="primary"
                block={true}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Loader className="sm white center" /> : "Save"}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </form>
      </Page.Content>
    </DefaultLayout>
  );
};
export default NewOrganizationReserveItemsPage;
