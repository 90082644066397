import gql from "graphql-tag";
import {
  AlternatePlanSettingFragment,
  BaselinePlanSettingFragment,
  ErrorFragment,
  InvitationFragment,
  OrganizationFragment,
  ReportsCategoryFragment,
  ReserveItemFragment,
  SimulationPlanSettingFragment,
  SpecialRequirementReportSettingFragment,
  SubscriptionFragment,
  UserFragment,
} from "./fragments";

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($attributes: OrganizationAttributes!) {
    createOrganization(attributes: $attributes) {
      organization {
        ...OrganizationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrganizationFragment}
  ${ErrorFragment}
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($attributes: OrganizationAttributes!) {
    updateOrganization(attributes: $attributes) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`;

export const UPLOAD_LOGO = gql`
  mutation UploadLogo($attributes: LogoUploadAttributes!) {
    uploadLogo(attributes: $attributes) {
      organization {
        ...OrganizationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrganizationFragment}
  ${ErrorFragment}
`;

export const DESTROY_LOGO = gql`
  mutation DestroyLogo {
    destroyLogo {
      organization {
        ...OrganizationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrganizationFragment}
  ${ErrorFragment}
`;

export const SET_FOCUSED_ORGANIZATION = gql`
  mutation SetFocusedOrganization($id: ID) {
    setFocusedOrganization(id: $id) {
      organization {
        ...OrganizationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrganizationFragment}
  ${ErrorFragment}
`;

export const DESTROY_ORGANIZATION = gql`
  mutation DestroyOrganization($id: ID!) {
    destroyOrganization(id: $id) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription(
    $email: String!
    $password: String!
    $agreedToTermsAndPrivacyPolicy: Boolean!
  ) {
    createSubscription(
      email: $email
      password: $password
      agreedToTermsAndPrivacyPolicy: $agreedToTermsAndPrivacyPolicy
    ) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionFragment}
  ${ErrorFragment}
`;

export const ACTIVATE_SUBSCRIPTION = gql`
  mutation ActivateSubscription(
    $startedAt: ISO8601DateTime!
    $stripePaymentMethodId: String!
    $promoCode: String
    $quantity: Int
  ) {
    activateSubscription(
      startedAt: $startedAt
      stripePaymentMethodId: $stripePaymentMethodId
      promoCode: $promoCode
      quantity: $quantity
    ) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionFragment}
  ${ErrorFragment}
`;

export const CREATE_STRIPE_PORTAL_SESSION = gql`
  mutation CreateStripePortalSession {
    createStripePortalSession {
      url
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const UPLOAD_RESERVE_ITEM_FILE_MUTATION = gql`
  mutation UploadReserveItemFile(
    $attributes: ReserveItemFileUploadAttributes!
  ) {
    uploadReserveItemFile(attributes: $attributes) {
      reserveItem {
        ...ReserveItemFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ReserveItemFragment}
  ${ErrorFragment}
`;

export const DESTROY_UPLOADED_FILE = gql`
  mutation DestroyReserveItemUploadedFile(
    $reserveItemId: ID!
    $photo: Boolean
    $document: Boolean
  ) {
    destroyReserveItemUploadedFile(
      reserveItemId: $reserveItemId
      photo: $photo
      document: $document
    ) {
      ...ReserveItemFragment
    }
  }
  ${ReserveItemFragment}
`;

export const UPDATE_BASELINE_PLAN_SETTING = gql`
  mutation UpdateBaselinePlanSetting(
    $attributes: BaselinePlanSettingAttributes!
  ) {
    updateBaselinePlanSetting(attributes: $attributes) {
      ...BaselinePlanSettingFragment
    }
  }
  ${BaselinePlanSettingFragment}
`;

export const UPDATE_SIMULATION_PLAN_SETTING = gql`
  mutation UpdateSimulationPlanSetting(
    $attributes: SimulationPlanSettingAttributes!
  ) {
    updateSimulationPlanSetting(attributes: $attributes) {
      ...SimulationPlanSettingFragment
    }
  }
  ${SimulationPlanSettingFragment}
`;

export const UPDATE_ALTERNATE_PLAN_SETTING = gql`
  mutation UpdateAlternatePlanSetting(
    $attributes: AlternatePlanSettingAttributes!
  ) {
    updateAlternatePlanSetting(attributes: $attributes) {
      alternatePlanSetting {
        ...AlternatePlanSettingFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${AlternatePlanSettingFragment}
  ${ErrorFragment}
`;

export const CREATE_CUSTOM_RESERVE_ITEMS = gql`
  mutation CreateCustomReserveItems(
    $attributesArray: [ReserveItemAttributes!]!
  ) {
    createCustomReserveItems(attributesArray: $attributesArray) {
      ...ReserveItemFragment
    }
  }
  ${ReserveItemFragment}
`;

export const CREATE_CUSTOM_RESERVE_ITEM = gql`
  mutation CreateCustomReserveItem($attributes: ReserveItemAttributes!) {
    createCustomReserveItem(attributes: $attributes) {
      reserveItem {
        ...ReserveItemFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ReserveItemFragment}
  ${ErrorFragment}
`;

export const UPDATE_RESERVE_ITEMS = gql`
  mutation UpdateReserveItems($attributesArray: [ReserveItemAttributes!]!) {
    updateReserveItems(attributesArray: $attributesArray) {
      ...ReserveItemFragment
    }
  }
  ${ReserveItemFragment}
`;

export const UPDATE_RESERVE_ITEM = gql`
  mutation UpdateReserveItem($attributes: ReserveItemAttributes!) {
    updateReserveItem(attributes: $attributes) {
      reserveItem {
        ...ReserveItemFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ReserveItemFragment}
  ${ErrorFragment}
`;

export const CREATE_OR_UPDATE_CUSTOM_REPORTS_CATEGORY = gql`
  mutation CreateOrUpdateCustomReportsCategory(
    $attributes: ReportsCategoryAttributes!
  ) {
    createOrUpdateCustomReportsCategory(attributes: $attributes) {
      ...ReportsCategoryFragment
    }
  }
  ${ReportsCategoryFragment}
`;

export const CREATE_INVITATION = gql`
  mutation CreateInvitation($email: String!, $role: String!) {
    createInvitation(email: $email, role: $role) {
      user {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;

export const CREATE_INVITATIONS = gql`
  mutation CreateInvitations($attributesArray: [InvitationAttributes!]!) {
    createInvitations(attributesArray: $attributesArray) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const REVOKE_INVITATION = gql`
  mutation RevokeInvitation($id: ID!) {
    revokeInvitation(id: $id) {
      invitation {
        ...InvitationFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${InvitationFragment}
  ${ErrorFragment}
`;

export const ACCEPT_INVITATION_UNAUTHENTICATED = gql`
  mutation AcceptInvitationUnauthenticated(
    $agreedToTermsAndPrivacyPolicy: Boolean!
    $password: String!
    $invitationToken: String!
  ) {
    acceptInvitationUnauthenticated(
      agreedToTermsAndPrivacyPolicy: $agreedToTermsAndPrivacyPolicy
      password: $password
      invitationToken: $invitationToken
    ) {
      user {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;

export const ACCEPT_INVITATION_AUTHENTICATED = gql`
  mutation AcceptInvitationAuthenticated($invitationToken: String!) {
    acceptInvitationAuthenticated(invitationToken: $invitationToken) {
      success
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const UPDATE_SPECIAL_REQUIREMENT_REPORT_SETTING = gql`
  mutation UpdateSpecialRequirementReportSetting(
    $attributes: SpecialRequirementReportSettingAttributes!
  ) {
    updateSpecialRequirementReportSetting(attributes: $attributes) {
      specialRequirementReportSetting {
        ...SpecialRequirementReportSettingFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SpecialRequirementReportSettingFragment}
  ${ErrorFragment}
`;

export const GENERATE_PDF = gql`
  mutation GeneratePdf(
    $styles: String!
    $pdfName: String!
    $baselinePlanChart: String
    $baselinePercentFundedChart: String
    $simulationPlanChart: String
    $simulationPercentFundedChart: String
    $alternatePlanChart: String
    $alternatePercentFundedChart: String
  ) {
    generatePdf(
      styles: $styles
      pdfName: $pdfName
      baselinePlanChart: $baselinePlanChart
      baselinePercentFundedChart: $baselinePercentFundedChart
      simulationPlanChart: $simulationPlanChart
      simulationPercentFundedChart: $simulationPercentFundedChart
      alternatePlanChart: $alternatePlanChart
      alternatePercentFundedChart: $alternatePercentFundedChart
    ) {
      pdfUrl
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;
