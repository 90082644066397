import SharedFormUtils from "./SharedFormUtils";
import { object, string } from "yup";

class SettingsFormUtils extends SharedFormUtils {
  static outgoingSchema = object({
    email: string()
      .email("Email must be valid.")
      .required("Email is required."),
    role: string().required("Role is required."),
  });
}

export default SettingsFormUtils;
