import React from "react";
import { Card } from "tabler-react";
import InfoLayout from "../components/InfoLayout";

const TermsPage = () => {
  return (
    <InfoLayout>
      <Card>
        <Card.Body>
          <h1 className="text-center">TERMS OF USE</h1>
          <p>
            <u>EFFECTIVE: February 1, 2020</u>
          </p>
          <p>
            Hafner Technology Group LLC (<i>hereafter</i>, "HTG") welcomes you
            to the Pemaquid&trade; Reserves planning service, an{" "}
            <nobr>easy-to-use</nobr> <nobr>software-based</nobr> service
            designed to enable your organization to plan reserves in
            anticipation of capital expenses likely to be incurred (
            <i>hereafter</i>, "the Service").
          </p>
          <p>
            We have prepared these Terms of Use (<i>hereafter</i>, "Terms"),
            which provide you with our rules to use the Service. Please note
            that these Terms are legally binding; as a result, please read them
            carefully. In these Terms of Use, "You" or "Your" means the
            organization and all related persons using the Service for the
            benefit of the organization.
          </p>
          <p className="text-center mt-5">
            <strong>
              IF YOU DO NOT AGREE TO THE TERMS SET FORTH IN THESE TERMS OF USE,
              DO NOT REGISTER TO USE THE SERVICE
            </strong>
          </p>
          <p className="my-5">
            <u>SECTION 1: Age Restriction</u>
          </p>
          <p>
            <ol>
              <li>You must be at least 18 years old to use the Service.</li>
            </ol>
          </p>
          <p>
            <u>SECTION 2: Use of Service Restrictions and Responsibilities</u>
          </p>
          <p>
            <ol>
              <li>
                You agree that you will not solicit, collect or use the login
                credentials of other users of the Service.
              </li>
              <li>
                You are required to pay the subscription fee for each user of
                the Service.
              </li>
              <li>
                You are prohibited from selling, transferring, licensing or
                assigning your account, followers, username, or any account
                rights.
              </li>
              <li>
                You are responsible for keeping your password secret and secure.
              </li>
              <li>
                You are responsible for your conduct, activity and any data,
                text, files, information, usernames and other content or
                materials (collectively, "Content") that you submit or disclose
                through our website as part of Your use of the Service.
              </li>
              <li>You must not change, modify, adapt or alter the Service.</li>
              <li>
                You must not interfere with or disrupt the Service. This
                includes any servers or any networks that are connected to the
                Service.
              </li>
              <li>
                You must not create accounts with the Services through
                unauthorized means, including but not limited to, by using an
                automated device, script, bot, spider, crawler or scraper.
              </li>
              <li>
                You must not attempt to restrict another user from using or
                enjoying the Service.
              </li>
              <li>
                You are responsible for reading and abiding by these Terms and
                any other HTG’s terms and policies.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 3: Illicit Activity</u>
          </p>
          <p>
            <ol>
              <li>
                You may not use the Service for any illegal or unauthorized
                purpose. You agree to comply with all laws, rules and
                regulations applicable to your use of the Service and your
                Content including but not limited to, copyright laws.
              </li>
              <li>
                If HTG believes Your Content violates these Terms or any other
                terms and policies set forth by HTG, we reserve the right (but
                not obligated) to access, review, screen, and delete Your
                Content and/or account at any time.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 4: Warranties</u>
          </p>
          <p>
            <ol>
              <li>
                You represent and warrant that:
                <ol type="a">
                  <li>
                    You own or have the right to use and disclose the Content on
                    or through the Service or otherwise have the right to grant
                    the rights and licenses set forth in these Terms;
                  </li>
                  <li>
                    The posting and use of Your Content on or through the
                    Service does not violate, misappropriate or infringe on the
                    rights of any third party, including, without limitation,
                    privacy rights, publicity rights, copyrights, trademark
                    and/or other intellectual property rights;
                  </li>
                  <li>
                    You agree to pay for all royalties, fees, and any other
                    monies owed by reason of Content you disclose on or through
                    the Service; and
                  </li>
                  <li>
                    You have the legal right and capacity to enter into these
                    Terms in your jurisdiction.
                  </li>
                </ol>
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 5: Advertisement</u>
          </p>
          <p>
            <ol>
              <li>
                HTG will not provide or allow any advertisements as part of the
                Service.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 6: Intellectual Property Rights</u>
          </p>
          <p>
            <ol>
              <li>
                HTG does not own any Content that You disclose on or through the
                Service. However, you do grant us a license to use the Content
                as described in Section 7 of these Terms.
              </li>
              <li>
                The Service contains content owned or licensed by HTG (
                <i>hereafter</i> "HTG Content"). HTG Content is protected by
                copyright, trademark, trade secret and other laws, and, as
                between you and HTG, HTG owns and retains all rights in the HTG
                Content and the Service. You will not remove, alter or conceal
                any copyright, trademark, service mark or other proprietary
                rights notices incorporated in or accompanying the HTG Content
                and You will not reproduce, modify, adapt, prepare derivative
                works based on, perform, display, publish, distribute, transmit,
                broadcast, sell, license or otherwise exploit the HTG Content.
              </li>
              <li>
                You are prohibited from copying, in whole or in part all
                trademarks owned by HTG without the prior written consent of
                HTG. In addition, all page headers, custom graphics, button
                icons and scripts are service marks, trademarks and/or trade
                dress of HTG, and may not be copied, imitated or used, in whole
                or in part, without prior written consent from HTG.
              </li>
              <li>
                Any Intellectual Property that You may conceive, develop or
                create as a result of observing or using the Service shall be
                owned by HTG. This shall include, but not be limited to any
                suggestions You may provide to HTG regarding or related to the
                Service. You agree that You will assign and do hereby assign to
                HTG all right, title, and interest in any Intellectual Property
                that You may conceive, develop or create as a result of
                observing or using the Service. Intellectual Property is defined
                as patents, patent applications and inventions (whether
                patentable or not) associated with the Service; (b) branding and
                trademarks associated with the Service; (c) copyrights and works
                of authorship associated with the Service; (d) trade secrets and{" "}
                <nobr>know-how</nobr> associated with the Service; and (d) all
                tangible and intangible representations and manifestations of
                such intellectual property associated with the Service.
              </li>
              <li>
                HTG, at its sole discretion, will provide You with automatic
                updates of the Service.
              </li>
              <li>
                HTG is serious about protecting and enforcing intellectual
                property. All complaints about infringement of another’s
                intellectual property must be sent to the following email
                address:{" "}
                <a href="mailto:info@pemaquidreserves.com">
                  info@pemaquidreserves.com
                </a>
                . The subject line must read “Infringement Complaint.” Please
                note that any allegations of infringement should provide enough
                specificity for our team to consider. HTG may request additional
                information before determining the validity of the alleged
                infringement.
              </li>
              <li>
                For copyright infringement, pursuant to 17 U.S.C. § 512(c)(3),
                please provide the following information to the HTG contact
                address above so that we can assess the claim:
                <ol type="a">
                  <li>
                    physical or electronic signature of a person authorized to
                    act on behalf of the copyright owner;
                  </li>
                  <li>
                    identify the copyrighted work claimed to have been
                    infringed;
                  </li>
                  <li>
                    identify the material that is claimed to be infringing or to
                    be the subject of infringing activity and that is to be
                    removed, or access to which is to be disabled, and
                    information reasonably sufficient to let us locate the
                    material;
                  </li>
                  <li>
                    provide Your contact information, including Your address,
                    telephone number, and an email address;
                  </li>
                  <li>
                    provide a personal statement that You have a{" "}
                    <nobr>good-faith</nobr> belief that the use of the material
                    in the manner complained of is not authorized by the
                    copyright owner, its agent, or the law; and
                  </li>
                  <li>
                    provide a statement that the information in the notification
                    is accurate and, under penalty of perjury, that You are
                    authorized to act on behalf of the copyright owner.
                  </li>
                </ol>
              </li>
            </ol>
          </p>
          <p className>
            <u>SECTION 7: Licenses</u>
          </p>
          <p>
            <ol>
              <li>
                HTG grants You a personal, worldwide,{" "}
                <nobr>non-assignable,</nobr> nonexclusive, revocable, and{" "}
                <nobr>non-sublicensable</nobr> license to access and use the
                Service so long as You abide by these Terms and any other terms
                or policies set forth by HTG.
              </li>
              <li>
                You hereby grant to HTG, its affiliates, agents and business
                partners, a <nobr>non-exclusive,</nobr> fully paid and{" "}
                <nobr>royalty-free,</nobr> transferable,{" "}
                <nobr>sub-licensable,</nobr> worldwide license to use the
                Content that You disclose on or through the Service in any form,
                subject to HTG’s Privacy Policy.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 8: Privacy</u>
          </p>
          <p>
            <ol>
              <li>
                HTG is committed to protecting Your privacy while You use the
                Service. We encourage all users to review our Privacy Policy,
                which can be read above. By using the Service, You agree to the
                conditions set forth in our Privacy Policy.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 9: Indemnification</u>
          </p>
          <p>
            <ol>
              <li>
                You agree, to the extent permitted by law, to indemnify, defend,
                and hold harmless HTG, its affiliates, officers, members,
                employees, licensors, and agents from and against any and all
                complaints, charges, claims, damages, losses, costs,
                liabilities, and expenses (including attorneys’ fees) due to,
                arising out of, or relating in any way to: (a) Your access to or
                use of the Services; (b) Your Content; and (c) Your breach of
                these Terms or any other terms or policies set forth by HTG.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 10: Disclaimers</u>
          </p>
          <p>
            <ol>
              <li>
                THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE
                EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE, AND <nobr>NON-INFRINGEMENT.</nobr> WE DO NOT REPRESENT OR
                WARRANT THAT:
                <ol type="a">
                  <li>
                    THE SERVICE WILL ALWAYS BE SECURE, <nobr>ERROR-FREE,</nobr>{" "}
                    OR TIMELY;
                  </li>
                  <li>
                    THE SERVICE WILL ALWAYS FUNCTION WITHOUT DELAYS,
                    DISRUPTIONS, OR IMPERFECTIONS; OR
                  </li>
                  <li>
                    THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON
                    OR THROUGH THE SERVICE WILL BE TIMELY OR ACCURATE.
                  </li>
                </ol>
              </li>
              <li>
                YOU MAY USE A SERVICE, FEATURE OR FUNCTIONALTY THAT A THIRD
                PARTY MADE AVAILABLE THROUGH THE SERVICE. HTG IS NOT RESPONSIBLE
                OR LIABLE FOR A THIRD PARTY’S TERMS OR ACTION AND YOU SHALL BE
                RESPONSIBLE FOR ABIDING BY SUCH THIRD PARTY’S TERMS.
              </li>
            </ol>
          </p>

          <p>
            <u>SECTION 11: Limitation of Liabilities</u>
          </p>
          <p>
            <ol>
              <li>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, HTG AND ITS OFFICERS,
                MEMBERS, EMPLOYEES, AFFILIATES, LICENSORS, AND SUPPLIERS WILL
                NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF
                PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR
                ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES,
                RESULTING FROM:
                <ol type="a">
                  <li>
                    YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
                    SERVICE;
                  </li>
                  <li>
                    THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR
                    THROUGH THE SERVICE;
                  </li>
                  <li>
                    UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT,
                    EVEN IF HTG HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES;
                  </li>
                  <li>
                    ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY HTG,
                    ITS AGENTS OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR
                    ANY OTHER PARTY'S USE OF THE SERVICE;
                  </li>
                  <li>
                    ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER
                    INTELLECTUAL PROPERTY OWNERS;
                  </li>
                  <li>
                    ANY ERRORS OR OMISSIONS IN THE SERVICE'S OPERATION; OR
                  </li>
                  <li>
                    ANY DAMAGE TO ANY USER'S COMPUTER, MOBILE DEVICE, OR OTHER
                    EQUIPMENT OR TECHNOLOGY. IN NO EVENT WILL THE HTG PARTIES
                    TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OR
                    ACTION EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100.00).
                  </li>
                </ol>
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 12: Termination of Terms</u>
          </p>
          <p>
            <ol>
              <li>
                You may terminate the Terms at any time for any reason by
                contacting{" "}
                <a href="mailto:info@pemaquidreserves.com">
                  info@pemaquidreserves.com
                </a>{" "}
                via email. Additionally, a phone call to validate the request to
                the office telephone number on the Contact Us portion of the web
                page is required.
              </li>
              <li>
                HTG may terminate Your account at any time, for any reason,
                without advanced notice.
              </li>
              <li>
                The following sections survive termination of the Terms:
                Sections 4, 7 and <nobr>9-17.</nobr>
              </li>
            </ol>
          </p>
          <p>
            <u>
              SECTION 13: Arbitration, Small Claims,
              <nobr>Class-Action</nobr> Waiver, and Jury Waiver
            </u>
          </p>
          <p>
            <ol>
              <li>
                Arbitration. You agree that all disputes between You and HTG
                with regard to Your relationship with HTG, including without
                limitation disputes related to these Terms, Your use of the
                Service, and/or rights of privacy and/or publicity, will be
                resolved by binding, individual arbitration under the American
                Arbitration Association's (Consumer Arbitration Rules). To learn
                more about the American Arbitration Association Consumer
                Arbitration Rules, visit:{" "}
                <a href="https://www.adr.org/Rules">
                  https://www.adr.org/Rules
                </a>
                .
                <ol type="a">
                  <li>
                    EXCEPTION: Neither You nor HTG is required to arbitrate any
                    dispute if either party seeks equitable relief for the
                    alleged unlawful use of copyrights, trademarks, trade names,
                    logos, trade secrets, or patents.
                  </li>
                  <li>
                    OPT-OUT: You may opt out of this agreement to arbitrate. If
                    You do so, neither you nor HTG can require the other to
                    participate in an arbitration proceeding. To opt out, You
                    must notify HTG in writing within 30 days of the date that
                    You first became subject to this arbitration provision. You
                    must use this email address to opt out:
                    info@pemaquidreserves.com.
                  </li>
                </ol>
              </li>
              <li>
                Small Claims. Instead of arbitration, you may bring your claim
                in Your local "small claims" court, if permitted by that small
                claims court's rules.
              </li>
              <li>
                Time Limitation: All claims related to HTG or the Service must
                be filed within one (1) year after such claim arose. You agree
                that if You fail to file a claim within one (1) year after such
                a claim arose, you will be prohibited from bringing the claim
                against HTG.
              </li>
              <li>
                Class-Action Waiver. You may bring claims only on Your own
                behalf. Neither You nor HTG will participate in a class action
                or <nobr>class-wide</nobr> arbitration for any claims covered by
                these Terms. You also agree not to participate in claims brought
                in a private attorney general or representative capacity, or
                consolidated claims involving another person's account, if HTG
                is a party to the proceeding. If the prohibition against class
                actions and other claims brought on behalf of third parties
                contained above is found to be unenforceable, then all of the
                preceding language in this section will be null and void. This
                section will survive the termination of Your relationship with
                HTG.
              </li>
              <li>
                Jury Waiver. You and HTG expressly waive all rights to a jury
                trial.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 14: Governing Law and Jurisdiction</u>
          </p>
          <p>
            <ol>
              <li>
                These Terms shall be governed by and construed, interpreted and
                enforced in accordance with and under the laws of the State of
                Maine, United States of America, without regard to the State of
                Maine’s conflict of laws doctrine. The Parties hereto submit to
                the exclusive jurisdiction of the United States Federal courts
                having jurisdiction in Maine for purposes of all legal
                proceedings arising out of or relating to this Agreement. The
                Parties waive any objection which they now or hereafter may have
                regarding personal jurisdiction or venue or any claim concerning
                forum non conveniens. If the matter is to be litigated in state
                court, the claims must be exclusively litigated in the Superior
                Court of Cumberland County, located in Portland, Maine. If the
                matter is to be litigated in federal court, the claims must be
                exclusively litigated in the United States District Court
                located in Portland, Maine.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 15: Severability</u>
          </p>
          <p>
            <ol>
              <li>
                In the event any provision of the Terms or the application of
                any such provision shall be held by a court, arbiter or tribunal
                to be invalid or unenforceable, the remaining provisions of the
                Terms shall remain in full force and effect.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 16. Assignment</u>
          </p>
          <p>
            <ol>
              <li>
                You will not assign the Terms or assign any rights or delegate
                any obligations hereunder, in whole or in part, whether
                voluntarily or by operation of law, without the prior written
                consent of HTG. Any purported assignment or delegation by You
                without the appropriate prior written consent of HTG will be
                null and void. HTG may assign these Terms or any rights
                hereunder without Your consent. These Terms do not confer any{" "}
                <nobr>third-party</nobr> beneficiary rights.
              </li>
            </ol>
          </p>
          <p>
            <u>SECTION 17: Acknowledge of Terms</u>
          </p>
          <p>
            <ol>
              <li>
                By using the Service, you declare that You have read the Terms,
                understand, and agree to all of its terms, conditions and
                meanings and their significance. You agree that the rule of
                construction to the effect that any ambiguities in the Terms are
                to be resolved against the drafter shall not be employed in the
                interpretation of the Terms.
              </li>
            </ol>
          </p>
          <p>
            If You have any questions about the Terms, please do not hesitate to
            contact us at:{" "}
            <a href="mailto:info@pemaquidreserves.com">
              info@pemaquidreserves.com
            </a>{" "}
            or the phone number on the Contact Us portion of the web page.
          </p>
        </Card.Body>
      </Card>
    </InfoLayout>
  );
};

export default TermsPage;
