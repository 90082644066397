import React from "react";
import UserContext from "./UserContext";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../../graphql/queries";
import LoadingPage from "../../../pages/LoadingPage";
import Bugsnag from "@bugsnag/js";
import ErrorRedirect from "../../ErrorRedirect";
import { openReplay } from "../../../utils/OpenReplay";

const UserProvider = ({ children }) => {
  const { loading, error, data } = useQuery(GET_USER);

  if (loading) return <LoadingPage />;
  if (data?.user) {
    Bugsnag.setUser(data.user.id, data.user.email);
    Bugsnag.addMetadata("user", {
      role: data.user.role,
      subscriptionId: data.user.subscription?.id,
    });
    openReplay.setUserID(data.user.email);
    openReplay.setMetadata("role", data.user.role);
    openReplay.setMetadata("subscriptionId", data.user.subscription?.id);
    openReplay.setMetadata("userId", data.user.id);
  }

  return (
    <UserContext.Provider value={{ loading, error, user: data?.user }}>
      <ErrorRedirect error={error} user={data?.user}>
        {children}
      </ErrorRedirect>
    </UserContext.Provider>
  );
};

export default UserProvider;
