import { array, boolean, number, object, string } from "yup";
import SharedFormUtils from "./SharedFormUtils";

class ReserveItemFormUtils extends SharedFormUtils {
  static incomingSingleSchema = object({
    itemName: string(),
    itemQuantity: this.defaultBlank(number()),
    yearsToReplace: this.defaultBlank(number().min(0)),
    costPerItem: this.defaultBlank(number().min(0)),
    latestCostYear: this.defaultBlank(number().integer().min(1900)),
    lastInstall: this.defaultBlank(number().integer().min(1900)),
    estimatedUsefulLife: this.defaultBlank(number().min(0)),
    totalCost: this.defaultBlank(number().min(0)),
    notes: string().nullable(),
    reportsCategory: string(),
    photoUrl: string().nullable(),
    documentUrl: string().nullable(),
    enabled: boolean(),
  });

  static incomingSchema = array().of(this.incomingSingleSchema);

  static outgoingSingleSchema = object({
    id: number().integer(),
    itemQuantity: this.blankToNull(number()),
    yearsToReplace: this.blankToNull(number().min(0)),
    costPerItem: this.blankToNull(number().min(0)),
    latestCostYear: this.blankToNull(number().integer().min(1900)),
    lastInstall: this.blankToNull(number().integer().min(1900)),
    estimatedUsefulLife: this.blankToNull(number().min(0)),
    totalCost: this.blankToNull(number().min(0)),
    notes: string().nullable(),
    reportsCategoryId: number().integer(),
    enabled: boolean(),
  });
  static outgoingSchema = array().of(this.outgoingSingleSchema);
}

export default ReserveItemFormUtils;
